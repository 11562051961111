export default function ProgramsService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = `${MicroServices.Base}/programs`;
  const getTemplateByProgramIdAndVersion = async (programId, version) => client.get(
    `${baseEndpointUrl}/v3/templates?$filter=programId eq '${programId}' and version eq '${version}'`,
  );
  const getProgramInfoByProgramOfferingId = async (programOfferingId) => client.get(
    `${baseEndpointUrl}/v3/programEligibility/${programOfferingId}`,
  );

  return {
    getTemplateByProgramIdAndVersion,
    getProgramInfoByProgramOfferingId,
  };
}
