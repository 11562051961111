import STATUS from '../../globalStatuses';
import {
  REQUEST_ATTENDANCE, RECEIVE_ATTENDANCE, REQUEST_ATTENDANCE_ERROR,
} from './types';

const findAttendanceBySequence = (sequence, attendanceUnits) => {
  const matchingAttendances = attendanceUnits.filter((attendance) => (
    attendance.sequence === sequence
  ));
  return matchingAttendances.length === 1 ? matchingAttendances[0] : null;
};

const addAttendancesByMembershipId = (
  state,
  courseMembershipId,
  activityGroups,
  attendanceUnits,
  receivedAt,
  status,
  error,
) => {
  const newState = { ...state };
  newState.byMembershipId[courseMembershipId] = {
    allowedAbsences: activityGroups
      ? activityGroups.allowedAbsences
      : undefined,
    attendances: activityGroups
      ? activityGroups.activitygroups.map((activityGroup) => {
        const matchingAttendance = findAttendanceBySequence(
          activityGroup.sequence,
          attendanceUnits,
        );
        return {
          id: matchingAttendance ? matchingAttendance.id : null,
          sequence: activityGroup.sequence,
          startDate: activityGroup.startDate,
          endDate: activityGroup.endDate,
          attendance: matchingAttendance ? matchingAttendance.attendance : null,
        };
      })
      : undefined,
    receivedAt,
    status: status || STATUS.FETCHED,
    error,
  };
  return newState;
};

export default function attendances(state = { attendances: {} }, action) {
  switch (action.type) {
    case REQUEST_ATTENDANCE:
      return addAttendancesByMembershipId(
        state,
        action.courseMembershipId,
        null,
        null,
        action.receivedAt,
        STATUS.FETCHING,
      );
    case RECEIVE_ATTENDANCE:
      return addAttendancesByMembershipId(
        state,
        action.courseMembershipId,
        action.activityGroups,
        action.attendances,
        action.receivedAt,
        STATUS.FETCHED,
      );
    case REQUEST_ATTENDANCE_ERROR:
      return addAttendancesByMembershipId(
        state,
        action.courseMembershipId,
        null,
        null,
        action.receivedAt,
        STATUS.ERROR,
        action.error,
      );
    default:
      return state;
  }
}
