import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
    '& .MuiButton-root': {
      padding: theme.spacing(0, 0.8, 0.6),
      textTransform: 'none',
      fontSize: theme.spacing(1.6),
      color: theme.palette.common.black,
      fontWeight: 400,
      letterSpacing: 'normal',
      outline: 'none',
    },
    '& .MuiButton-label': {
      width: 'auto',
      borderBottom: `0.3rem solid ${theme.palette.common.black}`,
      '&:hover': {
        borderBottom: `0.3rem solid ${theme.palette.error.main}`,
      },
    },
  },

  feedbackTitle: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: theme.spacing(2),
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(2.4),
    },
  },

  detailsSection: {
    padding: theme.spacing(1, 1.5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 3.5),
    },
  },
  rateExperience: {
    fontSize: theme.spacing(1.6),
    marginBottom: theme.spacing(1.4),
    marginTop: theme.spacing(3.2),
    fontFamily: 'IBM Plex Serif',
    fontWeight: '200',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2.4),
    },
  },
  tellMoreBox: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  tellMoreTitle: {
    marginBottom: theme.spacing(1.6),
    fontFamily: 'IBM Plex Serif',
    fontWeight: '200',
    fontSize: theme.spacing(1.6),
  },
  textBox: {
    fontFamily: 'Roboto',
    color: theme.palette.grays.mediumDarkGray,
    border: `0.1rem solid ${theme.palette.grays.mediumDarkGray}`,
    borderRadius: theme.spacing(0.4),
    height: theme.spacing(11),
    '& label': {
      fontSize: theme.spacing(1.6),
      color: theme.palette.grays.tooltipGray,
      paddingLeft: theme.spacing(1.6),
      '&.MuiFormLabel-root.Mui-focused': {
        color: theme.palette.grays.tooltipGray,
      },
    },
    '& div': {
      padding: theme.spacing(0, 1.6, 0, 1.6),
      '&::before, &::after, &.MuiInput-underline:hover:before': {
        borderBottom: 'none',
      },
      '& #feedback-reason-text:focus': {
        outline: `${theme.spacing(0.2)} solid ${theme.palette.text.primary}`,
        outlineOffset: '1.6rem',
      },
    },
  },
  contactBox: {
    marginTop: theme.spacing(4),
  },
  contactTitle: {
    marginBottom: theme.spacing(1.2),
    fontFamily: 'Roboto',
    fontSize: theme.spacing(1.6),
    '&.MuiFormLabel-root.Mui-focused': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(53),
    },
  },
  contactChoice: {
    color: theme.palette.grays.mediumDarkGray,
  },
  feedbackCategoryBox: {
    height: theme.spacing(34),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(18.6),
    },
  },
  feedbackCategoryRadioGroup: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexWrap: 'noWrap',
      flexDirection: 'row',
    },
  },
  feedbackCategoryForm: {
    color: theme.palette.grays.mediumDarkGray,
    display: 'flex',
    flexDirection: 'row',
    height: theme.spacing(14.4),
  },
  feedbackCategoryColumn: {
    color: theme.palette.grays.mediumDarkGray,
    flex: '50%',
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(5),
  },
  ohdearText: {
    fontSize: theme.spacing(1.6),
    padding: theme.spacing(0, 3),
  },
  submitButtonContainer: {
    marginBottom: theme.spacing(3.5),
    marginTop: theme.spacing(4.0),
  },
  resendButtonContainer: {
    flexWrap: 'noWrap',
    backgroundColor: theme.palette.error.background,
    padding: theme.spacing(3, 0, 3, 0),
    height: theme.spacing(9.6),
    borderLeft: `0.4rem solid ${theme.palette.error.main}`,
  },
  sendFeebackButton: {
    borderRadius: theme.spacing(2.8),
    width: theme.spacing(17.2),
    height: theme.spacing(4),
  },
  resendFeebackButton: {
    borderRadius: theme.spacing(2.8),
    width: theme.spacing(17.2),
    height: theme.spacing(4),
    marginRight: theme.spacing(3.5),
  },
  feedbackRoot: {
    minWidth: theme.spacing(36),
    minHeight: theme.spacing(22),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      minWidth: theme.spacing(46.5),
    },
  },
  thanksTitle: {
    width: theme.spacing(26.7),
    marginBottom: theme.spacing(4.5),
    fontSize: theme.spacing(2.4),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      minWidth: theme.spacing(38),
      marginBottom: theme.spacing(6),
    },
  },
  dialogControl: {
    '& [role*=dialog]': {
      overflowY: 'hidden',
      paddingLeft: theme.spacing(2.4),
    },
  },
}));
