import axios from 'axios';
import jwtDecode from 'jwt-decode';
import STATUS from '../../globalStatuses';
import {
  REQUEST_TOKEN, RECEIVE_TOKEN, REQUEST_TOKEN_ERROR, INVALIDATE_TOKEN,
} from './types';
import { ScenarioIdKey } from '../../../components/mock-scenarios/v1/mock-scenarios';

function requestToken(request) {
  return { type: REQUEST_TOKEN, request };
}

function receiveToken(token) {
  return { type: RECEIVE_TOKEN, token, receivedAt: Date.now() };
}

function requestTokenError(error) {
  return { type: REQUEST_TOKEN_ERROR, error, receivedAt: Date.now() };
}

function invalidateToken() {
  return { type: INVALIDATE_TOKEN, receivedAt: Date.now() };
}

function shouldInvalidateToken(state) {
  const { auth } = state;
  if (!auth) {
    return true;
  }
  return !auth.status || auth.status !== STATUS.FETCHING;
}

export function invalidateTokenIfNeeded() {
  return (dispatch, getState) => {
    if (shouldInvalidateToken(getState())) {
      return dispatch(invalidateToken());
    }
    return Promise.resolve();
  };
}

function fetchToken() {
  return (dispatch) => {
    const { MyPhoenix } = window;
    if ((MyPhoenix.Environment.IsAuthor || MyPhoenix.Environment.IsTest)) {
      dispatch(requestToken());
      return new Promise((resolve) => {
        resolve(dispatch(receiveToken({
          email: 'mock@test.com',
          personId: sessionStorage.getItem(ScenarioIdKey) || '1',
          token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJleHAiOjMwMDAwMDAwMDB9.niIXaERFcC7VH0if0-7YvqnD9dQWk81eP2OALz2zGvw',
          userName: 'mock',
        })));
      });
    }
    const fetchTokenRequest = axios.get('/api/services/get-token')
      .then((response) => {
        if (!response?.data?.token) {
          // token not available or expired, log back into next.js or get refresh token
          const currentPage = window.location.pathname;
          window.location.href = `/login.html?page=${currentPage}`;
          return null;
        }
        return dispatch(receiveToken(response.data));
      })
      .catch((error) => dispatch(requestTokenError(error)));
    dispatch(requestToken(fetchTokenRequest));
    return fetchTokenRequest;
  };
}

function shouldFetchToken(dispatch, getState) {
  const { auth: { token, serverSideReceivedAt, clientSideReceivedAt } } = getState();
  const useServerSideInvalidation = window?.MyPhoenix?.
      Config?.Features?.isServerSideTokenInvalidationEnabled;
  if (!useServerSideInvalidation) {
    if (token && jwtDecode(token).exp < Date.now() / 1000) {
      dispatch(invalidateTokenIfNeeded());
    }
  }
  if (useServerSideInvalidation) {
    if (token && serverSideReceivedAt && clientSideReceivedAt) {
      // Add time elapsed client-side to original server-side token received time
      // to approximate current server now time.
      // If current token's expiration is before current server now time, invalidate it
      const clientSideNow = Date.now() / 1000;
      const clientSideTimeElapsed = clientSideNow - clientSideReceivedAt;
      const serverSideNow = (serverSideReceivedAt / 1000) + clientSideTimeElapsed;
      const tokenExp = jwtDecode(token).exp;
      if (tokenExp < serverSideNow) {
        dispatch(invalidateTokenIfNeeded());
      }
    }
  }
  const { auth: { status } } = getState();

  return status !== STATUS.FETCHED && status !== STATUS.FETCHING;
}

export function fetchTokenIfNeeded() {
  return (dispatch, getState) => {
    const { auth } = getState();
    if (shouldFetchToken(dispatch, getState)) {
      return dispatch(fetchToken());
    }
    return auth.request;
  };
}
