/* GET addresses */
export const REQUEST_ADDRESSES = 'REQUEST_ADDRESSES';
export const RECEIVE_ADDRESSES = 'RECEIVE_ADDRESSES';
export const REQUEST_ADDRESSES_ERROR = 'REQUEST_ADDRESSES_ERROR';
/* GET address */
export const REQUEST_ADDRESS = 'REQUEST_ADDRESS';
export const RECEIVE_ADDRESS = 'RECEIVE_ADDRESS';
export const REQUEST_ADDRESS_ERROR = 'REQUEST_ADDRESS_ERROR';
/* PUT address */
export const REQUEST_UPDATE_ADDRESS = 'REQUEST_UPDATE_ADDRESS';
export const RECEIVE_UPDATE_ADDRESS = 'RECEIVE_UPDATE_ADDRESS';
export const REQUEST_UPDATE_ADDRESS_ERROR = 'REQUEST_UPDATE_ADDRESS_ERROR';
