import orderBy from 'lodash.orderby';
import moment from 'moment-timezone';
import STATUS from '../../globalStatuses';
import {
  REQUEST_APPLICATION_WORKFLOW,
  RECEIVE_APPLICATION_WORKFLOW,
  REQUEST_APPLICATION_WORKFLOW_ERROR,
  REQUEST_APPLICATION_PROGRAM,
  RECEIVE_APPLICATION_PROGRAM,
  REQUEST_APPLICATION_PROGRAM_ERROR,
  REQUEST_APPLICATION_INFO_ERROR,
} from './types';

const getMostRecentApplicationWorkflows = (workflows) => (workflows.length
  ? orderBy(workflows, (workflow) => moment(workflow.startTime).tz('America/Phoenix'), ['desc'])[0] : null);

const getValueFromWorkflow = (workflow, taskName, key) => {
  if (workflow) {
    const submissionWorkflowTask = workflow.tasks
      .find((task) => task.referenceTaskName === taskName);
    if (submissionWorkflowTask) {
      return submissionWorkflowTask[key];
    }
  }
  return null;
};

const getApplicationIdFromWorkflow = (workflow) => {
  if (workflow) {
    const taskWithApplicationId = workflow.tasks.find((task) => task.outputData && task.outputData.applicationId !== '');
    return taskWithApplicationId.outputData.applicationId;
  }
  return null;
};

const getApplicationTypeFromWorkflow = (workflow) => {
  if (workflow) {
    const inputWithApplicationType = workflow.input;
    if (inputWithApplicationType.applicationType) {
      return inputWithApplicationType.applicationType;
    }
    return null;
  }
  return null;
};

const getApplicationVersion = (workflow) => (
  workflow ? workflow.version : null
);

const getApplicationStartTime = (workflow) => (
  workflow ? workflow.startTime : null
);

export default function enrollmentApplication(state = {}, action) {
  switch (action.type) {
    case REQUEST_APPLICATION_WORKFLOW:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case RECEIVE_APPLICATION_WORKFLOW:
      // eslint-disable-next-line no-case-declarations
      const mostRecentWorkflow = getMostRecentApplicationWorkflows(action.workflows);
      return {
        ...state,
        applicationId: getApplicationIdFromWorkflow(mostRecentWorkflow),
        applicationStartTime: getApplicationStartTime(mostRecentWorkflow),
        applicationType: getApplicationTypeFromWorkflow(mostRecentWorkflow),
        submissionStatus: getValueFromWorkflow(mostRecentWorkflow, 'reviewSignSubmit', 'status'),
        submittedAt: getValueFromWorkflow(mostRecentWorkflow, 'reviewSignSubmit', 'scheduledTime'),
        reviewStatus: getValueFromWorkflow(mostRecentWorkflow, 'advisorReview', 'status'),
        version: getApplicationVersion(mostRecentWorkflow),
        receivedAt: action.receivedAt,
        status: STATUS.FETCHED,
      };
    case REQUEST_APPLICATION_PROGRAM:
      return {
        ...state,
        programInfo: {
          ...state.programInfo,
          status: STATUS.FETCHING,
        },
      };
    case RECEIVE_APPLICATION_PROGRAM:
      return {
        ...state,
        programOfferingId: action.programOfferingId,
        programInfo: {
          ...state.programInfo,
          programId: action.programDetails.programId,
          programName: action?.programDetails?.description,
          status: STATUS.FETCHED,
        },
      };
    case REQUEST_APPLICATION_WORKFLOW_ERROR:
      return {
        ...state,
        receivedAt: action.receivedAt,
        status: STATUS.ERROR,
      };
    case REQUEST_APPLICATION_INFO_ERROR:
      return {
        ...state,
        receivedAt: action.receivedAt,
        programInfo: {
          ...state.programInfo,
          status: STATUS.ERROR,
        },
      };
    case REQUEST_APPLICATION_PROGRAM_ERROR:
      return {
        ...state,
        receivedAt: action.receivedAt,
        programInfo: {
          ...state.programInfo,
          status: STATUS.ERROR,
        },
      };
    default:
      return state;
  }
}
