import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputBase, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from './SearchInput.styles';
import Icon from '../../icon/v1/Icon';
import theme from '../../../theme';

function SearchInput(props) {
  const {
    searchLandingPagePath,
    handleSearchOpenToggle,
    handleOpenSearchBarToggle,
    searchOpenValue,
    openSearchBarValue,
  } = props;

  const [input, setInput] = useState();
  const [searchOpen, setSearchOpen] = useState(false);
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const [openSearchBar, setOpenSearchBar] = useState(openSearchBarValue);

  const toggleInertOnMainAndFooter = (openSearch) => {
    const main = document.querySelector('main');
    const footer = document.querySelector('footer');
    if (!main || !footer) { return; }
    if (openSearch) {
      main.setAttribute('inert', 'true');
      footer.setAttribute('inert', 'true');
    } else {
      main.removeAttribute('inert');
      footer.removeAttribute('inert');
    }
  };

  const handleClickSearch = () => {
    if (md) {
      handleOpenSearchBarToggle();
      setOpenSearchBar(!openSearchBar);
    } else {
      handleSearchOpenToggle();
      const openSearch = !searchOpenValue;
      setSearchOpen(openSearch);
      toggleInertOnMainAndFooter(openSearch);
    }
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const classes = useStyles({ searchOpen });

  return (
    <>
      {!openSearchBar && (
      <IconButton
        className={classes.searchMobileIcon}
        onClick={handleClickSearch}
        aria-label="Search University of Phoenix"
      >
        <Icon icon="icon-spy-glass" color={theme.palette.common.black} />
      </IconButton>
      )}
      {md && openSearchBar && (
        <Grid>
          <form role="search" method="get" target="_self" action={searchLandingPagePath} className={classes.formMain}>
            <InputBase
              name="query"
              classes={{
                root: classes.searchBox,
                input: classes.searchInput,
              }}
              placeholder="Search University of Phoenix"
              value={input}
              onChange={handleInputChange}
              onBlur={handleClickSearch}
              inputProps={{ 'aria-label': 'Search University of Phoenix' }}
            />
            <IconButton
              className={classes.searchBtnIcon}
              type="submit"
              aria-label="Search University of Phoenix"
            >
              <Icon icon="icon-spy-glass" color={theme.palette.common.black} />
            </IconButton>
          </form>
        </Grid>
      )}
      <div className={classes.searchFormContainer}>
        <nav inert={(searchOpen ? undefined : 'true')} aria-label="Search" className={classes.nav}>
          <div className={classes.buttonClose}>
            <Button className={classes.closeButtonText} aria-label="Close" onClick={handleClickSearch} title="Close">
              Close
            </Button>
          </div>
          <div className={classes.divider} />
          <form role="search" method="get" target="_self" action={searchLandingPagePath} className={classes.formMobileView}>
            <div>
              <IconButton
                className={classes.searchBtnIcon}
                type="submit"
                aria-label="Search University of Phoenix"
              >
                <Icon icon="icon-spy-glass" color="" />
              </IconButton>
              <InputBase
                className={classes.searchBoxMobileView}
                name="query"
                classes={{
                  input: searchLandingPagePath,
                }}
                placeholder="Search University of Phoenix"
                inputProps={{ 'aria-label': 'Search University of Phoenix' }}
              />
            </div>
          </form>
          <div className={classes.divider} />
        </nav>
      </div>
    </>
  );
}
SearchInput.defaultProps = {
  handleSearchOpenToggle: () => {},
};

SearchInput.propTypes = {
  searchLandingPagePath: PropTypes.string.isRequired,
  handleSearchOpenToggle: PropTypes.func,
  handleOpenSearchBarToggle: PropTypes.func.isRequired,
  searchOpenValue: PropTypes.bool.isRequired,
  openSearchBarValue: PropTypes.bool.isRequired,
};
export default SearchInput;
