import React, { useEffect, useRef, useState } from 'react';
import Badge from '@material-ui/core/Badge';
import moment from 'moment';
import PropTypes from 'prop-types';
import Alert from '../../alert/v1/Alert';
import STATUS from '../../../redux/globalStatuses';
import styles from './NotificationBell.styles';
import { fetchLocation } from '../../../utils/window-functions';
import { createEventObject, useRaiseEventOnClick } from '../../../utils/event-functions';
import { getCurrentDate } from '../../../utils/date-time-functions';

const NotificationBell = ({
  menuOpen,
  messageCenterLink,
  statusAuth,
  statusAlert,
  statusNotifications,
  statusTasks,
  loadEvents,
  loginCount,
  loadNotifications,
  loadTasks,
  logonEvents,
  assignedTasks,
  enrollmentTasks,
  setNotificationAlert,
  setNotificationAlertCount,
  notificationAlert,
  parentComponentName,
  notificationsList,
  statusEnrollmentApplication,
  loadApplication,
}) => {
  const componentName = `${parentComponentName}_NotificationBell`;
  const [bellDetails, setBellDetails] = useState({
    showBadge: false,
    badgeCount: 0,
    badgeContent: null,
  });
  const [ringBell, setRingBell] = useState(false);
  const [firstRenderedTime, setFirstRenderedTime] = useState(null);
  const classes = styles();
  const linkRef = useRef(null);
  useRaiseEventOnClick([linkRef]);

  const {
    isMessageCenterEnrollmentTasksEnabled = false,
    LogonEventBypassBlacklist = '',
  } = window.MyPhoenix.Config.Features;

  const logonEventBypassBlacklist = LogonEventBypassBlacklist ? LogonEventBypassBlacklist.split(',') : [];
  const logonEventShouldBeBypassed = sessionStorage.getItem('logon-event-bypassed') !== null;
  const setLogonEventShouldBeBypassed = () => {
    sessionStorage.setItem('logon-event-bypassed', 'true');
  };
  const logonEventRedirect = () => {
    const eCampusHomepageUrl = `${window.MyPhoenix.Config.ECampus.Base}${window.MyPhoenix.Config.ECampus.Homepage}`;
    window.open(eCampusHomepageUrl, '_self');
  };
  const activeLogonEvents = loginCount > 0 && logonEvents?.length > 0
    ? logonEvents.filter((logonEvent) => {
      const today = getCurrentDate();
      return (
        today.isAfter(moment.tz(logonEvent?.states?.startDate, 'America/Phoenix'))
        && today.isBefore(moment.tz(logonEvent?.states?.dueDate, 'America/Phoenix'))
        && logonEvent.states?.status?.action === 'ASSIGNED'
      );
    })
    : [];
  const allLogonEventsAreBypassBlacklisted = loginCount
    ? activeLogonEvents.filter((logonEvent) => (
      logonEventBypassBlacklist.includes(logonEvent.templateId)
    )).length >= loginCount
    : false;
  const goToLogonEvent = () => {
    if (!allLogonEventsAreBypassBlacklisted) {
      setLogonEventShouldBeBypassed();
    }
    logonEventRedirect();
  };

  const allTasks = isMessageCenterEnrollmentTasksEnabled
    ? assignedTasks.concat(enrollmentTasks) : assignedTasks;

  const unreadyStatuses = [STATUS.UNFETCHED, STATUS.FETCHING, STATUS.INVALIDATED];

  const seocsEnabled = (messages) => {
    let filteredMessageArr = messages;
    if (filteredMessageArr
      .some((task) => task
            && task.templateId === window.MyPhoenix.Config.Features.SEOCSTemplateIdKey)
        && window.MyPhoenix.Config.Features.SEOCSEnabled) {
      filteredMessageArr = messages
        .filter((task) => task.templateId !== window.MyPhoenix.Config.Features.SEOCSTemplateIdKey);
    }
    return filteredMessageArr;
  };

  const getBadgeCount = (badgeList = []) => {
    const badgeCount = badgeList?.length;
    let badgeContent = badgeCount;
    if (badgeCount > 10) {
      badgeContent = '10+';
    }
    return { badgeContent, badgeCount };
  };

  const getBellDetails = () => {
    const filteredTasks = allTasks?.length ? seocsEnabled(allTasks) : allTasks;
    return {
      showBadge: Boolean(notificationsList?.length || filteredTasks?.length),
      ...getBadgeCount([...notificationsList, ...filteredTasks]),
    };
  };

  const queBellRing = () => {
    const timeElapsedFromFirstRender = moment().diff(firstRenderedTime);
    const timeToWaitForBellRing = 2500;
    if (timeElapsedFromFirstRender > timeToWaitForBellRing) {
      setRingBell(true);
    } else {
      const timeLeftToWait = timeToWaitForBellRing - timeElapsedFromFirstRender;
      setTimeout(() => {
        setRingBell(true);
      }, timeLeftToWait);
    }
  };

  useEffect(() => {
    // on first render set time
    setFirstRenderedTime(moment());
  }, []);

  useEffect(() => {
    if (statusAuth === STATUS.FETCHED) {
      loadApplication();
      loadNotifications();
      loadEvents();
      /** set notification alert count based on logon event count. */
      if (loginCount && !logonEventShouldBeBypassed) {
        setNotificationAlert(loginCount);
      } else {
      /** when theres no logon event found */
        setNotificationAlert(0);
      }
    }
  }, [
    statusAuth,
    loadEvents,
    loadApplication,
    loadNotifications,
    loginCount,
  ]);

  useEffect(() => {
    if (!unreadyStatuses.includes(statusEnrollmentApplication)) {
      loadTasks();
    }
  }, [statusEnrollmentApplication, loadTasks]);

  useEffect(() => {
    if (!unreadyStatuses.includes(statusTasks)
          && !unreadyStatuses.includes(statusAlert)
          && !unreadyStatuses.includes(statusNotifications)) {
      const bDetails = getBellDetails();
      setBellDetails(bDetails);
      setNotificationAlertCount(+bDetails.badgeCount);
      queBellRing();
    }
  }, [statusAlert, statusTasks, statusNotifications, firstRenderedTime]);

  const { showBadge, badgeContent } = bellDetails;

  return (
    <>
      <a
        id="notifications-link"
        href={messageCenterLink}
        target="_self"
        inert={menuOpen ? 'true' : undefined}
        aria-label={`Notifications link. ${showBadge ? `${badgeContent} New notifications available.` : 'No new notifications.'}`}
        className={classes.link}
        tabIndex={0}
        ref={linkRef}
        data-raise-event-on-click={createEventObject(
          componentName,
          [{ badgeContent }, { url: messageCenterLink }, { target: '_self' }],
        )}
      >
        <Badge
          overlap="circle"
          color="primary"
          badgeContent={badgeContent}
          invisible={!showBadge}
          className={classes.badge}
        >
          <div className={classes.bell}>
            <div className={`${classes.bellTop}
              ${showBadge
              && ringBell
              && !fetchLocation('message-center')
              ? 'animated' : ''}
            `}
            />
            <div className={`${classes.bellBottom}
              ${showBadge
              && ringBell
              && !fetchLocation('message-center')
              ? 'animated' : ''}
            `}
            />
          </div>
        </Badge>
        <span className={classes.headerIconLabel}>Notifications</span>
      </a>
      {!!notificationAlert && !logonEventShouldBeBypassed && (
        <Alert
          open
          dialogTitle="You have an important message."
          primaryText="Please review and take the appropriate action to move forward."
          buttonText="Continue to message"
          primaryButtonCallback={goToLogonEvent}
        />
      )}
    </>
  );
};

NotificationBell.propTypes = {
  logonEvents: PropTypes.arrayOf(PropTypes.object),
  assignedTasks: PropTypes.arrayOf(PropTypes.object),
  enrollmentTasks: PropTypes.arrayOf(PropTypes.object),
  loadApplication: PropTypes.func,
  loadEvents: PropTypes.func.isRequired,
  loginCount: PropTypes.number.isRequired,
  loadTasks: PropTypes.func,
  loadNotifications: PropTypes.func,
  menuOpen: PropTypes.bool,
  messageCenterLink: PropTypes.string,
  notificationAlert: PropTypes.number.isRequired,
  notificationsList: PropTypes.arrayOf(PropTypes.object),
  parentComponentName: PropTypes.string,
  setNotificationAlert: PropTypes.func,
  setNotificationAlertCount: PropTypes.func,
  statusAlert: PropTypes.string,
  statusAuth: PropTypes.string,
  statusEnrollmentApplication: PropTypes.string,
  statusNotifications: PropTypes.string,
  statusTasks: PropTypes.string,
};

NotificationBell.defaultProps = {
  logonEvents: [],
  assignedTasks: [],
  enrollmentTasks: [],
  loadApplication: () => {},
  loadTasks: () => {},
  loadNotifications: () => {},
  menuOpen: false,
  messageCenterLink: '/message-center.html',
  notificationsList: [],
  parentComponentName: '',
  setNotificationAlert: () => {},
  setNotificationAlertCount: () => {},
  statusAlert: STATUS.UNFETCHED,
  statusAuth: STATUS.UNFETCHED,
  statusEnrollmentApplication: STATUS.UNFETCHED,
  statusNotifications: STATUS.UNFETCHED,
  statusTasks: STATUS.UNFETCHED,
};
export default NotificationBell;
