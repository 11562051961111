import STATUS from '../../globalStatuses';
import {
  REQUEST_PROGRAM_PROGRESS_BY_ID,
  REQUEST_PROGRAM_PROGRESS_ERROR,
  INVALIDATE_PROGRAM_PROGRESS,
  UPDATE_PROGRAM_PROGRESS,
  REQUEST_ALL_PROGRAM_PROGRESS_IDS,
  RECEIVE_ALL_PROGRAM_PROGRESS_IDS,
  REQUEST_ALL_PROGRAM_PROGRESS_ID_ERRORS,
} from './types';
import { getPageLoadIndex } from '../../../utils/page-functions';

export default function programProgress(state = {}, action) {
  switch (action.type) {
    case REQUEST_PROGRAM_PROGRESS_BY_ID:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.programId]: {
            ...state.byId[action.programId],
            status: STATUS.FETCHING,
            pageLoadIndex: getPageLoadIndex(),
          },
        },
      };
    case UPDATE_PROGRAM_PROGRESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.programId]: {
            ...action.payload,
            receivedAt: action.receivedAt,
            status: STATUS.FETCHED,
          },
        },
      };
    case REQUEST_PROGRAM_PROGRESS_ERROR:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.programId]: {
            error: action.error,
            receivedAt: action.receivedAt,
            status: STATUS.ERROR,
          },
        },
      };
    case INVALIDATE_PROGRAM_PROGRESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.programId]: {
            ...state.byId[action.programId],
            status: STATUS.INVALIDATED,
          },
        },
      };
    case REQUEST_ALL_PROGRAM_PROGRESS_IDS:
      return {
        ...state,
        status: STATUS.FETCHING,
        receivedAt: action.receivedAt,
      };
    case RECEIVE_ALL_PROGRAM_PROGRESS_IDS:
      return {
        ...state,
        status: STATUS.FETCHED,
        receivedAt: action.receivedAt,
      };
    case REQUEST_ALL_PROGRAM_PROGRESS_ID_ERRORS:
      return {
        ...state,
        status: STATUS.ERROR,
        receivedAt: action.receivedAt,
      };
    default:
      return state;
  }
}
