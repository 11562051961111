import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './IconLink.scss';
import Icon from '../../icon/v1/Icon';
import theme from '../../../theme';
import { useRaiseEventOnClick, createEventObject } from '../../../utils/event-functions';
/**
 * Icon Link React Component
 */
function IconLink(props) {
  const {
    styleType, target, url, icon, text, onClick, parentComponentName,
  } = props;
  const componentName = `${parentComponentName}_IconLink`;
  const linkRef = useRef(null);
  useRaiseEventOnClick([linkRef]);
  const iconColor = styleType === 'white'
    ? theme.palette.common.white : theme.palette.grays.almostBlack;

  return (
    <div className={`icon-link ${styleType}`}>
      <a
        href={url}
        target={target}
        onClick={onClick}
        ref={linkRef}
        data-raise-event-on-click={createEventObject(
          componentName,
          [{ url }, { target }, { text }, { styleType }],
        )}
      >
        <Icon icon={icon} color={iconColor} />
        <span>{text}</span>
      </a>
    </div>
  );
}

IconLink.defaultProps = {
  onClick: undefined,
  target: '_self',
  styleType: 'almost-black',
  parentComponentName: '',
};

IconLink.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  styleType: PropTypes.string,
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  parentComponentName: PropTypes.string,
};

export default IconLink;
