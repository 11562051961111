import STATUS from '../../globalStatuses';
import {
  REQUEST_SKILLS,
  REQUEST_SKILLS_ERROR,
  RECEIVE_SKILLS,
} from './types';

const sortSkillsByKey = (key, allSkills) => (
  allSkills.reduce((acc, current) => {
    acc[current[key]] = acc[current[key]] || [];
    acc[current[key]].push(current);
    return acc;
  }, {})
);

export default function skills(state = { skills: {} }, action) {
  switch (action.type) {
    case REQUEST_SKILLS:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case RECEIVE_SKILLS:
      return {
        ...state,
        byCourseId: sortSkillsByKey('courseID', action.skills),
        byProgramId: sortSkillsByKey('programID', action.skills),
        receivedAt: action.receivedAt,
        status: STATUS.FETCHED,
      };
    case REQUEST_SKILLS_ERROR:
      return {
        ...state,
        status: STATUS.ERROR,
        error: action.error,
      };
    default:
      return state;
  }
}
