export default function ApplicationService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = `${MicroServices.Base}/applications`;

  const getApplicationInfoByApplicationId = async (applicationId) => client.get(
    `${baseEndpointUrl}/v1/applications/${applicationId}`,
  );

  return {
    getApplicationInfoByApplicationId,
  };
}
