import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  badge: {
    width: '3.2rem',
    height: '3.2rem',

    '& .MuiBadge-dot': {
      height: '1.7rem',
      width: '1.7rem',
      borderRadius: '100%',
    },
    '& .MuiBadge-anchorOriginTopRightCircle': {
      top: '18%',
      right: '23%',
      border: '.2rem #fff solid',
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  bell: {
    position: 'relative',
    top: '2.0rem',
    left: '1.6rem',
    transform: 'translate(-50%, -50%)',
  },
  bellTop: {
    width: '1.6rem',
    height: '1.6rem',
    background: '#000',
    borderRadius: '50% 50% 0 0',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '.4rem',
      height: '.4rem',
      background: 'inherit',
      top: '-.2rem',
      left: '50%',
      transform: 'translateX(-50%)',
      borderRadius: '50%',
    },
    '&:after': {
      content: '""',
      borderBottom: '.4rem solid #000',
      borderLeft: '.4rem solid #FFF',
      borderRight: '.4rem solid #FFF',
      height: '0',
      width: '2.4rem',
      position: 'absolute',
      top: '1.6rem',
      left: '-.4rem',
    },
    '&.animated': {
      animation: '$abanico 1000ms ease-in-out',
      transformOrigin: 'top',
    },
  },
  bellBottom: {
    width: '.6rem',
    height: '.3rem',
    background: '#000',
    position: 'absolute',
    top: '2.1rem',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '0 0 .3rem .3rem',
    '&.animated': {
      animation: '$badalo 1000ms ease-in-out',
    },
  },
  '@keyframes abanico': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '7.5%': {
      transform: 'rotate(25deg)',
    },
    '30%': {
      transform: 'rotate(-25deg)',
    },
    '45%': {
      transform: 'rotate(15deg)',
    },
    '58%': {
      transform: 'rotate(-10deg)',
    },
    '70%': {
      transform: 'rotate(5deg)',
    },
    '87.5%': {
      transform: 'rotate(-2deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
  '@keyframes badalo': {
    '0%': {
    },
    '15%': {
      transform: 'translateX(-.8rem) rotate(7deg)',
    },
    '32.5%': {
      transform: 'translateX(.3rem) rotate(-7deg)',
    },
    '50%': {
      transform: 'translateX(-.5rem) rotate(7deg)',
    },
    '65%': {
      transform: 'translateX(.1rem) rotate(-7deg)',
    },
    '80%': {
      transform: 'translateX(-.5rem) rotate(7deg)',
    },
    '90%': {
      transform: 'translateX(0rem) rotate(-7deg)',
    },
    '100%': {
    },
  },

  headerIconLabel: {
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    textDecoration: 'none',
  },
}));

// .new-not {animation: popup 500ms forwards;}

// @keyframes popup {
//  0% {transform: scale(0.3); opacity: 0;}
//  100% {transform: scale(1); opacity: 1;}
// }
