import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(8),
    color: theme.palette.text.primary,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  flexContainer: {
    width: '100%',
    flexDirection: 'column',
    maxWidth: '106.6rem',
  },

  tiles: {
    position: 'relative',
    paddingTop: theme.spacing(5),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },

    '&.row': {
      marginLeft: theme.spacing(-0.5),
      marginRight: theme.spacing(-0.5),
    },
  },
  linkList: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    width: theme.spacing(20),

    [theme.breakpoints.between('sm', 'md')]: {
      width: theme.spacing(16),
    },
  },
  linkListItems: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconLink: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: theme.spacing(3),
  },
  footNotes: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    textAlign: 'right',
    paddingBottom: theme.spacing(1),

    '& svg': {
      marginRight: theme.spacing(0.8),
      fill: theme.palette.primary.main,
    },

    '& span': {
      fontSize: '2rem',
      fontWeight: 700,
      color: theme.palette.text.primary,
      position: 'relative',
      top: theme.spacing(0.7),
    },

    '& a': {
      display: 'inline-block',
      paddingBottom: theme.spacing(0.5),
      textDecoration: 'none',

      '&:hover': {
        borderBottom: `0.3rem solid ${theme.palette.primary.main}`,
        paddingBottom: theme.spacing(0.2),
      },
      '&:focus': {
        borderBottom: `0.3rem solid ${theme.palette.primary.main}`,
        paddingBottom: theme.spacing(0.2),
        outline: `0.3rem solid ${theme.palette.text.primary}`,
        outlineOffset: '0.3rem',
      },
    },
  },
  copyright: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    textAlign: 'left',
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: '1.4rem',
    lineHeight: theme.spacing(1.9),
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
      textAlign: 'center',
      alignItems: 'center',
    },
    '& a': {
      fontWeight: 400,
      '&:focus': {
        outline: `0.3rem solid ${theme.palette.text.primary}`,
        outlineOffset: '0.3rem',
      },
    },
  },
  underlined: {
    textDecoration: 'underline',
  },
  complementary: {
    marginTop: theme.spacing(3),
  },
}));
