import { connect } from 'react-redux';
import FeedbackButton from '../../components/feedback-button/FeedbackButton';
import {
  setSelectedEmailConsent,
  setSelectedFeedbackCategory,
  setSelectedEmojiValue,
  setSelectedFeedbackReason,
  postFeedbackSurvey,
  clearSelectedFeedback,
} from '../domains/feedbackloop/actions';

const mapStateToProps = (state) => ({
  emailConsent: state.feedback.emailConsent,
  feedbackCategory: state.feedback.feedbackCategory,
  selectedEmojiValue: state.feedback.selectedEmojiValue,
  feedbackReason: state.feedback.feedbackReason,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedEmailConsent: (val) => {
    dispatch(setSelectedEmailConsent(val));
  },
  setSelectedFeedbackCategory: (val) => {
    dispatch(setSelectedFeedbackCategory(val));
  },
  setSelectedEmojiValue: (val) => {
    dispatch(setSelectedEmojiValue(val));
  },
  setSelectedFeedbackReason: (val) => {
    dispatch(setSelectedFeedbackReason(val));
  },
  postFeedbackSurvey: (endpoint) => dispatch(postFeedbackSurvey(endpoint)),

  clearSelectedFeedback: () => dispatch(clearSelectedFeedback()),
});
const FeedbackModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeedbackButton);

export default FeedbackModalContainer;
