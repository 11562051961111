import STATUS from '../../globalStatuses';
import {
  REQUEST_USER_DEFINED_ATTRIBUTES,
  RECEIVE_USER_DEFINED_ATTRIBUTES,
  REQUEST_USER_DEFINED_ATTRIBUTES_ERROR,
} from './types';

export default function userDefinedAttributes(state = { userDefinedAttributes: {} }, action) {
  switch (action.type) {
    case REQUEST_USER_DEFINED_ATTRIBUTES:
      return {
        ...state,
        receivedAt: action.receivedAt,
        status: STATUS.FETCHING,
      };
    case RECEIVE_USER_DEFINED_ATTRIBUTES: {
      return {
        ...state,
        attributeValue: action.userDefinedAttributes,
        receivedAt: action.receivedAt,
        status: STATUS.FETCHED,
      };
    }
    case REQUEST_USER_DEFINED_ATTRIBUTES_ERROR:
      return {
        ...state,
        error: action.error,
        receivedAt: action.receivedAt,
        status: STATUS.ERROR,
      };
    default:
      return state;
  }
}
