import Cookies from 'js-cookie';
import WebFont from 'webfontloader';
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import LazyProgress from './myphoenix/components/lazy-progress/v1/LazyProgress';
import configureStore from './myphoenix/redux/configureStore';
import 'wicg-inert';
import theme from './myphoenix/theme';
import { fetchTokenIfNeeded } from './myphoenix/redux/domains/auth/actions';

// Structure components not lazy loaded
import HeaderContainer from './myphoenix/redux/containers/HeaderContainer';
import FooterContainer from './myphoenix/redux/containers/FooterContainer';
import FeedbackModalContainer from './myphoenix/redux/containers/FeedbackModalContainer';
import fetchCurrentUserIfNeeded from './myphoenix/redux/domains/currentUser/actions';
import fetchProgramsIfNeeded from './myphoenix/redux/domains/programs/actions';
import { initializeEventHandling } from './myphoenix/utils/event-functions';

// Lazy loaded Containers
const AlumniNAEGuidanceContainer = React.lazy(() => import('./myphoenix/redux/containers/AlumniNAEGuidanceContainer'));
const AchievementsContainer = React.lazy(() => import('./myphoenix/redux/containers/AchievementsContainer'));
const SkillsDashboardContainer = React.lazy(() => import('./myphoenix/redux/containers/SkillsDashboardContainer'));
const PersonalizedGreetingContainer = React.lazy(() => import('./myphoenix/redux/containers/PersonalizedGreetingContainer'));
const CourseInfoContainer = React.lazy(() => import('./myphoenix/redux/containers/CourseInfoContainer'));
const AssignedContactsContainer = React.lazy(() => import('./myphoenix/redux/containers/AssignedContactsContainer'));
const UniversityContactsContainer = React.lazy(() => import('./myphoenix/redux/containers/UniversityContactsContainer'));
const TasksContainer = React.lazy(() => import('./myphoenix/redux/containers/TasksContainer'));
const NotificationsContainer = React.lazy(() => import('./myphoenix/redux/containers/NotificationsContainer'));
const MockScenariosContainer = React.lazy(() => import('./myphoenix/redux/containers/MockScenariosContainer'));
const PublicHeader = React.lazy(() => import('./myphoenix/components/public-header/v1/PublicHeader'));
const PaigeChatBotContainer = React.lazy(() => import('./myphoenix/redux/containers/PaigeChatBotContainer'));
const SearchResultsContainer = React.lazy(() => import('./myphoenix/redux/containers/SearchResultsContainer'));
const PublicFooter = React.lazy(() => import('./myphoenix/components/public-footer/v1/PublicFooter'));
const ProgramInfoContainer = React.lazy(() => import('./myphoenix/redux/containers/ProgramInfoContainer'));
const ProgramProgressContainer = React.lazy(() => import('./myphoenix/redux/containers/ProgramProgressContainer'));
const ScheduledCreditsTableContainer = React.lazy(() => import('./myphoenix/redux/containers/tables/ScheduledCreditsTableContainer'));
const NotScheduledCreditsTableContainer = React.lazy(() => import('./myphoenix/redux/containers/tables/NotScheduledCreditsTableContainer'));
const AppliedCreditsTableContainer = React.lazy(() => import('./myphoenix/redux/containers/tables/AppliedCreditsTableContainer'));
const NotAppliedCreditsTableContainer = React.lazy(() => import('./myphoenix/redux/containers/tables/NotAppliedCreditsTableContainer'));
const AllCreditsTableContainer = React.lazy(() => import('./myphoenix/redux/containers/tables/AllCreditsTableContainer'));
const CategorySummaryCreditsTableContainer = React.lazy(() => import('./myphoenix/redux/containers/tables/CategorySummaryTableContainer'));
const CreditLevelSummaryTableContainer = React.lazy(() => import('./myphoenix/redux/containers/tables/CreditLevelSummaryTableContainer'));
const LinkDetokenizerContainer = React.lazy(() => import('./myphoenix/redux/containers/LinkDetokenizerContainer'));
const ResourceTilesContainer = React.lazy(() => import('./myphoenix/redux/containers/ResourceTilesContainer'));
const GradeReportContainer = React.lazy(() => import('./myphoenix/redux/containers/GradeReportContainer'));
const AccordionTable = React.lazy(() => import('./myphoenix/components/accordion-table/v1/AccordionTable'));
const EnrollmentTasksContainer = React.lazy(() => import('./myphoenix/redux/containers/EnrollmentTasksContainer'));
const HelpCardContainer = React.lazy(() => import('./myphoenix/redux/containers/HelpCardContainer'));
const CountDown = React.lazy(() => import('./myphoenix/redux/containers/CountDownContainer'));
const IconItem = React.lazy(() => import('./myphoenix/components/icon-item/v1/IconItem'));
const Icon = React.lazy(() => import('./myphoenix/components/icon/v1/Icon'));
const PLASubmissions = React.lazy(() => import('./myphoenix/redux/containers/PLASubmissionsContainer'));
const PLASubmissionDocumentsContainer = React.lazy(() => import('./myphoenix/redux/containers/PLASubmissionDocumentsContainer'));
const AchievementsModalContainer = React.lazy(() => import('./myphoenix/redux/containers/AchievementsModalContainer'));
const EngagementContainer = React.lazy(() => import('./myphoenix/redux/containers/EngagementContainer'));
const ApplicationStatusSkinnyTileContainer = React.lazy(() => import('./myphoenix/redux/containers/ApplicationStatusSkinnyTileContainer'));
const AttendanceTileContainer = React.lazy(() => import('./myphoenix/redux/containers/AttendanceTileContainer'));
const CBEDAEngagement = React.lazy(() => import('./myphoenix/redux/containers/CBEDAEngagementContainer'));
const SearchBoxContainer = React.lazy(() => import('./myphoenix/redux/containers/SearchBoxContainer'));
const TransferCreditsContainer = React.lazy(() => import('./myphoenix/redux/containers/tables/TransferCreditsContainer'));
const TransferCreditsOverviewTable = React.lazy(() => import('./myphoenix/components/tables/transfer-credits-overview-table/v1/TransferCreditsOverviewTable'));
const TransferOverviewSummaryTable = React.lazy(() => import('./myphoenix/redux/containers/tables/TransferOverviewSummaryContainer'));
const TransferCreditsCoursesNotApplicableContainer = React.lazy(() => import('./myphoenix/redux/containers/tables/TransferCreditsNotApplicableContainer'));
const AcademicReprieveBannerContainer = React.lazy(() => import('./myphoenix/redux/containers/AcademicReprieveBannerContainer'));

const SkillsCardContainer = React.lazy(() => import('./myphoenix/redux/containers/SkillsCardContainer'));
WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700,900', 'IBM Plex Serif:300,400,500,700,900'],
  },
});
const { persistor, store } = configureStore();
const suspenseFallback = React.createElement(LazyProgress);
const { MyPhoenix } = window;
const { Environment } = MyPhoenix;
const { NeedsAuthentication } = Environment;
MyPhoenix.Components = [
  { container: AlumniNAEGuidanceContainer, target: '.react-alumni-NAE-guidance-v1-wrapper' },
  { container: AchievementsContainer, target: '.react-achievements-v1-wrapper' },
  { container: SkillsDashboardContainer, target: '.react-skills-dashboard-v1-wrapper' },
  { container: AssignedContactsContainer, target: '.react-assigned-contacts-v1-wrapper' },
  { container: CourseInfoContainer, target: '.react-course-info-v1-wrapper' },
  { container: FooterContainer, target: '.react-footer-v1-wrapper' },
  { container: HeaderContainer, target: '.react-header-v1-wrapper' },
  { container: PublicHeader, target: '.react-public-header-v1-wrapper' },
  { container: MockScenariosContainer, target: '.react-mock-scenarios-v1-wrapper' },
  { container: NotificationsContainer, target: '.react-notifications-v1-wrapper' },
  { container: PersonalizedGreetingContainer, target: '.react-personalized-greeting-v1-wrapper' },
  { container: TasksContainer, target: '.react-tasks-v1-wrapper' },
  { container: UniversityContactsContainer, target: '.react-university-contacts-v1-wrapper' },
  { container: PaigeChatBotContainer, target: '.react-paige-chatbot-v1-wrapper' },
  { container: SearchBoxContainer, target: '.react-search-v1-wrapper' },
  { container: SearchResultsContainer, target: '.react-search-results-v1-wrapper' },
  { container: PublicFooter, target: '.react-public-footer-v1-wrapper' },
  { container: ProgramInfoContainer, target: '.react-program-info-v1-wrapper' },
  { container: ProgramProgressContainer, target: '.react-program-progress-v1-wrapper' },
  { container: ScheduledCreditsTableContainer, target: '.react-scheduled-credits-table-v1-wrapper' },
  { container: NotScheduledCreditsTableContainer, target: '.react-not-scheduled-credits-table-v1-wrapper' },
  { container: AppliedCreditsTableContainer, target: '.react-applied-credits-table-v1-wrapper' },
  { container: NotAppliedCreditsTableContainer, target: '.react-not-applied-credits-table-v1-wrapper' },
  { container: AllCreditsTableContainer, target: '.react-all-credits-table-v1-wrapper' },
  { container: CategorySummaryCreditsTableContainer, target: '.react-category-summary-credits-table-v1-wrapper' },
  { container: CreditLevelSummaryTableContainer, target: '.react-credit-level-summary-table-v1-wrapper' },
  { container: LinkDetokenizerContainer, target: '.react-link-detokenizer-v1-wrapper' },
  { container: ResourceTilesContainer, target: '.react-resource-tiles-v1-wrapper' },
  { container: GradeReportContainer, target: '.react-grade-report-v1-wrapper' },
  { container: AccordionTable, target: '.react-accordion-table-v1-wrapper' },
  { container: EnrollmentTasksContainer, target: '.react-enrollment-tasks-v1-wrapper' },
  { container: HelpCardContainer, target: '.react-help-card-v1-wrapper' },
  { container: CountDown, target: '.react-count-down-v1-wrapper' },
  { container: IconItem, target: '.react-icon-item-v1-wrapper' },
  { container: Icon, target: '.react-icon-v1-wrapper' },
  { container: FeedbackModalContainer, target: '.react-feedback-loop-v1-wrapper' },
  { container: PLASubmissions, target: '.react-pla-submissions-v1-wrapper' },
  { container: PLASubmissionDocumentsContainer, target: '.react-pla-submission-documents-v1-wrapper' },
  { container: AchievementsModalContainer, target: '.react-achievements-modal-v1-wrapper' },
  { container: EngagementContainer, target: '.react-engagement-v1-wrapper' },
  { container: ApplicationStatusSkinnyTileContainer, target: '.react-application-status-v1-wrapper' },
  { container: SkillsCardContainer, target: '.react-skills-card-v1-wrapper' },
  { container: AttendanceTileContainer, target: '.react-attendance-tile-v1-wrapper' },
  { container: CBEDAEngagement, target: '.react-cbe-da-engagement-v1-wrapper' },
  { container: TransferCreditsContainer, target: '.react-transfer-credits-details-table-v1-wrapper' },
  { container: TransferCreditsCoursesNotApplicableContainer, target: '.react-transfer-credits-not-applicable-table-v1-wrapper' },
  { container: TransferCreditsOverviewTable, target: '.react-transfer-credits-overview-table-v1-wrapper' },
  { container: TransferOverviewSummaryTable, target: '.react-transfer-overview-summary-table-v1-wrapper' },
  { container: AcademicReprieveBannerContainer, target: '.react-academic-reprieve-banner-v1-wrapper' },
];
MyPhoenix.Persistor = persistor;
MyPhoenix.User = {
  personId: Cookies.get('MyPhoenix.PersonId'),
};
MyPhoenix.Analytics = { User: {}, Page: {} };

MyPhoenix.Utils.InitComponents = () => {
  MyPhoenix.Components.forEach((component) => {
    const targets = document.querySelectorAll(component.target);
    targets.forEach((element) => {
      const props = JSON.parse((element.getAttribute('data-json-properties')));
      const reactComponent = React.createElement(component.container,
        props);
      const suspenseComponent = React.createElement(React.Suspense,
        { fallback: suspenseFallback },
        [reactComponent]);
      const themedComponent = React.createElement(ThemeProvider,
        { theme },
        [suspenseComponent]);
      ReactDOM.render(React.createElement(Provider,
        { store },
        [themedComponent]), element);
    });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  MyPhoenix.Utils.InitComponents();
  if (NeedsAuthentication) {
    store.dispatch(fetchTokenIfNeeded())
      .then(() => { // Data needed ASAP independent of components (Analytics/Target)
        store.dispatch(fetchCurrentUserIfNeeded());
        store.dispatch(fetchProgramsIfNeeded());
      });
  }
});

window.addEventListener('load', () => {
  initializeEventHandling();
});
