const DEFAULT_STORAGE_CONTEXT = 'global';
const DEFAULT_STORAGE_PREFIX = 'edu.phoenix.myphoenix';

const getIdentifier = (context, identifier) => {
  const storageContext = context || DEFAULT_STORAGE_CONTEXT;
  return identifier || `${DEFAULT_STORAGE_PREFIX}.${storageContext}`;
};

const getSessionStorageData = (prop, context, identifier) => {
  if (typeof (Storage) === 'undefined') {
    return false; // in case storage is not supported
  }
  const id = getIdentifier(context, identifier);
  return sessionStorage.getItem(`${id}.${prop}`);
};
const setSessionStorageData = (data, context, identifier) => {
  if (typeof (Storage) !== 'undefined') {
    const id = getIdentifier(context, identifier);
    sessionStorage.setItem(`${id}.${data.key}`, data.value);
  }
};
const removeSessionStorageData = (prop, context, identifier) => {
  if (typeof (Storage) !== 'undefined') {
    const id = getIdentifier(context, identifier);
    sessionStorage.removeItem(`${id}.${prop}`);
  }
};
const removeBulkSessionStorageData = (prefix = DEFAULT_STORAGE_PREFIX) => {
  Object.keys(sessionStorage).forEach((key) => {
    if (key.startsWith(prefix)) {
      sessionStorage.removeItem(key);
    }
  });
};
const removeAllSessionStorageData = () => {
  if (typeof (Storage) !== 'undefined') {
    sessionStorage.clear();
  }
};

export {
  getSessionStorageData,
  setSessionStorageData,
  removeSessionStorageData,
  removeAllSessionStorageData,
  removeBulkSessionStorageData,
};
