import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styles from './Footer.styles';
import IconTile from '../../icon-tile/v1/IconTile';
import LinkList from '../../link-list/v1/LinkList';
import IconLink from '../../icon-link/v1/IconLink';
import Accordion from '../../accordion/v1/Accordion';
import Icon from '../../icon/v1/Icon';
import {
  getClassroomKey,
  getClassroomUrlFromAuthoredParamVal,
  hasAuthoredClassroomParams,
} from '../../../utils/classroom-functions';
import {
  createEventObject,
  useRaiseEventOnClick,
} from '../../../utils/event-functions';
import STATUS from '../../../redux/globalStatuses';
import { getCurrentDate } from '../../../utils/date-time-functions';

/**
 * Footer React Component
 */
function Footer({
  statusAuth,
  statusPrograms,
  loadCourses,
  loadPrograms,
  copyrightLinks,
  copyrightText,
  linkLists,
  logo,
  currentCourses,
  futureCourses,
  tiles,
  isCBEDA,
}) {
  const componentName = 'Footer';
  const classes = styles();
  const logoRef = useRef(null);
  const copyRightLinkRef = useRef(null);
  useRaiseEventOnClick([logoRef, copyRightLinkRef]);
  useEffect(() => {
    if (statusAuth === STATUS.FETCHED) {
      loadCourses();
      loadPrograms();
    }
  }, [statusAuth, loadCourses]);
  const defaultStyleType = 'default';
  let currentCourseLinkToShow = [];
  if (currentCourses.length) {
    currentCourseLinkToShow = currentCourses;
  } else if (!currentCourses.length && futureCourses.length) {
    const currentDate = getCurrentDate().valueOf();
    const nextCourseStartDate = moment(futureCourses[0].startDate).tz('America/Phoenix').valueOf();
    const daysBeforeStartDate = Math.ceil((nextCourseStartDate - currentDate)
            / (1000 * 60 * 60 * 24));
    currentCourseLinkToShow = daysBeforeStartDate < 6 ? futureCourses : [];
  }

  const currentCourseIsActive = (currentCourseLinkToShow.length && currentCourseLinkToShow[0].statusSubCode === 'CO')
    || (currentCourseLinkToShow.length && currentCourseLinkToShow[0].statusSubCode === 'EN')
    || (currentCourseLinkToShow.length && currentCourseLinkToShow[0].statusSubCode === 'SC');

  /* eslint-disable react/no-array-index-key */
  const linkListItems = linkLists.map((item, index) => (
    <div key={index} className="footer-v1-links-container">
      <div
        className="footer-v1-accordion d-block d-md-none"
      >
        <Accordion
          header={item.header}
          links={item.links}
          styleType={defaultStyleType}
          sectionIndex={index}
        />
      </div>
      <div
        className={`${classes.linkList} flex-fill d-none d-md-block`}
      >
        <LinkList
          parentComponentName={componentName}
          header={item.header}
          links={item.links}
          styleType="footer"
        />
      </div>
    </div>
  ));

  const tileItems = tiles.map((item, index) => {
    let { url } = item;
    if (hasAuthoredClassroomParams(url)) {
      let sectionId = '';
      if (currentCourseIsActive && currentCourses.length === 1) {
        sectionId = currentCourseLinkToShow[0]?.sectionId;
      }
      url = getClassroomUrlFromAuthoredParamVal(url,
        getClassroomKey({ isCBEDA }),
        { sectionId, isProgramsError: statusPrograms === STATUS.ERROR });
    }
    /* eslint-enable no-undef */
    return (
      <div key={index}>
        <div className={`${classes.iconLink} d-block d-md-none`}>
          <IconLink
            icon={item.icon}
            text={item.text}
            url={url}
            styleType="footer"
            target={item.target}
            parentComponentName={componentName}
          />
        </div>
        <div className="col-sm-auto d-none d-md-block">
          <IconTile
            icon={item.icon}
            text={item.text}
            url={url}
            styleType={defaultStyleType}
            target={item.target}
            parentComponent={componentName}
          />
        </div>
      </div>
    );
  });

  const lastItem = copyrightLinks && copyrightLinks.length;
  const copyrightLinkItems = copyrightLinks.map((item, i) => {
    if (i === (lastItem - 1)) {
      return (
        <span key={i}>
          {item.url && (
          <a
            href={item.url}
            target={item.target}
            className={`${item.dynamicCookieLink ? 'optanon-show-settings' : null} ${item.alwaysUnderlined ? classes.underlined : null}`}
            id={item.dynamicCookieLink ? 'onetrust-pc-btn-handler' : null}
            ref={copyRightLinkRef}
            data-raise-event-on-click={createEventObject(
              `${componentName}_CopyrightLink`,
              [{ url: item.url }, { target: item.target }, { text: item.text }],
            )}
          >
            {item.text}
          </a>
          )}
          {`${(item.nonLinkText ? ` ${item.nonLinkText}` : '')}`}
        </span>
      );
    }
    return (
      <span key={i}>
        {item.url && (
        <a
          href={item.url}
          className={`${item.dynamicCookieLink ? 'optanon-show-settings' : null} ${item.alwaysUnderlined ? classes.underlined : null}`}
          id={item.dynamicCookieLink ? 'onetrust-pc-btn-handler' : null}
          target={item.target}
          ref={copyRightLinkRef}
          data-raise-event-on-click={createEventObject(
            `${componentName}_CopyrightLink`,
            [{ url: item.url }, { target: item.target }, { text: item.text }],
          )}
        >
          {item.text}
        </a>
        )}
        {`${(item.nonLinkText ? ` ${item.nonLinkText}` : '')} | `}
      </span>
    );
  });
  /* eslint-enable react/no-array-index-key */

  return (
    <footer
      className={`${classes.root} container-fluid no-gutters`}
    >
      <div className={classes.flexContainer}>
        <div className="d-none d-md-block">
          <div className={`${classes.tiles} row align-items-center justify-content-center`}>
            {tileItems}
          </div>
          <div className={classes.linkListItems}>
            {linkListItems.map((ele) => ele)}
          </div>
        </div>

        <div
          role="complementary"
          aria-label="MyPhoenix Resources"
          className={`${classes.complementary} d-block d-md-none`}
        >
          <div className="footer-v1-tiles col">
            {tileItems}
          </div>
          <div className="footer-v1-links col">
            {linkListItems}
          </div>
        </div>

        <div className={classes.footNotes}>
          <div className={classes.logo}>
            <a
              href={logo.url}
              ref={logoRef}
              data-raise-event-on-click={createEventObject(
                `${componentName}_LogoLink`,
                [{ url: logo.url }, { text: logo.text }],
              )}
            >
              <Icon icon={logo.icon} />
              <span>{logo.text}</span>
            </a>
          </div>
          <div role="contentinfo" aria-label="Legal Information" className={classes.copyright}>
            <span>{copyrightLinkItems}</span>
            <div>{copyrightText}</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      target: PropTypes.string,
    }),
  ),
  linkLists: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          target: PropTypes.string,
        }),
      ),
    }),
  ),
  logo: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }),
  copyrightText: PropTypes.string,
  copyrightLinks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      nonLinkText: PropTypes.string,
      url: PropTypes.string.isRequired,
      target: PropTypes.string,
      dynamicCookieLink: PropTypes.bool,
      alwaysUnderlined: PropTypes.bool,
    }),
  ),
  loadCourses: PropTypes.func,
  loadPrograms: PropTypes.func,
  currentCourses: PropTypes.arrayOf(PropTypes.object).isRequired,
  futureCourses: PropTypes.arrayOf(PropTypes.object),
  statusAuth: PropTypes.string,
  statusPrograms: PropTypes.string,
  isCBEDA: PropTypes.bool,
};

Footer.defaultProps = {
  tiles: [],
  linkLists: [],
  logo: {
    url: '',
    text: '',
    icon: '',
  },
  copyrightLinks: [],
  copyrightText: '',
  loadCourses: () => {},
  loadPrograms: () => {},
  statusAuth: STATUS.UNFETCHED,
  statusPrograms: STATUS.UNFETCHED,
  futureCourses: [],
  isCBEDA: false,
};

export default Footer;
