import React from 'react';
import PropTypes from 'prop-types';
import AvatarMUI from '@material-ui/core/Avatar';
import Icon from '../../icon/v1/Icon';
import styles from './Avatar.styles';
import theme from '../../../theme';

/**
 * Avatar React Component
 */
function Avatar({
  avatarContentType,
  encodedAvatar,
  firstName,
  lastName,
  size,
  mobileSize,
  icon,
}) {
  // eslint-disable-next-line no-unused-vars
  const componentName = 'Avatar';
  const classes = styles({ size, mobileSize });

  let avatar;
  if (avatarContentType && encodedAvatar) { // Encoded Image
    avatar = <img src={`data:${avatarContentType};base64,${encodedAvatar}`} alt="Profile Avatar" aria-hidden="true" />;
  } else if (firstName && lastName) { // Initials
    avatar = (
      <span aria-hidden="true">
        {`${firstName.substring(0, 1)}${lastName.substring(0, 1)}`}
      </span>
    );
  } else { // Icon
    avatar = <Icon icon={icon} color={theme.palette.common.white} aria-hidden="true" />;
  }

  return <AvatarMUI className={classes.root}>{avatar}</AvatarMUI>;
}

Avatar.propTypes = {
  avatarContentType: PropTypes.string,
  encodedAvatar: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),
  mobileSize: PropTypes.string,
};

Avatar.defaultProps = {
  avatarContentType: '',
  encodedAvatar: '',
  firstName: '',
  lastName: '',
  icon: 'icon-phoenix-head',
  size: 'sm',
  mobileSize: 'sm',
};

export default Avatar;
