import STATUS from '../../globalStatuses';
import {
  REQUEST_SKILLSBASEDPROGRAMS,
  REQUEST_SKILLSBASEDPROGRAMS_ERROR,
  RECEIVE_SKILLSBASEDPROGRAMS,
} from './types';

export default function SKILLSBASEDPROGRAMS(state = { skillsBasedPrograms: {} }, action) {
  switch (action.type) {
    case REQUEST_SKILLSBASEDPROGRAMS:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case RECEIVE_SKILLSBASEDPROGRAMS:
      return {
        ...state,
        programs: action.skillsBasedPrograms,
        status: STATUS.FETCHED,
      };
    case REQUEST_SKILLSBASEDPROGRAMS_ERROR:
      return {
        ...state,
        status: STATUS.ERROR,
        error: action.error,
      };
    default:
      return state;
  }
}
