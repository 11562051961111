import axios from 'axios';
import { errorToMessage } from './error-functions';

const openReApply = () => {
  window.open('/apply');
};

const logoutRedirect = async (destination) => {
  const isAEMLogout = destination?.includes('services');
  if (isAEMLogout && window.MyPhoenix.Config.ECampus.LogoutEnabled) {
    await axios.get(`${window.MyPhoenix.Config.ECampus.Base}${window.MyPhoenix.Config.ECampus.LogoutUri}`,
      {
        timeout: 2000,
        withCredentials: true,
      })
      .catch((error) => {
        errorToMessage(error);
      });
  }
  if (isAEMLogout && window.MyPhoenix.Config.MultiMedia.LogoutEnabled) {
    await axios.get(`${window.MyPhoenix.Config.MultiMedia.Base}${window.MyPhoenix.Config.MultiMedia.LogoutUri}`,
      {
        timeout: 2000,
        withCredentials: true,
      })
      .catch((error) => {
        errorToMessage(error);
      });
  }
  window.location.href = destination;
};

export {
  logoutRedirect,
  openReApply,
};
