import STATUS from '../../globalStatuses';
import {
  REQUEST_CURRENT_USER, RECEIVE_CURRENT_USER, REQUEST_CURRENT_USER_ERROR,
  REQUEST_AVATAR, RECEIVE_AVATAR, REQUEST_AVATAR_ERROR,
} from './types';
import { errorToMessage } from '../../../utils/error-functions';
import { shouldFetchPersistedData } from '../../../utils/action-reducer-functions';

/* action creators */
function requestCurrentUser() {
  return { type: REQUEST_CURRENT_USER, receivedAt: Date.now() };
}

function receiveCurrentUser(person, names) {
  return {
    type: RECEIVE_CURRENT_USER, person, names, receivedAt: Date.now(),
  };
}

function requestCurrentUserError(error) {
  return { type: REQUEST_CURRENT_USER_ERROR, error, receivedAt: Date.now() };
}

function requestAvatar() {
  return { type: REQUEST_AVATAR };
}

function receiveAvatar(avatar) {
  return {
    type: RECEIVE_AVATAR, avatar, receivedAt: Date.now(),
  };
}

function requestAvatarError(error) {
  return { type: REQUEST_AVATAR_ERROR, error, receivedAt: Date.now() };
}

function fetchCurrentUser() {
  return async (dispatch, getState, { Services }) => {
    const { auth } = getState();
    dispatch(requestCurrentUser());
    try {
      const [personResponse, namesResponse] = await Promise.all([
        Services.PersonsService.getPersonByPersonId(auth.personId),
        Services.PersonsService.getNamesByPersonId(auth.personId),
      ]);

      return dispatch(
        receiveCurrentUser(personResponse.data, namesResponse.data),
      );
    } catch (error) { return dispatch(requestCurrentUserError(errorToMessage(error))); }
  };
}

function shouldFetchCurrentUser(state) {
  const { currentUser } = state;
  if (!currentUser) {
    return true;
  }
  return shouldFetchPersistedData(currentUser.status, currentUser.receivedAt);
}

export default function fetchCurrentUserIfNeeded() {
  // Note that the function also receives getState()
  // which lets you choose what to dispatch next.

  // This is useful for avoiding a network request if
  // a cached value is already available.

  return (dispatch, getState) => {
    if (shouldFetchCurrentUser(getState())) {
      // Dispatch a thunk from thunk!
      return dispatch(fetchCurrentUser());
    }
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  };
}

function fetchAvatar() {
  return async (dispatch, getState, { Services }) => {
    const { auth } = getState();
    dispatch(requestAvatar());
    try {
      const avatarResponse = await
      Services.PersonsService.getAvatarByPersonId(auth.personId);
      return dispatch(
        receiveAvatar(avatarResponse.data),
      );
    } catch (error) {
      // endpoint returns 404 if user has no avatar so don't treat it as an error.
      if (error.response && error.response.status && error.response.status === 404) {
        return dispatch(receiveAvatar({
          avatarContentType: '',
          encodedAvatar: '',
        }));
      }
      return dispatch(requestAvatarError(error));
    }
  };
}

function shouldFetchAvatar(state) {
  const { currentUser } = state;
  if (!currentUser) {
    return true;
  }
  return currentUser.avatarStatus === STATUS.UNFETCHED;
}

export function fetchAvatarIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchAvatar(getState())) {
      return dispatch(fetchAvatar());
    }
    return Promise.resolve();
  };
}
