import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formMain: {
    background: theme.palette.background.default,
    display: 'flex',
    width: '100%',
    padding: theme.spacing(1.3),
  },
  searchBox: {
    flexGrow: '3',
  },
  searchInput: {
    width: '95%',
  },
  searchBtnIcon: {
    justifyContent: 'flex-end',
    padding: 0,
  },
  searchMobileIcon: {
    padding: `0 ${theme.spacing(0.5)} 0 0`,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(1)} ${theme.spacing(1.6)}`,
    },
  },
  searchFormContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  searchBoxMobileView: {
    display: 'inline-flex',
    width: '34.4rem',
    height: '5.0rem',
    padding: `${theme.spacing(1.6)} ${theme.spacing(3.2)} ${theme.spacing(1.9)} ${theme.spacing(1.6)}`,
    backgroundSize: 'cover',
    fontSize: '1.6rem',
    color: theme.palette.common.black,
    lineHeight: '1.92rem',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  nav: (props) => ({
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    margin: `${theme.spacing(-9.2)} ${theme.spacing(1.5)} ${theme.spacing(0)}`,
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(-8.2)} ${theme.spacing(0)} ${theme.spacing(0)}`,
    },
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(7)} ${theme.spacing(1)} ${theme.spacing(0)}`,
    },
    padding: `${theme.spacing(7)} ${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(0)}`,
    right: theme.spacing(-1.4),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    top: theme.spacing(8.5),
    background: theme.palette.common.white,
    transformOrigin: '0 0',
    transform: props.searchOpen ? 'scale(1, 1)' : 'translate(100%, 0)',
    transition: 'transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1)',
    [theme.breakpoints.up('md')]: {
      width: '35.4rem',
    },
  }),
  formMobileView: {
    width: '40rem',
  },
  closeButtonText: {
    font: 'Roboto',
    textDecoration: 'underline',
    '&:hover': {
      background: theme.palette.common.white,
    },
  },
  buttonClose: {
    textAlign: 'right',
    paddingRight: theme.spacing(2),
  },
  divider: {
    borderBottom: `0.2rem solid ${theme.palette.background.default}`,
  },
}
));
