export default function LearningService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = `${MicroServices.Base}/learning`;

  const getActivitiesBySectionId = async (sectionId) => client.get(
    `${baseEndpointUrl}/v1/activities?sourceId=${sectionId}&sourceType=SECTION`, // &gradable=true
  );

  const getActivityGroupsBySectionId = async (sectionId) => client.get(
    `${baseEndpointUrl}/v1/course/activitygroups?sourceId=${sectionId}&sourceType=SECTION`,
  );

  const getAssessmentResultsByPersonIdAndSectionId = async (personId, sectionId) => client.get(
    `${baseEndpointUrl}/v1/assessmentresults?personId=${personId}&sourceId=${sectionId}&sourceType=SECTION`, // &isPersProfile=true
  );

  const getMembershipsByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/course/memberships?$filter=personId eq '${personId}'`,
  );

  const getMembershipsByPersonIdAndDateRange = async (personId, min, max) => client.get(
    `${baseEndpointUrl}/v1/course/memberships?$filter=personId eq '${personId}' and startDate ge ${min} and startDate le ${max}`,
  );

  const getSectionBySourceId = async (sourceId) => client.get(
    `${baseEndpointUrl}/v1/course/section/${sourceId}`,
  );

  const getAttendancesByMembershipId = async (membershipId) => client.get(
    `${baseEndpointUrl}/v1/attendances?membershipId=${membershipId}`,
  );

  const getMembershipsBySourceIdTypeAndRole = async (sourceId, sourceType, roleType) => client.get(
    `${baseEndpointUrl}/v1/course/memberships?$filter=sourceId eq '${sourceId}' and sourceType eq '${sourceType}' and roleType eq '${roleType}'`,
  );

  const getSkillsByTemplateId = async (templateId) => client.get(
    `${baseEndpointUrl}/v1/course/curriculum?$templateid=${templateId}`,
  );

  return {
    getActivitiesBySectionId,
    getActivityGroupsBySectionId,
    getAssessmentResultsByPersonIdAndSectionId,
    getMembershipsByPersonId,
    getMembershipsByPersonIdAndDateRange,
    getSectionBySourceId,
    getAttendancesByMembershipId,
    getMembershipsBySourceIdTypeAndRole,
    getSkillsByTemplateId,
  };
}
