import {
  SET_SELECTED_PROGRAM,
  SET_SELECTED_MEMBERSHIP,
  SET_LOA_SELECTED,
  SET_SOCIAL_MODAL_OPEN_STATE,
  SET_NOTFICATION_ALERT_STATE,
  SET_NOTFICATION_ALERT_COUNT_STATE,
} from './types';

/* action creators */
export function setSelectedMembership(membershipId) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_MEMBERSHIP,
      membershipId,
    });

    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  };
}

export function setSelectedProgram(programId) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_PROGRAM, programId,
    });

    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  };
}

export function setLoaSelected(isLoaSelected) {
  return (dispatch) => {
    dispatch({
      type: SET_LOA_SELECTED, isLoaSelected,
    });

    return Promise.resolve();
  };
}

export function setSocialModalOpenState(socialModalOpen) {
  return (dispatch) => {
    dispatch({
      type: SET_SOCIAL_MODAL_OPEN_STATE, socialModalOpen,
    });

    return Promise.resolve();
  };
}

export function setNotificationAlert(alertState) {
  return (dispatch) => {
    dispatch({ type: SET_NOTFICATION_ALERT_STATE, alertState });
    return Promise.resolve();
  };
}

export function setNotificationAlertCount(alertCount) {
  return (dispatch) => {
    dispatch({ type: SET_NOTFICATION_ALERT_COUNT_STATE, alertCount });
    return Promise.resolve();
  };
}
