export default function PersonsService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = `${MicroServices.Base}/persons`;
  const getPersonByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/person/${personId}`,
  );

  const getNamesByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/person/${personId}/names`,
  );

  const getAvatarByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/person/${personId}/avatar/image`,
  );

  const getDemographicsByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/person/${personId}/information`,
  );

  const getEventsByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/person/${personId}/events-count?context=message,logon`,
    // Can be adjusted to have context = logon or message independently
  );

  const getUserAttributesByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/person/attributes/${personId}`,
  );

  const getMilitaryStatus = async (personId) => client.get(
    `${baseEndpointUrl}/v1/person/${personId}/military`,
  );

  return {
    getPersonByPersonId,
    getNamesByPersonId,
    getAvatarByPersonId,
    getEventsByPersonId,
    getUserAttributesByPersonId,
    getDemographicsByPersonId,
    getMilitaryStatus,
  };
}
