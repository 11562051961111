/* GET phones */
export const REQUEST_PHONES = 'REQUEST_PHONES';
export const RECEIVE_PHONES = 'RECEIVE_PHONES';
export const REQUEST_PHONES_ERROR = 'REQUEST_PHONES_ERROR';
/* post phones */
export const REQUEST_POST_PHONE = 'REQUEST_POST_PHONE';
export const RECEIVE_POST_PHONE = 'RECEIVE_POST_PHONE';
export const REQUEST_POST_PHONE_ERROR = 'REQUEST_POST_PHONE_ERROR';
/* PUT phones */
export const REQUEST_UPDATE_PHONE = 'REQUEST_UPDATE_PHONE';
export const RECEIVE_UPDATE_PHONE = 'RECEIVE_UPDATE_PHONE';
export const REQUEST_UPDATE_PHONE_ERROR = 'REQUEST_UPDATE_PHONE_ERROR';
/* DELETE phones */
export const REQUEST_DELETE_PHONE = 'REQUEST_DELETE_PHONE';
export const RECEIVE_DELETE_PHONE = 'RECEIVE_DELETE_PHONE';
export const REQUEST_DELETE_PHONE_ERROR = 'REQUEST_DELETE_PHONE_ERROR';
