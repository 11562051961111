import { REQUEST_ACADEMIC_YEAR_DATA, REQUEST_ACADEMIC_YEAR_DATA_ERROR, RECEIVE_ACADEMIC_YEAR_DATA } from './types';
import { errorToMessage } from '../../../utils/error-functions';
import { shouldFetch } from '../../../utils/status-functions';
import fetchAcademicTermsIfNeeded from '../academicTerms/action';

function requestAcademicYearData(oid, ayCode) {
  return {
    oid,
    ayCode,
    type: REQUEST_ACADEMIC_YEAR_DATA,
  };
}
function requestAcademicYearDataError(oid, ayCode, error) {
  return {
    oid,
    ayCode,
    error,
    receivedAt: Date.now(),
    type: REQUEST_ACADEMIC_YEAR_DATA_ERROR,
  };
}

function receiveAcademicYearData(oid, ayCode, academicYearData) {
  return {
    oid,
    ayCode,
    academicYearData,
    receivedAt: Date.now(),
    type: RECEIVE_ACADEMIC_YEAR_DATA,
  };
}

function fetchAcademicYearData(oid, ayCode) {
  return async (dispatch, getState, { Services }) => {
    dispatch(requestAcademicYearData(oid, ayCode));
    return Services.StudentService.getAcademicYearDataByOid(oid)
      .then((academicYearDataResponse) => {
        dispatch(receiveAcademicYearData(oid, ayCode, academicYearDataResponse.data[0]));
        const termCode = academicYearDataResponse.data[0]
          && academicYearDataResponse.data[0].academicTerms[0]
          ? academicYearDataResponse.data[0].academicTerms[0].termCode : '';
        if (termCode) {
          dispatch(fetchAcademicTermsIfNeeded(termCode));
        }
      })
      .catch((error) => {
        dispatch(requestAcademicYearDataError(oid, ayCode, errorToMessage(error)));
      });
  };
}

function shouldFetchAcademicYearData(state, ayCode) {
  const { academicYear } = state;
  const ayCodeData = academicYear.byCode[ayCode];
  return shouldFetch(ayCodeData, true);
}

export default function fetchAcademicYearDataIfNeeded(oid, ayCode) {
  return (dispatch, getState) => {
    if (shouldFetchAcademicYearData(getState(), ayCode)) {
      return dispatch(fetchAcademicYearData(oid, ayCode));
    }
    return Promise.resolve();
  };
}
