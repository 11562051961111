import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: ({ condensed }) => {
    const root = {
      display: 'flex',
      flex: '1 0',
    };
    if (condensed) {
      root.alignItems = 'center';
    }
    return root;
  },
  sectionDetails: {
    overflow: 'hidden',
  },
  sectionIcon: {
    flex: '0 0 2.8rem',
    height: '2.8rem',
    marginRight: theme.spacing(0.5),
  },
  sectionLink: {
    color: theme.palette.primary.main,
    fontSize: '1.6rem',
    marginTop: theme.spacing(1),
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:hover svg': {
      transform: 'translate(0.4rem, 0)',
    },
    '& svg': {
      transition: 'transform 0.2s linear',
    },
  },
  sectionTitle: ({ condensed }) => ({
    fontSize: '1.8rem',
    fontWeight: condensed ? 400 : 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  templateCode: {
    marginRight: theme.spacing(1.5),
  },
  cbeDASectionTitle: {
    fontSize: '1.8rem',
    fontWeight: 700,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  cbeDATemplateCode: {
    margin: theme.spacing(0, 1.5, 2, 0),
  },
  templateLink: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: theme.palette.text.primary,
    borderBottom: `0.2rem solid ${theme.palette.primary.main}`,
    height: '1.8rem',
    '&:hover': {
      textDecoration: 'none',
      borderColor: theme.palette.text.primary,
    },
  },
  goToSectionText: {
    marginRight: theme.spacing(0.8),
    color: theme.palette.primary.main,
  },
}));
