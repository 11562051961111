/*
   Tokens
 */
export const DYNAMIC_PROGRAM_ID_TOKEN = 'DYNAMIC_PROGRAM_ID';
export const USERS_UNIVERSITY_EMAIL = 'USERS_UNIVERSITY_EMAIL';
export const ALL_AUTHOR_TOKENS = [
  DYNAMIC_PROGRAM_ID_TOKEN,
  USERS_UNIVERSITY_EMAIL,
];

/*
   Params
 */

// param keys
export const CLASSROOM_URL_PARAM_KEY = 'myphxClassroomUrl';

// param values
export const CLASSROOM_BASE_URL_VAL = 'base';
export const CLASSROOM_CURRENT_COURSE_URL_VAL = 'currentCourse';
