import moment from 'moment';
import orderBy from 'lodash.orderby';
import STATUS from '../../globalStatuses';
import {
  REQUEST_COURSE_MEMBERSHIPS, RECEIVE_COURSE_MEMBERSHIPS, REQUEST_COURSE_MEMBERSHIPS_ERROR,
  RECEIVE_COURSE_SECTION, REQUEST_COURSE_SECTION_ERROR, REQUEST_COURSE_SECTION,
} from './types';
import { addColonToHourOffset, getCurrentDate } from '../../../utils/date-time-functions';
import { getPageLoadIndex } from '../../../utils/page-functions';

const sortMemberships = (memberships) => {
  const allMembershipIds = [];
  const currentMembershipIds = [];
  const futureMembershipIds = [];
  const pastMembershipIds = [];
  const now = getCurrentDate(moment.tz.guess(true));

  orderBy(memberships, ['startDate']).forEach((membership) => {
    allMembershipIds.push(membership.id);
    if (moment(membership.endDate).isBefore(now)) {
      pastMembershipIds.push(membership.id);
    } else if (moment(membership.startDate).isAfter(now)) {
      futureMembershipIds.push(membership.id);
    } else {
      currentMembershipIds.push(membership.id);
    }
  });

  return {
    allMembershipIds,
    currentMembershipIds,
    futureMembershipIds,
    pastMembershipIds: pastMembershipIds.reverse(),
  };
};

export default function courses(state = { courses: {} }, action) {
  switch (action.type) {
    case REQUEST_COURSE_MEMBERSHIPS:
      return {
        ...state,
        byRangeId: {
          ...state.byRangeId,
          [action.rangeId]: {
            allMembershipIds: [],
            currentMembershipIds: [],
            futureMembershipIds: [],
            pastMembershipIds: [],
            receivedAt: action.receivedAt,
            pageLoadIndex: getPageLoadIndex(),
            status: STATUS.FETCHING,
          },
        },
      };
    case RECEIVE_COURSE_MEMBERSHIPS:
      return {
        ...state,
        byMembershipId: {
          ...state.byMembershipId,
          ...action.memberships.reduce((byMembershipId, membership) => {
            // eslint-disable-next-line no-param-reassign
            byMembershipId[membership.id] = {
              membershipId: membership.id,
              grade: membership.grade ? membership.grade : '',
              sectionId: membership.sourceId ? membership.sourceId : '',
              statusSubCode: membership.statusSubCode || membership.status,
              startDate: addColonToHourOffset(membership.startDate),
              endDate: addColonToHourOffset(membership.endDate),
              daTermCode: membership.daAcademicYearTerm ? membership.daAcademicYearTerm.termCode : '',
              offeringType: membership?.offeringType,
              status: STATUS.UNFETCHED,
              ...state.byMembershipId[membership.id],
            };
            return byMembershipId;
          }, {}),
        },
        byRangeId: {
          ...state.byRangeId,
          [action.rangeId]: {
            ...sortMemberships(action.memberships),
            receivedAt: action.receivedAt,
            status: STATUS.FETCHED,
          },
        },
      };
    case REQUEST_COURSE_MEMBERSHIPS_ERROR:
      return {
        ...state,
        byRangeId: {
          ...state.byRangeId,
          [action.rangeId]: {
            ...state.byRangeId[action.rangeId],
            status: STATUS.ERROR,
            error: action.error,
          },
        },
      };
    case REQUEST_COURSE_SECTION:
      return {
        ...state,
        byMembershipId: {
          ...state.byMembershipId,
          [action.membershipId]: {
            ...state.byMembershipId[action.membershipId],
            receivedAt: action.receivedAt,
            pageLoadIndex: getPageLoadIndex(),
            status: STATUS.FETCHING,
          },
        },
      };
    case RECEIVE_COURSE_SECTION:
      return {
        ...state,
        byMembershipId: {
          ...state.byMembershipId,
          [action.membershipId]: {
            ...state.byMembershipId[action.membershipId],
            defaultCredits: action.section.defaultCredits,
            description: action.section.description,
            templateCode: action.section.templateCode,
            title: action.section.title,
            type: action.section.type,
            receivedAt: action.receivedAt,
            status: STATUS.FETCHED,
          },
        },
      };
    case REQUEST_COURSE_SECTION_ERROR:
      return {
        ...state,
        byMembershipId: {
          ...state.byMembershipId,
          [action.membershipId]: {
            ...state.byMembershipId[action.membershipId],
            status: STATUS.ERROR,
            error: action.error,
          },
        },
      };
    default:
      return state;
  }
}
