import STATUS from '../../globalStatuses';
import {
  REQUEST_PROGRAM_CONTACTS, RECEIVE_PROGRAM_CONTACTS, REQUEST_PROGRAM_CONTACTS_ERROR,
} from './types';

const addProgramContactsByProgramId = (
  state,
  programId,
  programContactsObject,
  receivedAt,
  status,
  error,
) => {
  const newState = { ...state };
  const enrollmentRepresentativesList = [];
  const academicCounselorsList = [];

  if (programContactsObject
    && programContactsObject.univContacts
    && programContactsObject.univContacts.length) {
    programContactsObject.univContacts.forEach((entry) => {
      academicCounselorsList.push(entry.support
        .filter((rep) => rep.contactGroup.includes('AC') && rep)
        .map((eligibleRep) => ({
          firstName: eligibleRep.person.name.firstName,
          lastName: eligibleRep.person.name.lastName,
          phoneNumber: eligibleRep.person.phones[0].phoneNumber,
          email: eligibleRep.person.email[0].emailAddress,
          location: entry.academicProgram.offeredAtLocation.name,
        })));
      enrollmentRepresentativesList.push(entry.support
        .filter((rep) => rep.contactGroup.includes('EC') && rep)
        .map((eligibleRep) => ({
          firstName: eligibleRep.person.name.firstName,
          lastName: eligibleRep.person.name.lastName,
          phoneNumber: eligibleRep.person.phones[0].phoneNumber,
          email: eligibleRep.person.email[0].emailAddress,
          location: entry.academicProgram.offeredAtLocation.name,
          programCode: entry.academicProgram.academicProgramCode,
        })));
    });
  }
  newState.byProgramId[programId] = {
    academicCounselors: [].concat(...academicCounselorsList),
    enrollmentRepresentatives: [].concat(...enrollmentRepresentativesList),
    receivedAt,
    status: status || STATUS.FETCHED,
    error,
  };
  return newState;
};

export default function programContacts(state = { programContacts: {} }, action) {
  switch (action.type) {
    case REQUEST_PROGRAM_CONTACTS:
      return addProgramContactsByProgramId(
        state,
        action.programId,
        null,
        action.receivedAt,
        STATUS.FETCHING,
      );
    case RECEIVE_PROGRAM_CONTACTS:
      return addProgramContactsByProgramId(
        state,
        action.programId,
        action.programContacts,
        action.receivedAt,
        STATUS.FETCHED,
      );
    case REQUEST_PROGRAM_CONTACTS_ERROR:
      return addProgramContactsByProgramId(
        state,
        action.programId,
        null,
        action.receivedAt,
        STATUS.ERROR,
        action.error,
      );
    default:
      return state;
  }
}
