import STATUS from '../../globalStatuses';
import {
  REQUEST_ACADEMIC_TERMS, RECEIVE_ACADEMIC_TERMS, REQUEST_ACADEMIC_TERMS_ERROR,
} from './types';

export default function academicTerms(state = { academicTerms: {} }, action) {
  switch (action.type) {
    case REQUEST_ACADEMIC_TERMS:
      return {
        ...state,
        byCode: {
          ...state.byCode,
          [action.termCode]: {
            ...state.byCode[action.termCode],
            status: STATUS.FETCHING,
          },
        },
      };
    case RECEIVE_ACADEMIC_TERMS:
      return {
        ...state,
        byCode: {
          ...state.byCode,
          [action.termCode]: {
            oid: action.termData.oid,
            code: action.termData.code,
            description: action.termData.description,
            startDate: action.termData.startDate,
            endDate: action.termData.endDate,
            status: STATUS.FETCHED,
            receivedAt: action.receivedAt,
          },
        },
        allCodes: state.allCodes.includes(action.termData.code)
          ? state.allCodes : [...state.allCodes, action.termData.code],
      };
    case REQUEST_ACADEMIC_TERMS_ERROR:
      return {
        ...state,
        byCode: {
          ...state.byCode,
          [action.termCode]: {
            ...state.byCode[action.termCode],
            error: action.error,
            status: STATUS.ERROR,
          },
        },
        allCodes: [...state.allCodes],
      };
    default:
      return state;
  }
}
