import STATUS from '../../globalStatuses';
import {
  REQUEST_ALERTS, RECEIVE_ALERTS, REQUEST_ALERTS_ERROR,
  INVALIDATE_ALERTS, REQUEST_NOTIFICATIONS, RECEIVE_NOTIFICATIONS, REQUEST_NOTIFICATIONS_ERROR,
  REQUEST_MARK_EVENT_AS_READ, REQUEST_MARK_EVENT_AS_READ_ERROR, RECEIVE_MARK_EVENT_AS_READ,
  REQUEST_MARK_NOTIFICATION_AS_READ_OR_UNREAD,
  RECEIVE_MARK_NOTIFICATION_AS_READ_OR_UNREAD,
  REQUEST_MARK_NOTIFICATION_AS_READ_OR_UNREAD_ERROR,
} from './types';
import { errorToMessage } from '../../../utils/error-functions';
import { achievementTypes } from '../../../utils/achievements-functions';

function requestAlerts() {
  return { type: REQUEST_ALERTS };
}
function requestNotifications() {
  return { type: REQUEST_NOTIFICATIONS };
}
function receiveAlerts(alerts) {
  return {
    type: RECEIVE_ALERTS, alerts, receivedAt: Date.now(),
  };
}
function requestAlertsError(error) {
  return { type: REQUEST_ALERTS_ERROR, error, receivedAt: Date.now() };
}

function requestNotificationsError(error) {
  return { type: REQUEST_NOTIFICATIONS_ERROR, error, receivedAt: Date.now() };
}
function invalidateAlerts() {
  return { type: INVALIDATE_ALERTS };
}
function requestMarkEventAsRead() {
  return { type: REQUEST_MARK_EVENT_AS_READ };
}
function receiveMarkEventAsRead(notificationId) {
  return { type: RECEIVE_MARK_EVENT_AS_READ, notificationId };
}
function markEventAsReadError(error) {
  return { type: REQUEST_MARK_EVENT_AS_READ_ERROR, error };
}

function requestMarkNotificationAsReadOrUnRead() {
  return { type: REQUEST_MARK_NOTIFICATION_AS_READ_OR_UNREAD };
}
function receiveMarkNotificationAsReadOrUnRead(notificationId) {
  return { type: RECEIVE_MARK_NOTIFICATION_AS_READ_OR_UNREAD, notificationId };
}
function markNotificationAsReadOrUnReadError(error) {
  return { type: REQUEST_MARK_NOTIFICATION_AS_READ_OR_UNREAD_ERROR, error };
}

function receiveNotifications(notifications) {
  return {
    type: RECEIVE_NOTIFICATIONS, notifications, receivedAt: Date.now(),
  };
}

function fetchAlerts() {
  return async (dispatch, getState, { Services }) => {
    dispatch(requestAlerts());
    try {
      const { auth } = getState();
      const alertsResponse = await
      Services.CommunicationService.getAlertsByPersonId(auth.personId);
      return dispatch(receiveAlerts(alertsResponse.data));
    } catch (error) { return dispatch(requestAlertsError(errorToMessage(error))); }
  };
}

function fetchNotifications() {
  return async (dispatch, getState, { Services }) => {
    dispatch(requestNotifications());
    try {
      const { auth } = getState();
      const notificationsResponse = await
      Services.CommunicationService
        .getNotificationsByPersonId(auth.personId);
      return dispatch(receiveNotifications(notificationsResponse.data));
    } catch (error) {
      return dispatch(requestNotificationsError(errorToMessage(error)));
    }
  };
}
function shouldFetchAlerts(state) {
  const { alerts } = state;
  if (!alerts.systemAlerts) {
    return true;
  }
  return alerts.status === STATUS.UNFETCHED || alerts.status === STATUS.INVALIDATED;
}

function shouldFetchNotifications(state) {
  const { alerts } = state;
  if (!alerts.notifications || !alerts.messages || !alerts.logonEvents) {
    return true;
  }
  return alerts.notificationsStatus === STATUS.UNFETCHED
    || alerts.notificationsStatus === STATUS.INVALIDATED;
}
export default function fetchAlertsIfNeeded() {
  // Note that the function also receives getState()
  // which lets you choose what to dispatch next.
  // This is useful for avoiding a network request if
  // a cached value is already available.
  return (dispatch, getState) => {
    if (shouldFetchAlerts(getState())) {
      // Dispatch a thunk from thunk!
      return dispatch(fetchAlerts());
    }
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  };
}

export function fetchNotificationsIfNeeded() {
  // Note that the function also receives getState()
  // which lets you choose what to dispatch next.
  // This is useful for avoiding a network request if
  // a cached value is already available.
  return (dispatch, getState) => {
    if (shouldFetchNotifications(getState())) {
      // Dispatch a thunk from thunk!
      return dispatch(fetchNotifications());
    }
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  };
}
/* ===========  Reset Alerts ============ */
export function resetAlerts() {
  return (dispatch) => {
    dispatch(invalidateAlerts());
    return Promise.resolve();
  };
}
/* ===========  End of Reset Alerts ============ */

const initiateCallBack = (callBackAction) => {
  if (callBackAction && callBackAction.action === 'REDIRECT') {
    if (callBackAction.achievementType === achievementTypes.skill) {
      window.open(
        [callBackAction.link], '_blank',
      );
    } else {
      window.location.href = callBackAction.link;
    }
  }
};

const updateNotifications = (dispatch, callBackAction) => {
  if (callBackAction) {
    initiateCallBack(callBackAction);
  } else {
    dispatch(fetchNotificationsIfNeeded());
    dispatch(fetchAlertsIfNeeded());
  }
};

/* =========== Mark LogOnEvent as Read =========== */
export function markEventAsRead(notificationId, callBackAction) {
  return async (dispatch, getState, { Services }) => {
    dispatch(requestMarkEventAsRead());
    const { auth } = getState();
    return Services.CommunicationService
      .putNotificationIdAsReadOrUnReadByPersonID(auth.personId, notificationId)
      .then((response) => {
        dispatch(receiveMarkEventAsRead(response.data.id));
        dispatch(resetAlerts())
          .then(updateNotifications(dispatch, callBackAction));
      })
      .catch((error) => {
        initiateCallBack(callBackAction);
        dispatch(markEventAsReadError(errorToMessage(error)));
      });
  };
}

/* =========== Mark Notification as Read or UnRead =========== */
export function markNotificationAsReadOrUnRead(notificationId, markRead) {
  return async (dispatch, getState, { Services }) => {
    dispatch(requestMarkNotificationAsReadOrUnRead());
    const { auth } = getState();
    return Services.CommunicationService
      .putNotificationIdAsReadOrUnReadByPersonID(auth.personId, notificationId, markRead)
      .then((response) => {
        dispatch(receiveMarkNotificationAsReadOrUnRead(response.data.id));
      })
      .catch((error) => {
        dispatch(markNotificationAsReadOrUnReadError(errorToMessage(error)));
      });
  };
}
