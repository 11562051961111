import { connect } from 'react-redux';
import moment from 'moment-timezone';
import fetchCoursesIfNeeded from '../domains/courses/actions';
import HeaderSectionList from '../../components/header-section-list/v1/HeaderSectionList';
import { getCurrentCoursesByRange, getFutureCoursesByRange } from '../../utils/course-functions';
import { getCurrentDate } from '../../utils/date-time-functions';
import fetchProgramsIfNeeded from '../domains/programs/actions';

const mapStateToProps = (state, ownProps) => {
  const primaryProgram = state.programs.byId[state.programs.primaryProgramId] || {};
  const isCBEDA = primaryProgram && primaryProgram.cbeDaProgram === 'TRUE';
  let heading = 'Current Classes';
  // Sections consist of current memberships that are CO/EN/SC
  let sections = getCurrentCoursesByRange(state.courses)
    .filter((section) => section.statusSubCode === 'CO' || section.statusSubCode === 'EN' || section.statusSubCode === 'SC');
  if (!sections.length) { // Sections consist of future memberships that are CO/EN/SC
    heading = 'Upcoming Classes';
    sections = getFutureCoursesByRange(state.courses)
      .filter((section) => section.statusSubCode === 'CO' || section.statusSubCode === 'EN' || section.statusSubCode === 'SC')
      .filter((course) => {
        const currentDate = getCurrentDate().valueOf();
        const nextCourseStartDate = moment(course.startDate).tz('America/Phoenix').valueOf();
        const daysBeforeStartDate = Math.ceil((nextCourseStartDate - currentDate)
          / (1000 * 60 * 60 * 24));
        return (daysBeforeStartDate <= 7 && daysBeforeStartDate > 0 && course.statusSubCode === 'SC') ? course : false;
      });
  }
  return {
    heading,
    sections,
    statusAuth: state.auth.status,
    isCBEDA,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadCourses: () => { dispatch(fetchCoursesIfNeeded()); },
  loadPrograms: () => dispatch(fetchProgramsIfNeeded()),
});

const HeaderSectionListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderSectionList);

export default HeaderSectionListContainer;
