/**
 * Fetches the events of the current user
 * @returns {Function}
 */

import { REQUEST_EVENTS, REQUEST_EVENTS_ERROR, RECEIVE_EVENTS } from './types';
import { errorToMessage } from '../../../utils/error-functions';
import { shouldFetch } from '../../../utils/status-functions';

function requestEvents() {
  return {
    type: REQUEST_EVENTS,
  };
}
function requestEventsError(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: REQUEST_EVENTS_ERROR,
  };
}

function receiveEvents(data) {
  return {
    data,
    receivedAt: Date.now(),
    type: RECEIVE_EVENTS,
  };
}

function fetchEvents() {
  return async (dispatch, getState, { Services }) => {
    const { auth } = getState();
    dispatch(requestEvents());
    return Services.PersonsService.getEventsByPersonId(auth.personId)
      .then((response) => {
        const { data } = response;
        dispatch(receiveEvents(data));
      })
      .catch((err) => dispatch(requestEventsError(errorToMessage(err))));
  };
}

function shouldFetchEvents(state) {
  const { events } = state;
  return shouldFetch(events, false);
}

export default function fetchEventsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchEvents(getState())) {
      return dispatch(fetchEvents());
    }
    return Promise.resolve();
  };
}
