import { useEffect } from 'react';

export const EVENT_QUEUE_THROTTLE_MS = 5;
export const ERROR_EVENT_NAME = 'myPhoenixErrorEvent';
export const DISPLAY_EVENT_NAME = 'myPhoenixDisplayEvent';
export const DISPLAY_ATTRIBUTE_NAME = 'data-raise-event-on-display';
export const CLICK_EVENT_NAME = 'myPhoenixClickEvent';
export const CLICK_ATTRIBUTE_NAME = 'data-raise-event-on-click';

let handlersAreInitialized = false;
const preInitializedEventQueue = [];

const getCurrentPage = () => {
  const { MyPhoenix } = window;
  return (MyPhoenix && MyPhoenix.Analytics) ? MyPhoenix.Analytics.Page : undefined;
};

const deserializeEventObject = (data) => {
  const { componentName, properties } = JSON.parse(data);
  let props = { };
  properties.forEach((prop) => {
    props = { ...props, ...prop };
  });
  return { componentName, page: getCurrentPage(), properties: props };
};

const dispatchEvent = (myPhxEvent) => {
  if (handlersAreInitialized) {
    document.dispatchEvent(myPhxEvent);
  } else {
    preInitializedEventQueue.push(myPhxEvent);
  }
};

export const publishMyPhoenixErrorEvent = (componentName, properties) => {
  const eventObject = { componentName, page: getCurrentPage(), properties };
  dispatchEvent(new CustomEvent(ERROR_EVENT_NAME, { detail: eventObject }));
};

export const publishMyPhoenixDisplayEvent = (element) => {
  const data = element.getAttribute(DISPLAY_ATTRIBUTE_NAME);
  if (data) {
    const eventObject = deserializeEventObject(data);
    dispatchEvent(new CustomEvent(DISPLAY_EVENT_NAME, { detail: eventObject }));
  }
};

export const publishMyPhoenixClickEvent = (event) => {
  if (event && event.currentTarget) {
    const element = event.currentTarget;
    const data = element.getAttribute(CLICK_ATTRIBUTE_NAME);
    if (data) {
      const eventObject = deserializeEventObject(data);
      dispatchEvent(new CustomEvent(CLICK_EVENT_NAME, { detail: eventObject }));
    }
  }
};

const addDisplayEventHandlingIfNeeded = (element) => {
  const { MyPhoenix } = window;
  const isRaiseEventOnDisplayEnabled = MyPhoenix && MyPhoenix.Config && MyPhoenix.Config.Features
    && typeof MyPhoenix.Config.Features.isRaiseEventOnDisplayEnabled !== 'undefined'
    ? MyPhoenix.Config.Features.isRaiseEventOnDisplayEnabled
    : true;

  if (isRaiseEventOnDisplayEnabled
      && element
      && !element.getAttribute(`${DISPLAY_ATTRIBUTE_NAME}-added`)) {
    element.setAttribute(`${DISPLAY_ATTRIBUTE_NAME}-added`, 'true');
    publishMyPhoenixDisplayEvent(element);
  }
};

const addClickEventHandlingIfNeeded = (element) => {
  const { MyPhoenix } = window;
  const isRaiseEventOnClickEnabled = MyPhoenix && MyPhoenix.Config && MyPhoenix.Config.Features
    && typeof MyPhoenix.Config.Features.isRaiseEventOnClickEnabled !== 'undefined'
    ? MyPhoenix.Config.Features.isRaiseEventOnClickEnabled
    : true;

  if (isRaiseEventOnClickEnabled
      && element
      && !element.getAttribute(`${CLICK_ATTRIBUTE_NAME}-added`)) {
    element.setAttribute(`${CLICK_ATTRIBUTE_NAME}-added`, 'true');
    element.addEventListener('click', publishMyPhoenixClickEvent);
  }
};

export const useRaiseEventOnDisplay = (elementRefArray) => {
  useEffect(() => {
    elementRefArray.forEach((elementRef) => {
      if (elementRef && elementRef.current) {
        if (Array.isArray(elementRef.current)) {
          elementRef.current.forEach((element) => {
            addDisplayEventHandlingIfNeeded(element.current);
          });
        } else {
          addDisplayEventHandlingIfNeeded(elementRef.current);
        }
      }
    });
  }, []);
};

export const useRaiseEventOnClick = (elementRefArray) => {
  useEffect(() => {
    elementRefArray.forEach((elementRef) => {
      if (elementRef && elementRef.current) {
        if (Array.isArray(elementRef.current)) {
          elementRef.current.forEach((element) => {
            addClickEventHandlingIfNeeded(element.current);
          });
        } else {
          addClickEventHandlingIfNeeded(elementRef.current);
        }
      }
    });
  }, []);
};

export const createEventObject = (name, propertiesArray) => JSON.stringify({
  componentName: name,
  properties: propertiesArray,
});

export const initializeEventHandling = () => {
  // add display event handling for elements already rendered
  const displayElements = document.querySelectorAll(`[${DISPLAY_ATTRIBUTE_NAME}]`);
  displayElements.forEach((element) => {
    addDisplayEventHandlingIfNeeded(element);
  });
  // add click event handling for elements already rendered
  const clickElements = document.querySelectorAll(`[${CLICK_ATTRIBUTE_NAME}]`);
  clickElements.forEach((element) => {
    addClickEventHandlingIfNeeded(element);
  });
  // turn of pre-init flag
  handlersAreInitialized = true;
  // loop through and dispatch any queued events, but slightly throttled
  let nextEvent = preInitializedEventQueue.shift();
  let throttledDispatchInterval;
  const throttledDispatch = () => {
    if (nextEvent) {
      dispatchEvent(nextEvent);
      nextEvent = preInitializedEventQueue.shift();
    } else {
      clearInterval(throttledDispatchInterval);
    }
  };
  throttledDispatchInterval = setInterval(throttledDispatch, EVENT_QUEUE_THROTTLE_MS);
};
