import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& h2': {
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: '1.5rem',
      margin: 0,
    },
    '& ul': {
      padding: `${theme.spacing(1.5)} 0 0 0`,
      margin: 0,
      '& li': {
        listStyle: 'none',
        paddingTop: theme.spacing(1.5),
        '&:first-child': {
          paddingTop: '0',
        },
      },
    },
    '& a': {
      fontWeight: 500,
      fontSize: '1.7rem',
    },
  },
  footer: {
    '& h2': {
      fontWeight: 700,
      fontSize: '1.4rem',
    },
    '& a': {
      fontWeight: 400,
      fontSize: '1.4rem',
      '&:focus': {
        outline: `0.1rem solid ${theme.palette.text.primary}`,
        outlineOffset: theme.spacing(0.3),
      },
    },
  },

  linkList: {
    '&:first-child': {
      marginTop: theme.spacing(1),
    },
    marginTop: theme.spacing(3.5),
  },
}));
