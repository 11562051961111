import moment from 'moment-timezone';
import STATUS from '../../globalStatuses';
import {
  REQUEST_PROGRAMS, RECEIVE_PROGRAMS,
  REQUEST_PROGRAMS_ERROR, INVALIDATE_PROGRAMS,
  REQUEST_GRADUATION_DATE,
  RECEIVE_GRADUATION_DATE,
  REQUEST_GRADUATION_DATE_ERROR,
} from './types';
import { transformById } from '../../../utils/action-reducer-functions';
import { getPageLoadIndex } from '../../../utils/page-functions';
import { getCurrentDate } from '../../../utils/date-time-functions';

export default function programs(state = { programs: {} }, action) {
  switch (action.type) {
    case REQUEST_PROGRAMS:
      return {
        ...state,
        receivedAt: action.receivedAt,
        pageLoadIndex: getPageLoadIndex(),
        status: STATUS.FETCHING,
      };
    case RECEIVE_PROGRAMS: {
      const transformedById = transformById(state, action.programs);
      transformedById.primaryProgramId = action.programs.primaryProgramId;
      const primaryProgram = transformedById.byId[transformedById.primaryProgramId];
      if (primaryProgram) {
        window.MyPhoenix.User.programCode = primaryProgram.code;
        window.MyPhoenix.User.programLevel = primaryProgram.qualificationLevel;
        window.MyPhoenix.User.programName = primaryProgram.name;
        window.MyPhoenix.User.programType = primaryProgram.type;
        window.MyPhoenix.User.programVersion = primaryProgram.version;
        window.MyPhoenix.Analytics.User.creditsTransferred = primaryProgram
          .creditsSummary.transferred;
        window.MyPhoenix.Analytics.User.startDate = primaryProgram.startDate;
        window.MyPhoenix.Analytics.User.programCompletionDate = primaryProgram
          .programCompletionDate;
        const enrollmentAffiliation = primaryProgram.statuses ? primaryProgram.statuses.find((status) => (status.statusType === 'EnrollmentStatus')) : '';
        const { qualificationLevel } = primaryProgram;
        if (qualificationLevel === 'UG') { window.MyPhoenix.Analytics.User.programLevel = 'UnderGraduate Level'; }
        if (qualificationLevel === 'GR') { window.MyPhoenix.Analytics.User.programLevel = 'Graduate Level'; }
        if (qualificationLevel === 'DO') { window.MyPhoenix.Analytics.User.programLevel = 'Doctorate Level'; }
        const enrollmentStatus = (statusCode) => {
          switch (statusCode) {
            case 'G': return 'Graduate';
            case 'W': return 'Withdrawn';
            case 'F': return 'FullTime Active';
            case 'L': return 'Less than FullTime Active';
            case 'A': return 'Leave of Absence';
            case 'X': return 'Not yet Started';
            default: return '';
          }
        };
        window.MyPhoenix.Analytics.User.studentStatus = enrollmentAffiliation
          && enrollmentStatus(enrollmentAffiliation.status);
        const lastActivityDate = moment.tz(primaryProgram.lastActivityDate, 'America/Phoenix');
        const currentDate = getCurrentDate();
        const noOfDays = currentDate.diff(lastActivityDate, 'days');
        if (Number.isNaN(noOfDays)) {
          window.MyPhoenix.Analytics.User.outOfAttendance = '';
        } else { window.MyPhoenix.Analytics.User.outOfAttendance = `${noOfDays} days`; }
      }
      return {
        ...transformedById,
        receivedAt: action.receivedAt,
      };
    }
    case REQUEST_PROGRAMS_ERROR:
      return {
        ...state,
        error: action.error,
        receivedAt: action.receivedAt,
        status: STATUS.ERROR,
      };
    case INVALIDATE_PROGRAMS:
      return {
        ...state,
        status: STATUS.INVALIDATED,
      };

    case REQUEST_GRADUATION_DATE: {
      const { selectedProgramId, receivedAt } = action;
      const newSelectedProgram = state.byId[selectedProgramId];
      newSelectedProgram.programGraduationInfo = {
        receivedAt,
        pageLoadIndex: getPageLoadIndex(),
        status: STATUS.FETCHING,
      };
      const updatedById = {
        [selectedProgramId]: newSelectedProgram,
      };
      return {
        ...state,
        byId: { ...state.byId, ...updatedById },
      };
    }

    case RECEIVE_GRADUATION_DATE: {
      const { results, selectedProgramId } = action.data;
      const newById = state.byId;
      const newSelectedProgram = newById[selectedProgramId];
      let newProgramInfo = {};
      if (results && results[0]) {
        const programValue = results[0];
        const {
          programGraduationInfo,
          creditsSummary = 0,
          programCompletionDeadlineDate,
        } = newSelectedProgram;
        newProgramInfo = {
          ...programGraduationInfo,
          [programValue.name]: +programValue?.value,
          programCreditsRemaining: creditsSummary?.remaining,
          programCompletionDeadlineDate,
          programName: newSelectedProgram?.name,
          receivedAt: action?.receivedAt,
          status: STATUS.FETCHED,
        };
      } else {
        newProgramInfo = {
          ...newSelectedProgram.programGraduationInfo,
          receivedAt: action?.receivedAt,
          status: STATUS.FETCHED,

        };
      }
      newSelectedProgram.programGraduationInfo = newProgramInfo;
      const updatedById = {
        [selectedProgramId]: newSelectedProgram,
      };
      return {
        ...state,
        byId: { ...state.byId, ...updatedById },
      };
    }

    case REQUEST_GRADUATION_DATE_ERROR: {
      const { selectedProgramId, error, receivedAt } = action;
      const newSelectedProgram = state.byId[selectedProgramId];
      const newProgramInfo = {
        ...newSelectedProgram.programGraduationInfo,
        error,
        receivedAt,
        status: STATUS.ERROR,
      };
      newSelectedProgram.programGraduationInfo = newProgramInfo;
      const updatedById = {
        [selectedProgramId]: newSelectedProgram,
      };
      return {
        ...state,
        byId: { ...state.byId, ...updatedById },
      };
    }
    default:
      return state;
  }
}
