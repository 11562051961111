import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  main: {
    position: 'relative',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.text,
    '&:hover, &:focus, &:hover .irn-text, &:focus .irn-text': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    '&.avatar-loading': {
      '& p': {
        height: '1.6rem',
        backgroundColor: theme.palette.grays.darkGray,
      },
    },
    '& .profile-info-container': {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: `${theme.spacing(1)}`,
      [theme.breakpoints.up('md')]: {
        marginLeft: `${theme.spacing(1.6)}`,
      },
    },
    '& .profile-info': {
      position: 'relative',
      fontWeight: 900,
      fontSize: '1.6rem',
      flexDirection: 'column',
      justifyContent: 'center',
      display: 'none',
      marginBottom: `${theme.spacing(0.2)}`,
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },

  },
  inrRoot: {
    position: 'absolute',
    bottom: '0.1rem',
    left: '6.6rem',
    '& .irn-text': {
      fontSize: '1.1rem',
      color: theme.palette.text.primary,
    },
    '& .copy-text': {
      color: theme.palette.text.primary,
      fontSize: '1rem',
      transition: 'opacity 0.5s',
      opacity: 1,
      '&.hidden': {
        opacity: 0,
      },
    },
    '& svg': {
      cursor: 'pointer',
      height: '1rem',
      width: '1rem',
      transition: 'fill 0.5s',
    },
  },
}));
