import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  myPhxIcon: (props) => ({
    [`&.myphoenix-icon.${props.icon}-fill`]: {
      fill: props.color,
    },
  }),

}));
