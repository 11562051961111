import { connect } from 'react-redux';
import NotificationBell from '../../components/notification-bell/v1/NotificationBell';
import fetchEventsIfNeeded from '../domains/events/actions';
import { fetchTasksIfNeeded } from '../domains/tasks/actions';
import { setNotificationAlert, setNotificationAlertCount } from '../domains/applicationState/actions';
import { fetchApplicationWorkflowIfNeeded } from '../domains/enrollmentApplication/actions';
import { fetchNotificationsIfNeeded } from '../domains/alerts/actions';

const mapStateToProps = (state, ownProps) => ({
  statusAuth: state.auth.status,
  statusAlert: state.alerts.status,
  statusNotifications: state.alerts.notificationsStatus,
  statusTasks: state.tasks.status,
  logonEvents: state.alerts.logonEvents,
  assignedTasks: state.tasks.assignedTasks,
  enrollmentTasks: state.tasks.enrollmentTasks,
  notificationAlert: state.applicationState.notificationAlert,
  notificationsList: state.alerts.notifications?.filter((notification) => !notification.read),
  statusEnrollmentApplication: state.enrollmentApplication.status,
  loginCount: state.events?.loginCount,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  loadEvents: () => dispatch(fetchEventsIfNeeded()),
  loadTasks: () => dispatch(fetchTasksIfNeeded()),
  loadNotifications: () => dispatch(fetchNotificationsIfNeeded()),
  setNotificationAlert: (alertCount) => dispatch(setNotificationAlert(alertCount)),
  setNotificationAlertCount: (alertCount) => dispatch(setNotificationAlertCount(alertCount)),
  loadApplication: () => dispatch(fetchApplicationWorkflowIfNeeded()),
});

const NotificationBellContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationBell);

export default NotificationBellContainer;
