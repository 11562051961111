import {
  SET_SELECTED_EMAILCONSENT,
  SET_SELECTED_FEEDBACKCATEGORY,
  SET_SELECTED_EMOJIVALUE,
  SET_SELECTED_FEEDBACKREASON,
  CLEAR_FEEDBACK,
} from './types';

export default function feedback(
  state = {
    feedback: {},
  },
  action,
) {
  switch (action.type) {
    case SET_SELECTED_EMAILCONSENT: {
      return {
        ...state,
        emailConsent: action.emailConsentData,
      };
    }
    case SET_SELECTED_FEEDBACKCATEGORY: {
      return {
        ...state,
        feedbackCategory: action.feedbackCategoryData,
      };
    }
    case SET_SELECTED_EMOJIVALUE: {
      return {
        ...state,
        selectedEmojiValue: action.selectedEmojiValueData,
      };
    }
    case SET_SELECTED_FEEDBACKREASON: {
      return {
        ...state,
        feedbackReason: action.feedbackReasonData,
      };
    }
    case CLEAR_FEEDBACK: {
      return {
        ...state,
        emailConsent: '',
        feedbackCategory: '',
        selectedEmojiValue: '',
        feedbackReason: '',
      };
    }

    default:
      return state;
  }
}
