import React, { useState, useEffect } from 'react';
import {
  TextField,
  Typography,
  useMediaQuery,
  Button,
  Box,
  Dialog,
  Grid,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import PropTypes from 'prop-types';
import theme from '../../../theme';
import styles from './FeedbackModal.styles';
import EmojiButtons from '../../form/emoji-buttons/EmojiButtons';

const FeedbackModal = (props) => {
  const {
    isModalOpen,
    closeCallback,
    emailConsent,
    feedbackCategory,
    selectedEmojiValue,
    feedbackReason,
    setSelectedEmailConsent,
    setSelectedFeedbackCategory,
    setSelectedEmojiValue,
    setSelectedFeedbackReason,
    postFeedbackSurvey,
    clearSelectedFeedback,
    feedbackLoopType,
    internalSurveySubmitEndpoint,
    successMessage,
    errorMessage,
    showCategories,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const componentName = 'FeedbackModal';
  const [submitFeedbackError, setsubmitFeedbackError] = React.useState(false);
  const [postFeedbackSubmitModal, setPostFeedbackSubmitModal] = React.useState(false);
  const classes = styles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleEmailConsent = (event) => {
    setSelectedEmailConsent(event.currentTarget.value);
    const { target } = event;
    const parent = target.closest('.email-consent-radiogroup');
    parent
      .querySelectorAll('[aria-selected="true"]')
      .forEach((t) => {
        t.setAttribute('aria-selected', false);
        t.setAttribute('tabindex', '-1');
      });
    target.closest('label').setAttribute('aria-selected', true);
    target.closest('label').setAttribute('tabindex', '0');
  };

  const handleFeedbackCategory = (event) => {
    setSelectedFeedbackCategory(event.currentTarget.value);
    const { target } = event;
    const parent = target.closest(`.${classes.feedbackCategoryRadioGroup}`);
    parent
      .querySelectorAll('[aria-selected="true"]')
      .forEach((t) => {
        t.setAttribute('aria-selected', false);
        t.setAttribute('tabindex', '-1');
      });

    target.closest('label').setAttribute('aria-selected', true);
    target.closest('label').setAttribute('tabindex', '0');
  };

  const handleTextChange = (event) => {
    setSelectedFeedbackReason(event.currentTarget.value);
  };
  useEffect(() => {
    // giving delay to avoid flickering issue
    setTimeout(() => {
      setPostFeedbackSubmitModal(false);
      setsubmitFeedbackError(false);
    }, 100);
  }, [isModalOpen]);

  const [disabled, setDisabled] = useState(false);

  const submitFeedback = () => {
    setDisabled(true);
    setSelectedEmailConsent(emailConsent || 'no');
    setSelectedFeedbackCategory(feedbackCategory || 'other');
    postFeedbackSurvey(internalSurveySubmitEndpoint)
      .then(() => {
        setsubmitFeedbackError(false);
        clearSelectedFeedback();
        setPostFeedbackSubmitModal(true); // true - show thank you / hide main survey
      })
      .catch(() => {
        setsubmitFeedbackError(true);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  const setSelectedEmoji = (event) => {
    setSelectedEmojiValue(event.currentTarget.value);
  };

  const feedbackSuccessGrid = () => (postFeedbackSubmitModal
    && (
    <Box className={classes.feedbackRoot} data-testid="success-modal">
      <Typography variant="h2" className={classes.thanksTitle} value={successMessage}>
        {successMessage}
      </Typography>
      <Button
        size="large"
        variant="contained"
        color="primary"
        aria-label="Close"
        className={classes.sendFeebackButton}
        onClick={closeCallback}
      >
        Close
      </Button>
    </Box>
    )

  );

  const feedbackSubmitButtonGrid = () => (feedbackLoopType !== 'external'
    && !postFeedbackSubmitModal
    && (

    <Grid
      container
      justify="center"
      alignItems="center"
      className={submitFeedbackError
        ? classes.resendButtonContainer : classes.submitButtonContainer}
    >
      {submitFeedbackError && (
      <Typography justify="left" className={classes.ohdearText}>
        {errorMessage}
      </Typography>
      )}
      <Button
        justify="float-end"
        variant="contained"
        onClick={submitFeedback}
        color="primary"
        aria-label={submitFeedbackError ? 'Resend' : 'Send Feedback'}
        disabled={disabled || !selectedEmojiValue}
        data-testid="submit-button"
        className={submitFeedbackError ? classes.resendFeebackButton : classes.sendFeebackButton}
      >
        {submitFeedbackError ? 'Resend' : 'Send Feedback'}
      </Button>
    </Grid>
    )
  );

  const feedbackModalMain = () => (!postFeedbackSubmitModal
    && (
    <Grid>
      <Box className={classes.closeButton}>
        <Button aria-label="Close" onClick={closeCallback} disableRipple autoFocus>
          Close
        </Button>
      </Box>

      {feedbackLoopType === 'internal'
      && (
        <Box className={classes.detailsSection}>
          <Typography variant="h2" className={classes.feedbackTitle} data-cy="alert-dialog-title">
            Your opinion counts – tell us what you think.
          </Typography>

          <Typography variant="body1" className={classes.rateExperience}>
            Rate your experience with MyPhoenix™.
          </Typography>

          <EmojiButtons setSelectedValue={setSelectedEmoji} selected={selectedEmojiValue} />
          {showCategories
        && (
          <Box className={classes.feedbackCategoryBox}>
            <FormControl component="fieldset" className={classes.feedbackCategoryForm}>
              <FormLabel
                component="legend"
                className={classes.contactTitle}
                aria-label="What is your feedback about"
              >
                What is your feedback about?
              </FormLabel>
              <RadioGroup
                aria-label="feedback category"
                name="feedback category"
                onChange={handleFeedbackCategory}
                defaultValue={feedbackCategory}
                className={classes.feedbackCategoryRadioGroup}
              >
                <div className={classes.feedbackCategoryColumn}>
                  <FormControlLabel
                    value="Website Feedback"
                    control={<Radio color="default" />}
                    label="Website Feedback"
                  />
                  <FormControlLabel
                    value="Faculty/Classroom"
                    control={<Radio color="default" />}
                    label="Faculty/Classroom"
                  />
                  <FormControlLabel
                    value="Chat"
                    control={<Radio color="default" />}
                    label="Chat"
                  />
                  <FormControlLabel
                    value="Career/Skills"
                    control={<Radio color="default" />}
                    label="Career/Skills"
                  />
                </div>
                <div className={classes.feedbackCategoryColumn}>
                  <FormControlLabel
                    value="Technical Issues"
                    control={<Radio color="default" />}
                    label="Technical Issues"
                  />
                  <FormControlLabel
                    value="Staff Interaction"
                    control={<Radio color="default" />}
                    label="Staff Interaction"
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio color="default" />}
                    label="Other"
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Box>
        )}

          <Box className={classes.tellMoreBox}>
            <Typography className={classes.tellMoreTitle}>Please tell us more.</Typography>
            <TextField
              className={classes.textBox}
              fullWidth
              rows={4}
              multiline
              onChange={handleTextChange}
              label="Why did you choose that rating?"
              value={feedbackReason}
              inputProps={{
                'aria-label': 'Why did you choose that rating?',
                id: 'feedback-reason-text',
              }}
            />
          </Box>

          <Box className={classes.contactBox}>
            <FormControl
              component="fieldset"
              className={classes.contactChoice}
            >
              <FormLabel
                component="legend"
                className={classes.contactTitle}
              >
                May we contact you if we have additional questions regarding your feedback?
              </FormLabel>
              <RadioGroup
                aria-label="feedback selection"
                name="feedback"
                onChange={handleEmailConsent}
                defaultValue={emailConsent}
                className="email-consent-radiogroup"
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="default" />}
                  label="Yes! Please use my email address on file."
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="default" />}
                  label="No, thanks!"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      )}

    </Grid>
    )
  );

  return (
    <>
      <Dialog
        id="feedback-modal"
        open={isModalOpen}
        onClose={closeCallback}
        aria-modal="true"
        role="dialog"
        aria-label="Give us your feedback"
        maxWidth="md"
        aria-labelledby="modal-title"
        className={`${classes.root} ${feedbackLoopType === 'external' ? classes.dialogControl : ''}`}
        fullScreen={fullScreen}
        data-testid="feedback-modal"
        fullWidth
      >
        {feedbackModalMain()}
        {feedbackSubmitButtonGrid()}
        {feedbackSuccessGrid()}

      </Dialog>

    </>
  );
};

FeedbackModal.defaultProps = {
  isModalOpen: false,
  closeCallback: () => {},
  emailConsent: '',
  feedbackCategory: '',
  feedbackReason: '',
  selectedEmojiValue: '',
  setSelectedEmailConsent: () => Promise.resolve(),
  setSelectedFeedbackCategory: () => Promise.resolve(),
  setSelectedEmojiValue: () => Promise.resolve(),
  setSelectedFeedbackReason: () => Promise.resolve(),
  postFeedbackSurvey: () => Promise.resolve(),
  clearSelectedFeedback: () => Promise.resolve(),
  feedbackLoopType: 'internal',
  internalSurveySubmitEndpoint: '',
  successMessage: '',
  errorMessage: '',
  showCategories: true,
};
FeedbackModal.propTypes = {
  selectedEmojiValue: PropTypes.string,
  emailConsent: PropTypes.string,
  feedbackCategory: PropTypes.string,
  feedbackReason: PropTypes.string,
  isModalOpen: PropTypes.bool,
  closeCallback: PropTypes.func,
  setSelectedEmailConsent: PropTypes.func,
  setSelectedFeedbackCategory: PropTypes.func,
  setSelectedEmojiValue: PropTypes.func,
  setSelectedFeedbackReason: PropTypes.func,
  postFeedbackSurvey: PropTypes.func,
  clearSelectedFeedback: PropTypes.func,
  feedbackLoopType: PropTypes.string,
  internalSurveySubmitEndpoint: PropTypes.string,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  showCategories: PropTypes.bool,
};

export default FeedbackModal;
