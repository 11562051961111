import { apiClient, basicThirdPartyClient } from './wrappers/clients';
import PersonsService from './domains/persons/v1/persons';
import SearchService from './domains/search/v1/search';
import ProgramsService from './domains/programs/v1/programs';
import LearningService from './domains/learning/v1/learning';
import ContactsService from './domains/contacts/v1/contacts';
import SagasService from './domains/sagas/v1/sagas';
import SkillsService from './domains/skills/v1/skills';
import StudentService from './domains/student/v1/student';
import StudentServiceV2 from './domains/student/v2/student';
import CommunicationService from './domains/communication/v1/communication';
import CoursesService from './domains/courses/v1/courses';
import DocumentService from './domains/document/v1/document';
import FeedbackSurveyService from './domains/surveys/v1/surveys';
import SkillsBasedProgramsService from './domains/skills/v1/skillsBasedPrograms';
import ApplicationService from './domains/applications/v1/applications';
import BuilderService from './domains/builder/v1/builder';

export default (getState = {}, dispatch = {}) => {
  const client = apiClient(getState, dispatch);
  const simpleClient = basicThirdPartyClient();
  return {
    ApplicationService: ApplicationService(client),
    PersonsService: PersonsService(client),
    SearchService: SearchService(client),
    LearningService: LearningService(client),
    ContactsService: ContactsService(client),
    SagasService: SagasService(client),
    SkillsService: SkillsService(client),
    StudentService: StudentService(client),
    StudentServiceV2: StudentServiceV2(client),
    ProgramsService: ProgramsService(client),
    CommunicationService: CommunicationService(client),
    CoursesService: CoursesService(client),
    DocumentService: DocumentService(client),
    FeedbackSurveyService: FeedbackSurveyService(),
    SkillsBasedProgramsService: SkillsBasedProgramsService(client),
    BuilderService: BuilderService(simpleClient),
  };
};
