import STATUS from '../../globalStatuses';
import {
  REQUEST_TASKS,
  RECEIVE_TASKS,
  RECEIVE_UPDATED_TASK,
  REQUEST_TASKS_ERROR,
  REQUEST_UPDATE_TASK_ERROR,
  INVALIDATE_TASKS,
  REQUEST_UPDATE_TASK,
} from './types';

const getTypeFromContext = (contexts) => {
  if (contexts.find((context) => context.indexOf('application_center/post_submission') > -1)) {
    return 'ENROLLMENT';
  }
  return 'REGULAR';
};

const sortTasks = (tasksList, applicationId = '') => {
  const completedTasks = [];
  const assignedTasks = [];
  const enrollmentTasks = [];

  tasksList
    .forEach((value) => {
      const task = {};
      task.taskName = value.title;
      task.taskDescription = value.description || '';
      task.id = value.id;
      task.templateId = value.templateId;
      task.userActionable = !!value.userActionable;
      task.status = value.states.status.action;
      task.link = value.link;
      task.linkTarget = value.linkTarget;
      task.linkCaption = value.linkCaption;
      task.dueDate = value.states.dueDate;
      task.type = getTypeFromContext(value.contexts);
      task.updateStatus = STATUS.UNFETCHED;
      task.priority = value.priority;
      task.expiryDate = value.states.expiryDate;
      if (task.status.toLowerCase() === 'completed') {
        completedTasks.push(task);
      } else if (task.type === 'ENROLLMENT') {
        task.relatedId = value.relatedId;
        enrollmentTasks.push(task);
      } else {
        assignedTasks.push(task);
      }
    });
  return {
    completedTasks,
    assignedTasks,
    enrollmentTasks: enrollmentTasks.filter((task) => task.relatedId === applicationId),
  };
};

export default function tasks(state = { tasks: {} }, action) {
  switch (action.type) {
    case REQUEST_TASKS:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case REQUEST_UPDATE_TASK:
      return {
        ...state,
        assignedTasks: state.assignedTasks.map((task) => {
          if (task.id === action.taskId) {
            return {
              ...task,
              updateStatus: STATUS.FETCHING,
            };
          }
          return task;
        }),
        enrollmentTasks: state.enrollmentTasks.map((task) => {
          if (task.id === action.taskId) {
            return {
              ...task,
              updateStatus: STATUS.FETCHING,
            };
          }
          return task;
        }),
      };
    case RECEIVE_TASKS:
      return {
        ...state,
        ...sortTasks(action.tasks, action.applicationId),
        status: STATUS.FETCHED,
      };
    case RECEIVE_UPDATED_TASK:
      return {
        ...state,
        assignedTasks: state.assignedTasks.map((task) => {
          if (task.id === action.task.id) {
            return {
              ...task,
              status: action.task.states.status.action,
              updateStatus: STATUS.FETCHED,
            };
          }
          return task;
        }),
        enrollmentTasks: state.enrollmentTasks.map((task) => {
          if (task.id === action.task.id) {
            return {
              ...task,
              status: action.task.states.status.action,
              updateStatus: STATUS.FETCHED,
            };
          }
          return task;
        }),
      };
    case REQUEST_TASKS_ERROR:
      return {
        ...state,
        status: STATUS.ERROR,
        error: action.error,
      };
    case REQUEST_UPDATE_TASK_ERROR:
      return {
        ...state,
        assignedTasks: state.assignedTasks.map((task) => {
          if (task.id === action.taskId) {
            return {
              ...task,
              updateStatus: STATUS.ERROR,
            };
          }
          return task;
        }),
        enrollmentTasks: state.enrollmentTasks.map((task) => {
          if (task.id === action.taskId) {
            return {
              ...task,
              updateStatus: STATUS.ERROR,
            };
          }
          return task;
        }),
      };
    case INVALIDATE_TASKS:
      return {
        ...state,
        status: STATUS.INVALIDATED,
      };
    default:
      return state;
  }
}
