import STATUS from '../../globalStatuses';
import {
  REQUEST_COURSE_CURRICULUM_ERROR, RECEIVE_COURSE_CURRICULUM, REQUEST_COURSE_CURRICULUM,
} from './types';

export default function courseCurriculum(state = { courseCurriculum: {} }, action) {
  const byCourseId = { ...state.byCourseId };
  switch (action.type) {
    case REQUEST_COURSE_CURRICULUM: {
      const curriculum = {
        status: STATUS.FETCHING,
      };
      byCourseId[action.courseId] = curriculum;
      return {
        ...state,
        byCourseId,
      };
    }
    case RECEIVE_COURSE_CURRICULUM: {
      let curriculum = {
        status: STATUS.FETCHED,
      };
      if (action.courseCurriculum) {
        const { description, topics } = action.courseCurriculum;
        const topicsAndObjectives = topics.map((object) => (
          {
            name: object.name,
            objectives: object.objectives,
          }
        ));
        curriculum = {
          description,
          topicsAndObjectives,
          status: STATUS.FETCHED,
        };
      }
      byCourseId[action.courseId] = curriculum;
      return {
        ...state,
        byCourseId,
      };
    }
    case REQUEST_COURSE_CURRICULUM_ERROR: {
      byCourseId[action.courseId].status = STATUS.ERROR;
      return {
        ...state,
        byCourseId,
      };
    }
    default:
      return state;
  }
}
