import STATUS from '../redux/globalStatuses';
import { getPageLoadIndex } from './page-functions';

const { MyPhoenix = { Config: { MicroServices: {} } } } = window;
const MS_UNTIL_STALE = MyPhoenix.Config.MicroServices.Stale || 15000;

const fetchAbleStatuses = [STATUS.UNFETCHED, STATUS.INVALIDATED];

export const shouldInvalidateAbortedFetch = (object) => {
  const currentPageLoadIndex = getPageLoadIndex();
  const { pageLoadIndex } = object;
  return pageLoadIndex && pageLoadIndex !== currentPageLoadIndex;
};

export const shouldInvalidateStaleFetch = (receivedAt) => {
  const now = Date.now();
  return receivedAt && now > (receivedAt + MS_UNTIL_STALE);
};

export const shouldFetch = (object, isPersistedData = false) => {
  // is there an object? if not lets fetch it
  if (object) {
    const { status, receivedAt } = object;
    // does it have a status? if not lets fetch it
    if (status) {
      // is the data being persisted?
      if (isPersistedData) {
        // lets check to see if its stale, an aborted fetch, or if its in a fetch-able status
        return (status === STATUS.FETCHED && shouldInvalidateStaleFetch(receivedAt))
            || (status === STATUS.FETCHING && shouldInvalidateAbortedFetch(object))
            || (status === STATUS.ERROR && shouldInvalidateAbortedFetch(object))
            || (fetchAbleStatuses.includes(status));
      }

      // if its not persisted, anything besides FETCHING lets fetch
      // and if it is FETCHING lets check to see if it was an aborted fetch
      return status !== STATUS.FETCHING
          || (status === STATUS.FETCHING && shouldInvalidateAbortedFetch(object));
    }
    return true;
  }
  return true;
};
