export const REQUEST_ALERTS = 'REQUEST_ALERTS';
export const RECEIVE_ALERTS = 'RECEIVE_ALERTS';
export const REQUEST_ALERTS_ERROR = 'REQUEST_ALERTS_ERROR';
export const INVALIDATE_ALERTS = 'INVALIDATE_ALERTS';
export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
export const REQUEST_NOTIFICATIONS_ERROR = 'REQUEST_NOTIFICATIONS_ERROR';
export const REQUEST_MARK_EVENT_AS_READ = 'REQUEST_MARK_EVENT_AS_READ';
export const RECEIVE_MARK_EVENT_AS_READ = 'RECEIVE_MARK_EVENT_AS_READ';
export const REQUEST_MARK_EVENT_AS_READ_ERROR = 'REQUEST_MARK_EVENT_AS_READ_ERROR';
export const REQUEST_MARK_NOTIFICATION_AS_READ_OR_UNREAD = 'REQUEST_MARK_NOTIFICATION_AS_READ_OR_UNREAD';
export const RECEIVE_MARK_NOTIFICATION_AS_READ_OR_UNREAD = 'RECEIVE_MARK_NOTIFICATION_AS_READ_OR_UNREAD';
export const REQUEST_MARK_NOTIFICATION_AS_READ_OR_UNREAD_ERROR = 'REQUEST_MARK_NOTIFICATION_AS_READ_OR_UNREAD_ERROR';
