import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import STATUS from '../../../redux/globalStatuses';
import SectionLink from '../../section-link/v1/SectionLink';
import useStyles from './HeaderSectionList.styles';

function HeaderSectionList({
  heading,
  inert,
  isCBEDA,
  loadCourses,
  loadPrograms,
  sections,
  statusAuth,
  parentComponentName,
}) {
  const componentName = 'HeaderSectionList';
  const classes = useStyles();

  useEffect(() => {
    if (statusAuth === STATUS.FETCHED) {
      loadCourses();
      loadPrograms();
    }
  }, [loadCourses, statusAuth]);

  if (!sections.length) {
    return null;
  }

  return (
    <div
      inert={inert}
      role="navigation"
      aria-labelledby="currentClassesNavigation"
      className={classes.root}
      data-testid="myphx-header-section-list"
    >
      <Typography id="currentClassesNavigation" className={classes.heading} variant="h2">{heading}</Typography>
      {sections.map((section, index) => (
        <div
          className={isCBEDA ? classes.cbeDASectionLinkContainer : classes.sectionLinkContainer}
          key={section.sectionId}
        >
          <SectionLink
            parentComponentName={`${parentComponentName}_${componentName}`}
            condensed={sections.length > 3}
            section={section}
            key={section.sectionId}
            isCBEDA={isCBEDA}
            isLastLink={index === (sections.length - 1)}
          />
        </div>
      ))}
    </div>
  );
}

HeaderSectionList.propTypes = {
  heading: PropTypes.string,
  inert: PropTypes.string,
  isCBEDA: PropTypes.bool,
  loadCourses: PropTypes.func,
  loadPrograms: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  statusAuth: PropTypes.string,
  parentComponentName: PropTypes.string,
};

HeaderSectionList.defaultProps = {
  heading: 'Current Classes',
  inert: undefined,
  isCBEDA: false,
  loadCourses: () => {},
  loadPrograms: () => {},
  statusAuth: STATUS.UNFETCHED,
  parentComponentName: '',
};

export default HeaderSectionList;
