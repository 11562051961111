import moment from 'moment';
import {
  REQUEST_COURSE_MEMBERSHIPS, RECEIVE_COURSE_MEMBERSHIPS, REQUEST_COURSE_MEMBERSHIPS_ERROR,
  REQUEST_COURSE_SECTION, RECEIVE_COURSE_SECTION, REQUEST_COURSE_SECTION_ERROR,
} from './types';

import { errorToMessage } from '../../../utils/error-functions';
import { convertClassRostNumToMembershipId, getIdForRange, RANGE } from '../../../utils/course-functions';
import { shouldFetch } from '../../../utils/status-functions';
import fetchAcademicTermsIfNeeded from '../academicTerms/action';

/* action creators */
function requestCourseMemberships(rangeId) {
  return { type: REQUEST_COURSE_MEMBERSHIPS, rangeId, receivedAt: Date.now() };
}

function receiveCourseMemberships(memberships, rangeId) {
  return {
    type: RECEIVE_COURSE_MEMBERSHIPS, memberships, rangeId, receivedAt: Date.now(),
  };
}

function requestCourseMembershipsError(rangeId, error) {
  return { type: REQUEST_COURSE_MEMBERSHIPS_ERROR, rangeId, error };
}

function requestCourseSection(membershipId) {
  return { type: REQUEST_COURSE_SECTION, membershipId, receivedAt: Date.now() };
}

function receiveCourseSection(section, membershipId) {
  return {
    type: RECEIVE_COURSE_SECTION, section, membershipId, receivedAt: Date.now(),
  };
}

function requestCourseSectionError(error, membershipId) {
  return {
    type: REQUEST_COURSE_SECTION_ERROR,
    error: errorToMessage(error),
    membershipId,
    receivedAt: Date.now(),
  };
}

function shouldFetchCourseSection(state, membershipId) {
  const { courses } = state;
  const membership = courses.byMembershipId[membershipId];
  return shouldFetch(membership, true);
}

function fetchCourseSections(courseMemberships, rangeId) {
  return async (dispatch, getState, { Services }) => {
    let atLeastOneCourseSectionFailed = false;
    const state = getState();
    const sections = courseMemberships.filter(
      (membership) => (shouldFetchCourseSection(state, membership.id)),
    );

    await Promise.all(sections.map(
      (membership) => {
        const termCode = membership.daAcademicYearTerm && membership.daAcademicYearTerm.termCode
          ? membership.daAcademicYearTerm.termCode : null;
        if (termCode) {
          dispatch(fetchAcademicTermsIfNeeded(termCode));
        }
        dispatch(requestCourseSection(membership.id));
        return Services.LearningService.getSectionBySourceId(membership.sourceId)
          .then((sectionResponse) => {
            dispatch(receiveCourseSection(sectionResponse.data, membership.id));
          })
          .catch((error) => {
            atLeastOneCourseSectionFailed = true;
            dispatch(requestCourseSectionError(error, membership.id));
          });
      },
    )).then(() => {
      if (atLeastOneCourseSectionFailed) {
        dispatch(requestCourseMembershipsError(rangeId, 'At least one section call failed'));
      } else {
        dispatch(receiveCourseMemberships(courseMemberships, rangeId));
      }
    });
  };
}

function fetchCourses(range) {
  const rangeId = getIdForRange(range);
  return async (dispatch, getState, { Services }) => {
    dispatch(requestCourseMemberships(rangeId));
    const { auth } = getState();
    const { Features } = window.MyPhoenix.Config;
    const { StudentMembershipsV2Enabled } = Features;
    const LearningServiceVersion = StudentMembershipsV2Enabled ? 'StudentServiceV2' : 'LearningService';
    return Services[LearningServiceVersion]
      .getMembershipsByPersonIdAndDateRange(
        auth.personId,
        moment().add(range.min, 'days').format('YYYY-MM-DD'),
        moment().add(range.max, 'days').format('YYYY-MM-DD'),
      )
      .then(async (membershipsResponse) => {
        const memberships = membershipsResponse.data && membershipsResponse.data.memberships
          ? membershipsResponse.data.memberships
            // use participanId for id if id is null
            .map((membership) => {
              if (!membership.id && membership.participantId) {
                // eslint-disable-next-line no-param-reassign
                membership.id = convertClassRostNumToMembershipId(membership.participantId);
              }
              return membership;
            })
            // filter out workshop memberships (the ones that contain SDW in their ID)
            .filter((membership) => (membership.id.indexOf('SDW') === -1))
            // filter out exams or any sections w/o start and end dates
            .filter((membership) => (membership.startDate && membership.endDate && membership.courseOfferingType !== 'TT'))
          : [];
        dispatch(fetchCourseSections(memberships, rangeId));
      })
      .catch((error) => {
        dispatch(requestCourseMembershipsError(rangeId, errorToMessage(error)));
      });
  };
}

function shouldFetchCourses(state, rangeId) {
  const { courses } = state;
  const rangeInfo = courses.byRangeId[rangeId];
  return shouldFetch(rangeInfo, true);
}

/**
 * Fetch courses by a given date range
 *
 * @param range: { min: (days from today), max: (days from today) }
 * @returns {function(*, *): (*)}
 */
export default function fetchCoursesIfNeeded(range = RANGE.DEFAULT) {
  return (dispatch, getState) => {
    if (shouldFetchCourses(getState(), getIdForRange(range))) {
      return dispatch(fetchCourses(range));
    }
    return Promise.resolve();
  };
}
