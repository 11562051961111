import uuid from 'uuid/v4';

const xCorrelationIdName = 'X-Correlation-Id';
const xRequestIdName = 'X-Request-Id';
const xCorrelationId = sessionStorage.getItem(xCorrelationIdName) || uuid();
sessionStorage.setItem(xCorrelationIdName, xCorrelationId);

export const getRequestId = (config) => (
  config && config.headers
    ? config.headers[xRequestIdName]
    : '');

export const getCorrelationId = (config) => (
  config && config.headers
    ? config.headers[xCorrelationIdName]
    : sessionStorage.getItem(xCorrelationIdName));

export const trackingIdInterceptor = (config) => {
  const configUpdate = config;
  configUpdate.headers[xCorrelationIdName] = xCorrelationId;
  configUpdate.headers[xRequestIdName] = uuid();
  return configUpdate;
};
