import STATUS from '../../globalStatuses';
import {
  REQUEST_PHONES, RECEIVE_PHONES, REQUEST_PHONES_ERROR,
  REQUEST_POST_PHONE, RECEIVE_POST_PHONE, REQUEST_POST_PHONE_ERROR,
  REQUEST_UPDATE_PHONE, RECEIVE_UPDATE_PHONE, REQUEST_UPDATE_PHONE_ERROR,
  REQUEST_DELETE_PHONE, RECEIVE_DELETE_PHONE, REQUEST_DELETE_PHONE_ERROR,
} from './types';
import { transformById, deleteById } from '../../../utils/action-reducer-functions';

function phones(state = { phones: {} }, action) {
  switch (action.type) {
    case REQUEST_PHONES:
      return {
        ...state,
        receivedAt: action.receivedAt,
        status: STATUS.FETCHING,
      };
    case RECEIVE_PHONES: {
      const transformedById = transformById(state, action.phones);
      const primaryIds = transformedById.allIds.filter(
        (id) => transformedById.byId[id].primary === 'true',
      );
      return {
        ...transformedById,
        primaryOrFirstId: (primaryIds.length && primaryIds[0])
          || (transformedById.allIds.length && transformedById.allIds[0]),
        receivedAt: action.receivedAt,
        status: STATUS.FETCHED,
      };
    }
    case REQUEST_PHONES_ERROR:
      return {
        ...state,
        error: action.error,
        receivedAt: action.receivedAt,
        status: STATUS.ERROR,
      };
    case REQUEST_POST_PHONE:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case RECEIVE_POST_PHONE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.phone.id]: action.phone,
        },
        allIds: state.allIds.includes(action.phone.id)
          ? state.allIds : [...state.allIds, action.phone.id],
        status: STATUS.FETCHED,
      };
    case REQUEST_POST_PHONE_ERROR:
      return {
        ...state,
        status: STATUS.ERROR,
      };
    case REQUEST_UPDATE_PHONE:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case RECEIVE_UPDATE_PHONE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.phone.id]: action.phone,
        },
        allIds: state.allIds.includes(action.phone.id)
          ? state.allIds : [...state.allIds, action.phone.id],
        status: STATUS.FETCHED,
      };
    case REQUEST_UPDATE_PHONE_ERROR:
      return {
        ...state,
        status: STATUS.ERROR,
      };
    case REQUEST_DELETE_PHONE:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case RECEIVE_DELETE_PHONE:
      return deleteById(state, action.phone.id);
    case REQUEST_DELETE_PHONE_ERROR:
      return {
        ...state,
        status: STATUS.ERROR,
      };
    default:
      return state;
  }
}

export default phones;
