import {
  SET_SELECTED_PROGRAM,
  SET_SELECTED_MEMBERSHIP,
  SET_LOA_SELECTED,
  SET_SOCIAL_MODAL_OPEN_STATE,
  SET_NOTFICATION_ALERT_STATE,
  SET_NOTFICATION_ALERT_COUNT_STATE,
} from './types';

export default function applicationState(
  state = {
    selectedMembershipId: '',
    selectedProgramId: '',
    loaSelected: false,
    socialModalOpen: null,
    notificationAlert: null,
    notificationCount: null,
  },
  action,
) {
  switch (action.type) {
    case SET_SELECTED_MEMBERSHIP: {
      return {
        ...state,
        selectedMembershipId: action.membershipId,
      };
    }
    case SET_SELECTED_PROGRAM: {
      return {
        ...state,
        selectedProgramId: action.programId,
      };
    }
    case SET_LOA_SELECTED: {
      return {
        ...state,
        loaSelected: action.isLoaSelected,
      };
    }
    case SET_SOCIAL_MODAL_OPEN_STATE: {
      return {
        ...state,
        socialModalOpen: action.socialModalOpen,
      };
    }

    case SET_NOTFICATION_ALERT_STATE: {
      return {
        ...state,
        notificationAlert: action.alertState,
      };
    }

    case SET_NOTFICATION_ALERT_COUNT_STATE: {
      return {
        ...state,
        notificationCount: action.alertCount,
      };
    }
    default:
      return state;
  }
}
