import STATUS from '../../globalStatuses';
import {
  REQUEST_SITE_STRIPE,
  RECEIVE_SITE_STRIPE,
  REQUEST_SITE_STRIPE_ERROR,
} from './types';

export default function siteStripe(state = {}, action) {
  switch (action.type) {
    case REQUEST_SITE_STRIPE: {
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    }
    case RECEIVE_SITE_STRIPE: {
      return {
        ...state,
        ...action.data,
        status: STATUS.FETCHED,
      };
    }
    case REQUEST_SITE_STRIPE_ERROR: {
      return {
        ...state,
        status: STATUS.ERROR,
      };
    }
    default:
      return state;
  }
}
