import { errorToCode, trackTimeoutError } from '../../myphoenix/utils/error-functions';
import { getRequestId, getCorrelationId } from './trackingIdInterceptors';

const errorTrackingInterceptor = (error) => {
  const timeoutCode = 'ECONNABORTED';
  const { code, config } = error;
  if (code && code === timeoutCode) {
    trackTimeoutError(errorToCode(error), getRequestId(config), getCorrelationId(config));
  }
  return Promise.reject(error);
};

export default errorTrackingInterceptor;
