import STATUS from '../redux/globalStatuses';

const { MyPhoenix = { Config: { MicroServices: {} } } } = window;
const MS_UNTIL_RETRY = MyPhoenix.Config.MicroServices.Retry || 15000;
const MS_UNTIL_STALE = MyPhoenix.Config.MicroServices.Stale || 15000;
const MS_UNTIL_TIMEOUT = MyPhoenix.Config.MicroServices.Timeout || 15000;

export const transformById = (state, array) => {
  let byId = {};
  const allIds = [];
  array.forEach((value) => {
    const key = value.id;
    byId = { ...byId, [key]: value };
    allIds.push(key);
  });

  return {
    ...state,
    byId,
    allIds,
    status: STATUS.FETCHED,
  };
};

export const deleteById = (state, id) => {
  const { byId, allIds } = state;
  if (byId[id]) {
    delete byId[id];
  }
  const updateAllIds = allIds.includes(id) ? allIds.filter((updated) => updated !== id) : allIds;

  return {
    ...state,
    byId,
    allIds: updateAllIds,
    status: STATUS.FETCHED,
  };
};

export const transformByNamedId = (array, idName = 'Id', idProperty = 'id') => {
  let byId = {};
  const allIds = [];
  const byIdPropertyName = `by${idName}`;
  const allIdsPropertyName = `all${idName}s`;
  array.forEach((value) => {
    const key = value[idProperty];
    byId = { ...byId, [key]: value };
    allIds.push(key);
  });

  return {
    [byIdPropertyName]: byId,
    [allIdsPropertyName]: allIds,
  };
};

/**
 * @deprecated
 *  please use shouldFetch from
 *  src/myphoenix/utils/status-functions.js
 */
/**
 * Returns true if the data is invalidated or unfetched.
 * For persisted data, if the data was received more than 10 minutes prior
 * or the data was in error and was received 1 minute prior.
 *
 * @param status
 * @param receivedAt
 * @returns {boolean}
 */
export const shouldFetchPersistedData = (status, receivedAt) => {
  const now = Date.now();
  return status === STATUS.UNFETCHED || status === STATUS.INVALIDATED
    || now > (receivedAt + MS_UNTIL_STALE)
    || (status === STATUS.FETCHING && now > (receivedAt + MS_UNTIL_TIMEOUT))
    || (status === STATUS.ERROR && now > (receivedAt + MS_UNTIL_RETRY));
};
