import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import withWidth from '@material-ui/core/withWidth';
import { Grid } from '@material-ui/core';
import LinkList from '../../link-list/v1/LinkList';
import IconLink from '../../icon-link/v1/IconLink';
import Icon from '../../icon/v1/Icon';
import SiteStripe from '../../site-stripe/v1/SiteStripe';
import SearchInput from '../../search-input/v1/SearchInput';
import HeaderAvatarContainer from '../../../redux/containers/HeaderAvatarContainer';
import NotificationBellContainer from '../../../redux/containers/NotificationBellContainer';
import HeaderSectionListContainer from '../../../redux/containers/HeaderSectionListContainer';
import { removeBulkSessionStorageData } from '../../../utils/storageUtils';
import STATUS from '../../../redux/globalStatuses';
import { logoutRedirect } from '../../../utils/redirect-functions';
import theme from '../../../theme';
import styles from './Header.styles';
import { createEventObject, useRaiseEventOnClick } from '../../../utils/event-functions';

/**
 * Header React Component
 */
function Header({
  statusAuth,
  statusAlerts,
  loadAlerts,
  siteStripeAlerts,
  statusUserDefinedAttributes,
  loadUserDefinedAttributes,
  loadProfile,
  loadSiteStripe,
  linkLists,
  logout,
  iconLinks,
  profileLink,
  myPhoenixLink,
  messageCenterLink,
  emailInboxLink,
  enableSearch,
  siteStripeOn,
  siteStripeAriaLabel,
  siteStripeMessage,
  siteStripe,
  searchLandingPagePath,
  siteStripeCtaText,
  siteStripeCtaUrl,
  siteStripeCtaTarget,
  userDefinedAttributes,
  firstName,
  lastName,
  issuerId,
  emailInboxLinkTarget,
  width,
}) {
  const componentName = 'Header';
  const classes = styles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [headerWrapperHeight, setHeaderWrapperHeight] = useState(7.2);
  const [copiedInterval, setCopiedInterval] = useState(null);
  const wrapperRef = React.useRef();
  const buttonRef = React.useRef();
  const siteStripeRef = React.useRef();
  const emailRef = useRef(null);
  const issuerIdRef = useRef(null);
  const copyIssuerIdRef = useRef(null);
  const logoRef = useRef(null);
  useRaiseEventOnClick([emailRef, issuerIdRef, copyIssuerIdRef, logoRef, buttonRef]);

  function toggleInertOnMainAndFooter() {
    const main = document.querySelector('main');
    const footer = document.querySelector('footer');
    if (!main || !footer) { return; }
    if (menuOpen) {
      main.setAttribute('inert', 'true');
      footer.setAttribute('inert', 'true');
    } else {
      main.removeAttribute('inert');
      footer.removeAttribute('inert');
    }
  }

  useEffect(() => {
    toggleInertOnMainAndFooter();
  }, [menuOpen]);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  function calculateHeaderWrapperHeight() {
    let headerHeight = 7.2;
    if (width !== 'xs' && width !== 'sm') {
      headerHeight += 1;
    }
    if (((siteStripeOn || siteStripe.displayFlag) && siteStripeRef.current)
      || (siteStripeRef.current && (siteStripeAlerts && siteStripeAlerts.length > 0))) {
      // divide by 10 to convert px to rem (as my mama always said)
      headerHeight += siteStripeRef.current.offsetHeight / 10;
    }
    setHeaderWrapperHeight(headerHeight);
  }

  useEffect(() => {
    function handleScroll() {
      setScroll(window.scrollY > 0);
    }

    function onEscapeKey(event) {
      if (event.keyCode === 27 && menuOpen) {
        toggleMenu();
      }
    }

    function handleClickOutside(event) {
      if (wrapperRef && !wrapperRef.current.contains(event.target)
        && !buttonRef.current.contains(event.target) && menuOpen) {
        toggleMenu();
      }
    }

    window.addEventListener('scroll', throttle(handleScroll, 500));
    document.addEventListener('keydown', onEscapeKey, false);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', throttle(handleScroll, 500));
      document.removeEventListener('keydown', onEscapeKey, false);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen, wrapperRef, buttonRef]);

  function handleCopyClick(e) {
    e.preventDefault();
    navigator.clipboard.writeText(issuerId);
    setTimeout(() => {
      setCopiedInterval(false);
    }, 2000);
    setCopiedInterval(true);
  }

  function handleSearchOpenToggle() {
    setSearchOpen(!searchOpen);
  }

  function handleOpenSearchBarToggle() {
    setOpenSearchBar(!openSearchBar);
  }

  useLayoutEffect(() => {
    if (siteStripeRef.current
        && ((siteStripeAlerts && siteStripeAlerts.length > 0)
            || siteStripeOn || siteStripe.displayFlag)) {
      calculateHeaderWrapperHeight();
    }

    window.addEventListener('resize', calculateHeaderWrapperHeight);
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('resize', calculateHeaderWrapperHeight);
    };
  }, [siteStripeAlerts, siteStripeRef, siteStripeOn]);

  useEffect(() => {
    if (statusAuth === STATUS.FETCHED) {
      loadProfile();
    }
    if (siteStripe.status === STATUS.UNFETCHED) {
      loadSiteStripe();
    }
    if (statusAlerts === STATUS.UNFETCHED && statusAuth === STATUS.FETCHED) {
      loadAlerts();
    }
    if (statusUserDefinedAttributes === STATUS.UNFETCHED && statusAuth === STATUS.FETCHED) {
      loadUserDefinedAttributes();
    }
  }, [statusAuth, statusAlerts, statusAuth, statusUserDefinedAttributes, loadProfile,
    loadAlerts, loadUserDefinedAttributes, loadSiteStripe, siteStripe.status]);

  const filteredLinks = (linksArray) => linksArray.filter((item) => (item.group && item.group === 'all')
    || (item.group && userDefinedAttributes.includes(item.group)));

  const linkListsSection = linkLists && linkLists.map((list, index) => (
    // eslint-disable-next-line react/no-array-index-key,max-len
    <LinkList parentComponentName={componentName} key={index} header={list.header} links={filteredLinks(list.links)} />
  ));
  const iconLinksSection = iconLinks && iconLinks.map((link, index) => (
    <IconLink
      icon={link.icon}
      key={index} // eslint-disable-line react/no-array-index-key
      target={link.target}
      text={link.text}
      url={link.url}
      parentComponentName={componentName}
    />
  ));
  const logoutLink = logout && (
    <IconLink
      color={theme.palette.custom.brandBrown}
      icon={logout.icon}
      onClick={(e) => {
        e.preventDefault();
        window.MyPhoenix.Persistor.purge();
        removeBulkSessionStorageData();
        logoutRedirect(logout.url);
      }}
      text={logout.text}
      url={logout.url}
      parentComponentName={componentName}
    />
  );

  return (
    <div style={{ height: `${headerWrapperHeight}rem` }}>
      <header className={`${classes.root} mui-fixed container-fluid no-gutters ${scroll ? `${classes.scroll}` : ''}`}>
        <div className={classes.headerRowContainer}>
          <div className={classes.headerEyebrow}>
            <div className={classes.headerEyebrowContainer}>
              <a
                href={profileLink}
                aria-label={issuerId ? `${firstName} ${lastName} IRN ${issuerId}` : `${firstName} ${lastName}`}
                tabIndex="0"
                ref={issuerIdRef}
                data-raise-event-on-click={createEventObject(
                  `${componentName}_IssuerIdLink`,
                  [{ url: profileLink }, { issuerId }],
                )}
              >
                {' '}
                <span className={classes.profileInfo}>
                  <span>{`${firstName} ${lastName}`}</span>
                </span>
                {issuerId && (
                  <span className={classes.irnText}>
                    {issuerId}
                    &nbsp;
                    <Icon
                      className={classes.copyIcon}
                      color={copiedInterval ? 'phoenix-red' : 'icon-gray'}
                      icon="icon-copy"
                      onClick={handleCopyClick}
                      ref={copyIssuerIdRef}
                      data-raise-event-on-click={createEventObject(
                        `${componentName}_CopyIssuerIdLink`,
                        [{ issuerId }],
                      )}
                    />
                    <span className={`${classes.copyText} ${copiedInterval ? '' : `${classes.hidden}`}`}>&nbsp;Copied!</span>
                  </span>
                )}
              </a>
            </div>
          </div>
          <Grid className={classes.headerRow} container alignItems="center">
            <Grid item sm={0} md={4} inert={menuOpen ? 'true' : undefined} className={classes.avatar}>
              <HeaderAvatarContainer
                profileLink={profileLink}
                parentComponentName={componentName}
              />
            </Grid>
            <Grid item sm={6} md={4} className={classes.logo} inert={menuOpen ? 'true' : undefined}>
              <a
                href={myPhoenixLink}
                aria-label="My Phoenix homepage"
                ref={logoRef}
                data-raise-event-on-click={createEventObject(
                  `${componentName}_LogoLink`,
                  [{ url: profileLink }, { issuerId }],
                )}
              >
                <Icon icon="icon-phoenix-logo" color={theme.palette.custom.brandRed} />
                <span className={classes.myPhoenixTitle} id="my-phoenix-title">MyPhoenix</span>
              </a>
            </Grid>
            <Grid item sm={6} md={4} className={classes.menuToggle}>
              <div className={classes.searchInputContainer}>
                {enableSearch
                 && (
                 <SearchInput
                   searchLandingPagePath={searchLandingPagePath}
                   handleSearchOpenToggle={handleSearchOpenToggle}
                   handleOpenSearchBarToggle={handleOpenSearchBarToggle}
                   searchOpenValue={searchOpen}
                   openSearchBarValue={openSearchBar}
                 />
                 )}
              </div>
              {!searchOpen && (
                <>
                  <div className={classes.emailContainer}>
                    <a
                      id="email-link"
                      href={emailInboxLink}
                      target={emailInboxLinkTarget}
                      inert={menuOpen ? 'true' : undefined}
                      aria-label="Email Inbox link."
                      className={classes.emailLink}
                      rel="noreferrer"
                      ref={emailRef}
                      data-raise-event-on-click={createEventObject(
                        `${componentName}_EmailLink`,
                        [{ url: emailInboxLink }, { target: emailInboxLinkTarget }, { text: 'Email Inbox' }],
                      )}
                    >
                      <Icon icon="icon-email" />
                      <span className={`${classes.headerIconLabel} ${classes.emailLabel}`}>Email&nbsp;Inbox</span>
                    </a>
                  </div>
                  <div className={classes.notificationContainer}>
                    <NotificationBellContainer
                      messageCenterLink={messageCenterLink}
                      menuOpen={menuOpen}
                      parentComponentName={componentName}
                    />
                  </div>
                  <div className={classes.menuContainer} aria-labelledby="hamburger-menu-button">
                    <button
                      type="button"
                      id="hamburger-menu-button"
                      onClick={toggleMenu}
                      aria-label={menuOpen ? 'Close Navigation Menu' : 'Open Navigation Menu'}
                      aria-expanded={menuOpen ? 'true' : 'false'}
                      aria-controls="MainMenuNavigation"
                      ref={buttonRef}
                      data-testid="menu-button"
                      data-raise-event-on-click={createEventObject(
                        `${componentName}_MenuButton`,
                        [{ open: menuOpen }],
                      )}
                    >
                      <div
                        className={menuOpen ? `${classes.hamburger} ${classes.active}` : `${classes.hamburger}`}
                      >
                        <span />
                        <span />
                      </div>
                      <div className={`${classes.headerIconLabel} ${classes.menuLabel}`} aria-hidden="true">{menuOpen ? 'Close' : 'Menu' }</div>
                    </button>
                  </div>
                </>
              )}

            </Grid>
          </Grid>
        </div>
      </header>
      <div className={`${classes.navContainer} ${menuOpen ? `${classes.open}` : ''}`}>
        {/*
          TODO: The correct way to go is with inert but FireFox is still implementing it.
          Right now it's in a pre-release state, next version of FireFox should have it implemented.
          https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/inert#browser_compatibility
        */}
        <div
          className={menuOpen ? classes.navCenterer : classes.navCentererHidden}
        >
          <nav
            ref={wrapperRef}
            inert={(menuOpen || false)}
            aria-label="Main Menu"
            id="MainMenuNavigation"
            className={`v-100 ${menuOpen ? `${classes.open}` : ''}`}
            data-testid="menu-open"
          >
            <HeaderSectionListContainer
              inert={(menuOpen ? undefined : 'true')}
              parentComponentName={componentName}
            />
            <div className={classes.linkListsContainer}>
              {linkListsSection}
            </div>
            <div className={classes.iconLinksContainer}>
              {iconLinksSection}
              {logoutLink}
            </div>
          </nav>
        </div>
      </div>
      <div className={menuOpen && `${classes.menuOpenOverlay}`} />
      {/* Defaults to props passed from mark-up-generator\layout\header\header.props.js.
      This helps with loading state while we make the call to builder */}
      <SiteStripe
        ref={siteStripeRef}
        siteStripeAlerts={siteStripeAlerts}
        siteStripeOn={siteStripe.displayFlag ? siteStripe.displayFlag : siteStripeOn}
        ariaLabel={siteStripeAriaLabel}
        message={siteStripe.message ? siteStripe.message : siteStripeMessage}
        ctaText={siteStripe.ctaText ? siteStripe.ctaText : siteStripeCtaText}
        ctaUrl={siteStripe.ctaUrl ? siteStripe.ctaUrl : siteStripeCtaUrl}
        ctaTarget={siteStripe.target ? siteStripe.target : siteStripeCtaTarget}
        parentComponentName={componentName}
      />
    </div>
  );
}

Header.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  issuerId: PropTypes.string,
  siteStripe: PropTypes.shape({
    displayFlag: PropTypes.bool,
    message: PropTypes.string,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    target: PropTypes.string,
    status: PropTypes.string,
  }),
  iconLinks: PropTypes.arrayOf(
    PropTypes.shape({
      // Shape comes from Icon Link component
      icon: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      target: PropTypes.string,
      styleType: PropTypes.string,
    }),
  ),
  linkLists: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          // Shape comes from Link List component
          text: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          target: PropTypes.string,
          group: PropTypes.string,
        }),
      ),
    }),
  ),
  emailInboxLink: PropTypes.string,
  emailInboxLinkTarget: PropTypes.string,
  enableSearch: PropTypes.bool,
  logout: PropTypes.shape({
    text: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
  messageCenterLink: PropTypes.string,
  myPhoenixLink: PropTypes.string,
  profileLink: PropTypes.string,
  searchLandingPagePath: PropTypes.string,
  siteStripeOn: PropTypes.bool,
  siteStripeAriaLabel: PropTypes.string,
  siteStripeMessage: PropTypes.string,
  siteStripeCtaText: PropTypes.string,
  siteStripeCtaUrl: PropTypes.string,
  siteStripeCtaTarget: PropTypes.string,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  loadAlerts: PropTypes.func.isRequired,
  loadProfile: PropTypes.func.isRequired,
  loadSiteStripe: PropTypes.func.isRequired,
  loadUserDefinedAttributes: PropTypes.func.isRequired,
  siteStripeAlerts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      description: PropTypes.string,
      startDate: PropTypes.string,
      priority: PropTypes.shape({
        level: PropTypes.string,
        weight: PropTypes.number,
      }),
    }),
  ),
  statusAuth: PropTypes.string.isRequired,
  statusAlerts: PropTypes.string.isRequired,
  statusUserDefinedAttributes: PropTypes.string.isRequired,
  userDefinedAttributes: PropTypes.arrayOf(PropTypes.string),
};

Header.defaultProps = {
  enableSearch: false,
  siteStripe: {
    displayFlag: false,
    message: '',
    ctaText: '',
    ctaUrl: '',
    target: '',
    status: STATUS.UNFETCHED,
  },
  emailInboxLink: '',
  emailInboxLinkTarget: '_blank',
  firstName: '',
  lastName: '',
  issuerId: '',
  searchLandingPagePath: '',
  iconLinks: [],
  linkLists: [],
  logout: {
    text: 'Logout',
    icon: 'icon-logout',
    url: '/services/public/myphoenix/ssologout',
  },
  profileLink: '',
  myPhoenixLink: '/home.html',
  messageCenterLink: '',
  siteStripeOn: false,
  siteStripeAriaLabel: '',
  siteStripeMessage: '',
  siteStripeCtaText: '',
  siteStripeCtaUrl: '',
  siteStripeCtaTarget: '',
  siteStripeAlerts: [],
  userDefinedAttributes: [],
};

export default withWidth()(Header);
