export const getPrimaryInstructorPersonIdByMembershipSourceType = (
  memberships,
) => {
  let personId = null;
  if (memberships?.length) {
    const primaryInstructorMembership = memberships.filter(
      (membership) => membership?.subRoleType?.toLowerCase() === 'primary',
    );
    personId = primaryInstructorMembership[0]?.personId ?? null;
  }

  return personId;
};

export const getFacultyEmail = (instructorEmails) => {
  const universityEmails = instructorEmails?.filter(
      (email) => email?.type?.toLowerCase() === 'university',
  );
  const primaryUniversityEmails = universityEmails?.filter(
      (email) => email?.primary?.toLowerCase() === 'true',
  );
  return (primaryUniversityEmails && primaryUniversityEmails[0])
      || (universityEmails && universityEmails[0])
      || {};
};
