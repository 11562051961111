import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useStyles from './FeedbackButton.styles';
import FeedbackModal from '../feedback-modal/v1/FeedbackModal';

function FeedbackButton(props) {
  const {
    feedbackLoopType,
    internalSurveySubmitEndpoint,
    externalSurveyTarget,
    externalSurveyURL,
    successMessage,
    errorMessage,
    emailConsent,
    selectedEmojiValue,
    feedbackReason,
    feedbackCategory,
    setSelectedEmailConsent,
    setSelectedFeedbackCategory,
    setSelectedEmojiValue,
    setSelectedFeedbackReason,
    postFeedbackSurvey,
    clearSelectedFeedback,
    showCategories,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const componentName = 'FeedbackButton';
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  const getInternalSurveyEndpoint = () => {
    if (feedbackLoopType === 'internal') {
      return !internalSurveySubmitEndpoint ? '/services/data/v51.0/sobjects/UOPX_Survey__c' : internalSurveySubmitEndpoint;
    }

    return '';
  };
  const getExternalSurveyURL = () => (feedbackLoopType === 'external' && externalSurveyTarget === 'modal' ? externalSurveyURL : '');
  const handleModalOpen = () => {
    if (feedbackLoopType === 'internal' || (feedbackLoopType === 'external' && externalSurveyTarget === 'modal')) {
      if (feedbackLoopType === 'internal') {
        setSelectedEmailConsent(emailConsent);
        setSelectedFeedbackCategory(feedbackCategory);
        setSelectedEmojiValue(selectedEmojiValue);
        setSelectedFeedbackReason(feedbackReason);
      }
      setModalOpen(true);
    } else {
      window.open(externalSurveyURL, externalSurveyTarget);
    }
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <div className={classes.buttonContainer}>
        <Button
          id="modal-title"
          aria-label="Feedback"
          className={classes.ctaButton}
          type="button"
          onClick={handleModalOpen}
        >
          <Typography className={classes.buttonText}>
            Feedback
          </Typography>
        </Button>
      </div>
      <FeedbackModal
        selectedEmojiValue={selectedEmojiValue}
        emailConsent={emailConsent}
        feedbackCategory={feedbackCategory}
        feedbackReason={feedbackReason}
        isModalOpen={modalOpen}
        feedbackLoopType={feedbackLoopType}
        closeCallback={handleModalClose}
        internalSurveySubmitEndpoint={getInternalSurveyEndpoint()}
        externalSurveyURL={getExternalSurveyURL()}
        successMessage={successMessage || 'Thank you\'! We appreciate your feedback!'}
        errorMessage={errorMessage || 'Oh dear! We\'re sorry your feedback did\'nt go through. Please resend'}
        setSelectedEmailConsent={setSelectedEmailConsent}
        setSelectedFeedbackCategory={setSelectedFeedbackCategory}
        setSelectedEmojiValue={setSelectedEmojiValue}
        setSelectedFeedbackReason={setSelectedFeedbackReason}
        postFeedbackSurvey={postFeedbackSurvey}
        clearSelectedFeedback={clearSelectedFeedback}
        showCategories={showCategories}
      />

    </div>
  );
}

export default FeedbackButton;

FeedbackButton.defaultProps = {
  feedbackLoopType: 'internal',
  internalSurveySubmitEndpoint: '',
  externalSurveyTarget: '_blank',
  externalSurveyURL: '',
  emailConsent: '',
  feedbackCategory: '',
  selectedEmojiValue: '',
  feedbackReason: '',
  successMessage: '',
  errorMessage: '',
  showCategories: true,
  setSelectedEmailConsent: () => Promise.resolve(),
  setSelectedFeedbackCategory: () => Promise.resolve(),
  setSelectedEmojiValue: () => Promise.resolve(),
  setSelectedFeedbackReason: () => Promise.resolve(),
  postFeedbackSurvey: () => Promise.resolve(),
  clearSelectedFeedback: () => Promise.resolve(),
};

FeedbackButton.propTypes = {
  feedbackLoopType: PropTypes.string,
  internalSurveySubmitEndpoint: PropTypes.string,
  externalSurveyTarget: PropTypes.string,
  externalSurveyURL: PropTypes.string,
  emailConsent: PropTypes.string,
  feedbackCategory: PropTypes.string,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  selectedEmojiValue: PropTypes.string,
  feedbackReason: PropTypes.string,
  setSelectedEmailConsent: PropTypes.func,
  setSelectedFeedbackCategory: PropTypes.func,
  setSelectedEmojiValue: PropTypes.func,
  setSelectedFeedbackReason: PropTypes.func,
  postFeedbackSurvey: PropTypes.func,
  clearSelectedFeedback: PropTypes.func,
  showCategories: PropTypes.bool,
};
