let retryAttempts = 0;
/**
 * This Interceptor is intended to handle server responses of 5XX.
*/
const retryInterceptor = (error, client, enabled, allowed) => {
  const { config, response } = error;
  if (enabled) {
    if ((config && response && response.status >= 500 && response.status <= 511)) {
      // including this check because the detailprogression call has a 500 error we need to parse
      // remove this check once the microservice is fixed
      const { url } = config;
      const isDegreeAuditPath = url.includes('/v1/program/detailprogression');

      if (
        config.method.toLowerCase() === 'get'
          && config.url
          && config.headers
          && retryAttempts <= allowed
          && !isDegreeAuditPath
      ) {
        retryAttempts += 1;
        return client.request(config);
      }
    }
  }
  return Promise.reject(error);
};

export default retryInterceptor;
