import { publishMyPhoenixErrorEvent } from './event-functions';

const methodToCode = (method) => {
  switch (method) {
    case 'delete':
      return '01';
    case 'get':
      return '02';
    case 'post':
      return '03';
    case 'put':
      return '04';
    default:
      return '00';
  }
};

/**
     * Convert Domain and Subdomains to Code
     * @param {*} domain
     * @param {*} url
     */
// TODO: Ensure all calls in the app are covered. (I.E. Wallets, work, etc)
const domainToDigits = (domain, url) => {
  switch (domain) {
    case 'applications':
      if (url.indexOf('applications') >= 0) {
        return '011';
      }
      return '010';
    case 'catalogs':
      return '020';
    case 'contacts':
      if (url.indexOf('validate') >= 0) {
        if (url.indexOf('zipcode') >= 0) {
          return '032';
        }
        if (url.indexOf('address') >= 0) {
          return '033';
        }
        return '031';
      }
      if (url.indexOf('suggest') >= 0) {
        if (url.indexOf('address') >= 0) {
          return '035';
        }
        return '034';
      }
      // addresses or address
      if (url.indexOf('address') >= 0) {
        return '036';
      }
      // emails or email
      if (url.indexOf('email') >= 0) {
        return '037';
      }
      // phones or phone
      if (url.indexOf('phone') >= 0) {
        return '038';
      }
      if (url.indexOf('support') >= 0) {
        return '039';
      }
      return '030';
    case 'courses':
      if (url.indexOf('curriculum') >= 0) {
        return '041';
      }
      return '040';
    case 'iam':
      if (url.indexOf('email') >= 0) {
        return '051';
      }
      if (url.indexOf('username') >= 0) {
        return '052';
      }
      return '050';
    case 'learning':
      if (url.indexOf('activities') >= 0) {
        return '061';
      }
      if (url.indexOf('activitygroups') >= 0) {
        return '062';
      }
      if (url.indexOf('assessmentresults') >= 0) {
        return '063';
      }
      if (url.indexOf('memberships') >= 0) {
        return '064';
      }
      if (url.indexOf('section') >= 0) {
        return '065';
      }
      if (url.indexOf('attendances') >= 0) {
        return '066';
      }
      if (url.indexOf('curriculum') >= 0) {
        return '067';
      }
      return '060';
    case 'organizations':
      if (url.indexOf('military') >= 0) {
        return '072';
      }
      if (url.indexOf('veranorg') >= 0) {
        return '072';
      }
      return '070';
    case 'persons':
      if (url.indexOf('names') >= 0) {
        return '081';
      }
      if (url.indexOf('name') >= 0) {
        return '082';
      }
      if (url.indexOf('avatar') >= 0) {
        return '083';
      }
      if (url.indexOf('events-count') >= 0) {
        return '084';
      }
      if (url.indexOf('attributes') >= 0) {
        return '085';
      }
      if (url.indexOf('information') >= 0) {
        return '086';
      }
      if (url.indexOf('military') >= 0) {
        return '087';
      }
      // /person, /persons, else
      return '080';
    case 'preferences':
      return '090';
    case 'programs':
      if (url.indexOf('templates') >= 0) {
        return '101';
      }
      if (url.indexOf('programeligibility') >= 0) {
        return '102';
      }
      return '100';
    case 'sagas':
      if (url.indexOf('persontasks') >= 0) {
        return '111';
      }
      if (url.indexOf('persontask') >= 0) {
        return '112';
      }
      if (url.indexOf('workflow/admission/person') >= 0) {
        return '113';
      }
      return '110';
    case 'student':
      if (url.indexOf('pla/documents') >= 0) {
        return '127';
      }
      if (url.indexOf('pla/applications') >= 0) {
        return '128';
      }
      if (url.indexOf('pla') >= 0) {
        return '121';
      }
      if (url.indexOf('detailprogression') >= 0) {
        return '122';
      }
      if (url.indexOf('statuses') >= 0) {
        return '123';
      }
      if (url.indexOf('membershipsdetails') >= 0) {
        return '124';
      }
      if (url.indexOf('leaveofabsence') >= 0) {
        return '125';
      }
      if (url.indexOf('outcome') >= 0) {
        return '126';
      }
      if (url.indexOf('cbe') >= 0) {
        return '129';
      }
      if (url.indexOf('estimatedgraduationdate') >= 0) {
        return '191';
      }
      if (url.indexOf('course/memberships') >= 0) {
        return '192';
      }
      if (url.indexOf('academic/terms') >= 0) {
        return '193';
      }
      if (url.indexOf('academic/years') >= 0) {
        return '194';
      }
      if (url.indexOf('academic/yearterms') >= 0) {
        return '195';
      }
      if (url.indexOf('transfercreditdetails') >= 0) {
        return '196';
      }
      return '120';
    case 'wallets':
      return '130';
    case 'communication':
      if (url.indexOf('notifications') >= 0) {
        return '141';
      }
      if (url.indexOf('alerts') >= 0) {
        return '142';
      }
      return '140';
    case 'document':
      if (url.indexOf('formorpacket') >= 0) {
        return '151';
      }
      return '150';
    case 'search':
      return '160';
    default:
      return '000';
  }
};

const urlToCode = (url) => {
  const { MicroServices } = window.MyPhoenix.Config;
  if (url.indexOf(MicroServices.Base) >= 0) { // API Request 10XXX
    const urlParts = url.replace(MicroServices.Base, '').split('/');
    return `10${domainToDigits(urlParts[1], url)}`;
  }
  return '00000'; // OTHER
};

export const errorToCode = (error) => {
  const { config = {}, response = {} } = error;
  const errorCode = `${methodToCode(config.method)}-${urlToCode(config.url ? config.url.toLowerCase() : '')}-${response.status || '000'}`;
  return errorCode;
};

export const errorToMessage = (error) => {
  const { code = '' } = error;
  const errorCode = code || errorToCode(error);
  if (errorCode === '00-00000-000' && error.message) {
    return error.message;
  }
  return errorCode;
};

export const trackTimeoutError = (requestCode, requestId, correlationId) => {
  publishMyPhoenixErrorEvent(`ECONNABORTED: ${requestCode}`, { requestId, correlationId });
};

export const trackComponentError = (component, message) => {
  const { MyPhoenix } = window;
  const personId = MyPhoenix.User ? MyPhoenix.User.personId : 'N/A';
  publishMyPhoenixErrorEvent(`ErrorMessage: ${component}`, { personId: personId || 'N/A', message });
};

export const reloadOnError = () => {
  if (window.MyPhoenix.Config.Features.PurgeOnErrorReloadEnabled) {
    window.MyPhoenix.Persistor.purge();
  }
  window.location.reload();
};
