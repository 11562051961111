import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  heading: {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '1.5rem',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1.5),
  },
  sectionLinkContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
  },
  cbeDASectionLinkContainer: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(1),
    '&:nth-of-type(1)': {
      paddingTop: theme.spacing(2),
      borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    },
    '&:last-child': {
      borderRadius: theme.spacing(0, 0, 0.5, 0.5),
      paddingBottom: theme.spacing(2),
    },
    '&:only-of-type': {
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(2, 0, 2, 1),
    },
  },
}));
