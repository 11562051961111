export default function FeedbackSurveyService() {
  let { SalesForce } = window.MyPhoenix.Config;
  SalesForce = SalesForce || {};
  const ownerId = SalesForce.OwnerId;
  const recordTypeId = SalesForce.RecordTypeId;

  /**
   * Returns submitted application as HTML/JS/CSS.
   *
   * @returns Promise<`*>
   */

  // uses fetch instead because getting the sales force token is handled in next.js
  const postFeedbackSurvey = async (pLoad, endpoint) => {
    const payload = pLoad;
    payload.OwnerId = ownerId;
    payload.RecordTypeId = recordTypeId;
    const request = {
      method: 'POST',
      body: JSON.stringify(payload),
    };

    return fetch(endpoint, request);
  };

  return {
    postFeedbackSurvey,
  };
}
