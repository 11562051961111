// consider the usage of a proper template library if the use-cases are extensive
const stringFromTemplate = (template, data) => {
  const outputStr = template.replace(/#\{(\d)\}/g, (_, p1) => data[p1]);
  return outputStr;
};

const resolveExternalConfigs = (defaultVal, incoming, dynamicVals) => {
  let resolved = defaultVal;
  if (incoming.match(/#{\d+}/)) {
    resolved = dynamicVals ? (stringFromTemplate(incoming, dynamicVals) || resolved) : resolved;
  } else if (incoming) {
    resolved = incoming;
  }
  return resolved;
};

const createMultiVariableObject = (variables, dynamicValues) => {
  const result = {};
  // eslint-disable-next-line no-return-assign
  variables.forEach((key, i) => result[key] = dynamicValues[i]);
  return result;
};

const mapVariableToValue = (defaultVal, incoming, variableMap) => {
  let resolved = defaultVal;
  if (variableMap) {
    const objectKeysRegex = new RegExp(Object.keys(variableMap).join('|'), 'g');
    if (incoming.match(objectKeysRegex)) {
      resolved = incoming.replace(objectKeysRegex, (matched) => variableMap[matched]);
    }
  } else if (incoming) {
    resolved = incoming;
  }
  return resolved;
};

const truncate = (str, n, useWordBoundary) => {
  if (str.length <= n) { return str; }
  const subString = str.substr(0, n - 1);
  return `${useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(' '))
    : subString} ...`;
};

const labelize = (objectKey) => {
  let label = objectKey.replace(/((^[a-z]|[A-Z0-9])[a-z]*|-)/g, (char) => (char === '-' ? ' ' : ` ${char}`));
  label = label.substring(1);
  label = label.replace(/(^| )(\w)/g, (char) => char.toUpperCase());
  return label;
};

const toLowerCaseButCapitalizeEveryFirstChar = (sentence) => {
  const sentenceWords = sentence.split(' ');
  const blackList = ['OF', 'IN', 'AT'];

  return sentenceWords.map((word) => {
    if (blackList.includes(word)) return word.toLowerCase();

    const firstLetter = word.charAt(0);
    const cWord = word.toLowerCase();
    return firstLetter + cWord.slice(1);
  }).join(' ');
};

const componentNameToMyPhxKebabFormat = (componentName) => {
  let attributeValue = '';
  if (typeof componentName === 'string') {
    const nameSpace = 'myphx';
    // find all capitals and add dash before value example ComponentName --> -Component-Name
    const nameDelimited = componentName.replace(/([A-Z])/g, '-$1');
    // then format to lower case example -Component-Name  --> -component-name
    const fullyFormatted = nameDelimited.toLowerCase();
    // full value myphx-component-name
    attributeValue = nameSpace + fullyFormatted;
  }
  return attributeValue;
};

const mergeComponentNames = (parentComponentName, childComponentName) => {
  if (parentComponentName && childComponentName) {
    return `${parentComponentName}_${childComponentName}`;
  }
  return parentComponentName || childComponentName;
};

const makeBold = (string, keyword) => {
  const re = new RegExp(keyword, 'g');
  return string.replace(re, `<b>${keyword}</b>`);
};

const getUnitsOrCredits = (isCBEDA) => (isCBEDA ? 'Units' : 'Credits');

const replaceCreditVerbiageIfNeeded = (isCBEDA, string, includeCompetency = false) => (
  isCBEDA ? string.replace(/Credit/g, `${includeCompetency ? 'Competency ' : ''}Unit`).replace(/credit/g, `${includeCompetency ? 'competency ' : ''}unit`)
    : string);

const capitalize = (word) => word
  .toLowerCase()
  .replace(/\w/, (firstLetter) => firstLetter.toUpperCase());

export {
  stringFromTemplate,
  resolveExternalConfigs,
  truncate,
  labelize,
  toLowerCaseButCapitalizeEveryFirstChar,
  createMultiVariableObject,
  mapVariableToValue,
  componentNameToMyPhxKebabFormat,
  mergeComponentNames,
  makeBold,
  getUnitsOrCredits,
  replaceCreditVerbiageIfNeeded,
  capitalize,
};
