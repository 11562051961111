import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    '&.styleType-default': {
      color: theme.palette.text.primary,
    },
    '&.styleType-white': {
      color: theme.palette.background.paper,
    },
    '&.styleType-table': {
      border: `0.1rem solid ${theme.palette.grays.borderGray}`,
      borderTop: 'none',
    },
  },
  summary: {
    minHeight: theme.spacing(1),
    padding: 0,
    '&.styleType-table': {
      padding: theme.spacing(0.6, 2.4),
    },
    '&.Mui-expanded': {
      minHeight: theme.spacing(1),
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      outline: `${theme.spacing(0.1)} solid ${theme.palette.text.primary}`,
    },
    '& .MuiAccordionSummary-content': {
      margin: `${theme.spacing(1.5)} 0`,
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: theme.spacing(-1),
    },
  },
  summaryHeader: {
    fontSize: '1.7rem',
    fontWeight: '500',
    '&.styleType-table': {
      fontSize: '2rem',
      fontWeight: (props) => (props.boldHeader ? '600' : '300'),
    },
  },
  expandIcon: {
    '&.styleType-default,&.styleType-table': {
      fill: theme.palette.primary.main,
    },
    '&.styleType-table': {
      width: '2.4rem',
      height: '2.4rem',
    },
    '&.styleType-white': {
      fill: theme.palette.background.paper,
    },
  },
  details: {
    marginLeft: theme.spacing(0.3),
    marginTop: theme.spacing(-0.5),
    padding: 0,
    '&.styleType-table': {
      padding: theme.spacing(0.6, 2.2, 2.4),
    },
  },
  detailsContent: {
    fontSize: '1.7rem',
    '& ul': {
      margin: 0,
      paddingInlineStart: 0,
      listStyle: 'none',
      '& li': {
        listStyle: 'none',
      },
    },
  },
  item: {
    padding: `${theme.spacing(0.2)} 0`,
    '&.styleType-table': {
      paddingBottom: theme.spacing(2),
    },
    '&.styleType-table:last-child': {
      paddingBottom: 0,
    },
    '& a': {
      textDecoration: 'underline',
      '&:hover, &:focus': {
        textDecoration: 'none',
        borderBottom: `0.3rem solid ${theme.palette.primary.main}`,
      },
      '&:focus': {
        outline: `${theme.spacing(0.1)} solid ${theme.palette.text.primary}`,
        outlineOffset: theme.spacing(0.3),
      },
    },
    '&.styleType-default a': {
      color: theme.palette.text.primary,
    },
    '&.styleType-white a': {
      color: theme.palette.background.paper,
    },
    '& h5': {
      fontSize: '1.6rem',
      fontWeight: '600',
      color: theme.palette.text.disabled,
      '& button > .MuiButton-label': {
        textDecoration: 'underline',
        fontWeight: '600',
        '&:hover, &:focus': {
          textDecoration: 'none',
          borderBottom: `0.3rem solid ${theme.palette.primary.main}`,
        },
      },
    },
    '& h6': {
      fontSize: '1.6rem',
    },
  },
}));

export default useStyles;
