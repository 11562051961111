import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import Link from '@material-ui/core/Link';
import Truncate from 'react-truncate';
import moment from 'moment-timezone';
import useStyles from './SiteStripe.styles';
import Icon from '../../icon/v1/Icon';
import { adjustForAnchorNavigation, handleAnchorClick } from '../../../utils/anchor-nav-functions';
import { createEventObject } from '../../../utils/event-functions';

const SiteStripe = React.forwardRef((props, ref) => {
  const {
    ariaLabel, message, siteStripeOn, siteStripeAlerts,
    ctaText, ctaUrl, ctaTarget, lines, more, less, parentComponentName,
  } = props;
  const componentName = 'SiteStripe';
  const [expanded, setExpanded] = useState(false);
  const [truncate, setTruncated] = useState(false);
  const hasSiteStripeAlert = siteStripeAlerts.length > 0;
  // sort system alerts by weight
  const sortedAlertsByWeight = siteStripeAlerts
    .sort((a, b) => b.priority.weight - a.priority.weight);
  // then sort weight by date
  const currentAlert = sortedAlertsByWeight
    .sort((a, b) => moment(b.startDate).tz('America/Phoenix') - moment(a.startDate).tz('America/Phoenix'));
  const removeMarkup = (string) => string.replace(/(<([^>]+)>)/gi, '');
  const alertMessage = () => {
    let alertText = '';
    if (hasSiteStripeAlert) {
      if (currentAlert[0].title) {
        alertText = currentAlert[0].title;
      } else {
        const hasMarkup = (/<\/?[a-z][\s\S]*>/i.test(currentAlert[0].description));
        alertText = !hasMarkup
          ? currentAlert[0].description : removeMarkup(currentAlert[0].description);
      }
    }
    return alertText;
  };

  let truncateWidth = 0;
  if (currentAlert.length && alertMessage().length > 0) {
    const font = 15;
    const lineLimit = 100;
    const infoIconWidth = 24;
    const length = alertMessage().length * font;
    const widthPlusInfoIcon = length + infoIconWidth;
    const max = font * lineLimit;
    if (widthPlusInfoIcon < max) {
      truncateWidth = widthPlusInfoIcon;
    }
  }

  const classes = useStyles(truncateWidth);

  const handleTruncate = (truncated) => {
    if (truncate !== truncated) {
      setTruncated(truncated);
    }
  };
  const toggleLines = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    adjustForAnchorNavigation(siteStripeOn, hasSiteStripeAlert);
    handleAnchorClick();
  });

  if (siteStripeOn) {
    return (
      <Fade in={!!message}>
        <section
          ref={ref}
          className={`${classes.stripe} mui-fixed site-stripe`}
          aria-label={ariaLabel || SiteStripe.defaultProps.ariaLabel}
          data-raise-event-on-display={createEventObject(
            `${parentComponentName}_${componentName}`,
            [{ message }],
          )}
        >
          <div className={classes.container}>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={classes.icon}>
              <Icon icon="icon-info" aria-hidden="true" />
            </div>
            <div className={classes.message}>
              <span dangerouslySetInnerHTML={{ __html: message }} className={classes.messageText} />
              {ctaText && (
                <>
                  <Link
                    target={ctaTarget}
                    href={ctaUrl}
                    className={classes.cta}
                    data-raise-event-on-click={createEventObject(
                      `${parentComponentName}_${componentName}_Cta`,
                      [{ url: ctaUrl }, { text: ctaText }],
                    )}
                  >
                    {ctaText}
                    <Icon icon="icon-long-arrow" className={classes['icon-long-arrow']} aria-hidden="true" />
                  </Link>
                </>
              )}
            </div>
          </div>
        </section>
      </Fade>
    );
  } if (hasSiteStripeAlert) {
    return (
      <Fade in={!!alertMessage()}>
        <section
          ref={ref}
          className={`${classes.stripe} mui-fixed site-stripe`}
          aria-label={SiteStripe.defaultProps.ariaLabel}
        >
          <div className={classes.container}>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={classes.icon}>
              <Icon icon="icon-info" aria-hidden="true" />
            </div>
            <div className={expanded ? classes.alertExpanded : classes.alertMessage}>
              <Truncate
                lines={!expanded && lines}
                ellipsis={(
                  <span>
                    ...
                    <button className={classes.truncateButton} type="submit" aria-label={more} onClick={toggleLines}>{more}</button>
                    {currentAlert[0].link && (
                    <Link
                      target="_blank"
                      aria-label="Learn More"
                      href={currentAlert[0].link}
                      className={classes.cta}
                    >
                      Learn More
                      <Icon icon="icon-long-arrow" className={classes['icon-long-arrow']} aria-hidden="true" />
                    </Link>
                    )}
                  </span>
                    )}
                onTruncate={handleTruncate}
                width={truncateWidth}
              >
                <span className={classes.messageText}>
                  {alertMessage()}
                </span>
              </Truncate>
              {!truncate && expanded && (
                <span>
                  {' '}
                  <button className={classes.truncateButton} aria-label={less} type="submit" onClick={toggleLines}>{less}</button>
                </span>
              )}
              {currentAlert[0].link && !truncate && (
                <>
                  <Link
                    target="_blank"
                    aria-label="Learn More"
                    href={currentAlert[0].link}
                    className={classes.cta}
                  >
                    Learn More
                    <Icon icon="icon-long-arrow" className={classes['icon-long-arrow']} aria-hidden="true" />
                  </Link>
                </>
              )}
            </div>
          </div>
        </section>
      </Fade>
    );
  }
  return null;
});

SiteStripe.propTypes = {
  ariaLabel: PropTypes.string,
  message: PropTypes.string,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaTarget: PropTypes.string,
  siteStripeOn: PropTypes.bool,
  siteStripeAlerts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      description: PropTypes.string,
      startDate: PropTypes.string,
      priority: PropTypes.shape({
        level: PropTypes.string,
        weight: PropTypes.number,
      }),
    }),
  ),
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
  parentComponentName: PropTypes.string,
};

SiteStripe.defaultProps = {
  ariaLabel: 'Important Messages',
  message: '',
  ctaText: '',
  ctaUrl: '',
  ctaTarget: '',
  siteStripeOn: false,
  siteStripeAlerts: [],
  lines: 2,
  more: 'Read more',
  less: 'Show less',
  parentComponentName: '',
};

export default SiteStripe;
