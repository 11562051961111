import { getBBRootUrl, getBBClassUrl, openBlackboard } from './blackboard-functions';
import { getBrightspaceBaseUrl, getBrightspaceClassUrl, openBrightspace } from './brightspace-functions';
import { CLASSROOM_BASE_URL_VAL, CLASSROOM_CURRENT_COURSE_URL_VAL, CLASSROOM_URL_PARAM_KEY } from './author-functions';
import { capitalize } from './stringUtils';

export const BLACKBOARD = 'BLACKBOARD';
export const BRIGHTSPACE = 'BRIGHTSPACE';

const CLASSROOM_MAP = {
  [BLACKBOARD]: {
    baseUrl: getBBRootUrl(),
    classUrl: (props) => getBBClassUrl(props.sectionId),
    openClassUrl: (props) => openBlackboard(props.sectionId),
  },
  [BRIGHTSPACE]: {
    baseUrl: getBrightspaceBaseUrl(),
    classUrl: () => getBrightspaceClassUrl(),
    openClassUrl: () => openBrightspace(),
  },
};

export const getClassroomKey = (props) => {
  const { isCBEDA } = props;
  if (isCBEDA) {
    return BRIGHTSPACE;
  }
  return BLACKBOARD;
};

export const getBaseClassroomUrl = (key) => CLASSROOM_MAP[key].baseUrl;

export const getClassroomClassUrl = (key, props) => CLASSROOM_MAP[key].classUrl(props);

export const openClassUrl = (key, props) => CLASSROOM_MAP[key].openClassUrl(props);

export const getClassroomInfoUrl = window?.MyPhoenix?.Config?.General?.ClassroomInfoUrl
  ? window.MyPhoenix.Config.General.ClassroomInfoUrl : '/public/go-to-class-info.html';

export const getClassroomErrorUrl = (key, props) => {
  const { isProgramsError } = props;
  if (isProgramsError) {
    return getClassroomInfoUrl;
  }
  return getBaseClassroomUrl(key);
};

export const hasAuthoredClassroomParams = (url) => url.includes(CLASSROOM_URL_PARAM_KEY);

export const getClassroomUrlFromAuthoredParamVal = (url, key, props) => {
  const { isProgramsError } = props;
  if (isProgramsError) {
    return url;
  }
  const urlParams = new URLSearchParams(url);
  let classroomUrl = url;
  urlParams.forEach((paramVal) => {
    if (paramVal === CLASSROOM_BASE_URL_VAL) {
      classroomUrl = getBaseClassroomUrl(key);
    }
    if (paramVal === CLASSROOM_CURRENT_COURSE_URL_VAL) {
      classroomUrl = getClassroomClassUrl(key, props);
    }
  });
  return classroomUrl;
};
export const gradeDescribinator = (grade, isCBEDA) => {
  const lowerCaseGrade = grade.toLowerCase();
  const gradeMap = {
    ma: 'Mastered',
    me: 'Met',
    nm: 'Not met',
    dr: 'Dropped',
    w: 'Withdrawn',
  };
  if (isCBEDA && gradeMap[lowerCaseGrade]) {
    return gradeMap[lowerCaseGrade];
  }
  return grade;
};

export const stringHasBlackboard = (str) => str.toLowerCase().includes(BLACKBOARD.toLowerCase());

export const replaceBlackboardWithClassRoomValueIfNeeded = (isCBEDA, str) => {
  if (isCBEDA) {
    return str.replace(capitalize(BLACKBOARD), capitalize(BRIGHTSPACE));
  }
  return str;
};
