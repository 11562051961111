const Scenarios = [
  {
    personId: '1', firstName: 'Avatar', lastName: 'Aang', description: '1 Current Section. 2 Upcoming. 1 Instructor.',
  },
  {
    personId: '2', firstName: 'Frodo', lastName: 'Baggins', description: '2 Current Sections. 1 AC.',
  },
  {
    personId: '3', firstName: 'Harry', lastName: 'Potter', description: '6 Current Sections. 3 Instructors. 2 ACs.',
  },
  {
    personId: '4', firstName: 'George', lastName: 'Weasley', description: '1 Dropped Section. 5 Previous. 2 ACs.',
  },
  {
    personId: '5', firstName: 'Neville', lastName: 'Longbottom', description: '1 Scheduled Section. 3 ERs.',
  },
  {
    personId: '6', firstName: 'Patrick', lastName: 'Star', description: '1 current section. 1 instructor. 1 ER (shows ER)',
  },
  {
    personId: '7', firstName: 'Spongebob', lastName: 'Squarepants', description: '1 current section, 1 past. 1 instructor. 1 ER, 1 AC (shows AC)',
  },
  {
    personId: '8', firstName: 'Sandy', lastName: 'Cheeks', description: '2 upcoming, full width upcoming tile. 1 ER',
  },
  {
    personId: '9', firstName: 'Mister', lastName: 'Meeseeks', description: '2 current, 1 past, 1 future',
  },
  {
    personId: '10', firstName: 'Rick', lastName: 'Sanchez', description: 'LOA, 1 future',
  },
  {
    personId: '11', firstName: 'Doctorate', lastName: 'One', description: 'Doctorate grad',
  },
  {
    personId: '12', firstName: 'Certificate', lastName: 'One', description: 'Certificate grad',
  },
  {
    personId: '13', firstName: 'Program', lastName: 'One', description: 'Program grad',
  },
  {
    personId: '900', firstName: 'Hermione', lastName: 'Granger', description: 'LOA, 2 future',
  },
  {
    personId: '15', firstName: 'Morty', lastName: 'Smith', description: 'LOA, no future',
  },
  {
    personId: '42', firstName: 'Arthur', lastName: 'Dent', description: 'New Student. No ACs or Instructors.',
  },
  {
    personId: '16', firstName: 'NewApp', lastName: 'Nate', description: 'New Student. V3 NewApp (Completed application, tasks in App Center).',
  },
  {
    personId: '17', firstName: 'NewApp', lastName: 'Nicki', description: 'New Student. V4 with enrollment tasks.',
  },
  {
    personId: 'TYPICAL', firstName: 'Tippie', lastName: 'Cal', description: '1 Current Section. 1 Upcoming. 1 Instructor. 1 AC.',
  },
  {
    personId: 'ERROR_PROGRAMS', firstName: 'Errol', lastName: 'Programs', description: 'Request for programs throws error',
  },
  {
    personId: 'ERROR_COURSES', firstName: 'Errol', lastName: 'Courses', description: 'Request for course membership throws error',
  },
  {
    personId: 'ERROR_COURSE', firstName: 'Errol', lastName: 'Course', description: 'Request for course section throws error',
  },
  {
    personId: 'ERROR_ATTENDANCES', firstName: 'Errol', lastName: 'Attendances', description: 'Request for course attendance throws error',
  },
  {
    personId: 'ERROR_NOTIFICATIONS', firstName: 'Errol', lastName: 'Notifications', description: 'Request for Notifications throws error',
  },
  {
    personId: 'ERROR_PERSONS', firstName: 'Errol', lastName: 'Persons', description: 'Request to Persons API throws error',
  },
  {
    personId: 'DEAN_LIST', firstName: 'Dean', lastName: 'List', description: 'Typical with unread Dean\'s list award.',
  },
  {
    personId: 'SKEEL_ERNER', firstName: 'Skeel', lastName: 'Erner', description: 'Typical with unread Skill achievement.',
  },
  {
    personId: 'RANDY_TIEMPO', firstName: 'Randy', lastName: 'Tiempo', description: 'Typical with random response times.',
  },
  {
    personId: 'PRAWG_DELAY', firstName: 'Prawg', lastName: 'DeLay', description: 'Typical with program progress timeout.',
  },
  {
    personId: '20', firstName: 'Mark', lastName: 'Hayden', description: '1 Current Section. 4 Upcoming.',
  },
  {
    personId: '43', firstName: 'Tom', lastName: 'Hanks', description: '1 Current Section. 4 Previous with CBE grades.',
  },
  {
    personId: '25', firstName: 'Mr.', lastName: 'Grinch', description: 'Withdrawn, 1 future course',
  },
  {
    personId: '26', firstName: 'Cindy Lou', lastName: 'Who', description: 'Withdrawn, no future courses',
  },
  {
    personId: 'CINDY_BOO_HOO', firstName: 'Cindy Boo', lastName: 'Hoo', description: 'Administratively Withdrawn, no future courses',
  },
  {
    personId: '27', firstName: 'Paul', lastName: 'Atreides', description: 'SkillsDashboard, program before date ',
  },
  {
    personId: '28', firstName: 'Duncan', lastName: 'Idaho', description: 'SkillsDashboard, no previous course, program on/after date',
  },
  {
    personId: '29', firstName: 'Thufir', lastName: 'Hawat', description: 'SkillsDashboard, no future course',
  },
  {
    personId: '35', firstName: 'Randall', lastName: 'Flagg', description: 'SkillsDashboard, Badge Pilot, multiple current courses, future and past course',
  },
  {
    personId: '555', firstName: 'John', lastName: 'Marsch', description: 'Skills Dashboard CBE Student',
  },
  {
    personId: '99', firstName: 'Shrek', lastName: '', description: 'CBE Student',
  },
  {
    personId: '40', firstName: 'Todo', lastName: 'Tracy', description: 'ReEnroll scenario',
  },
  {
    personId: '38', firstName: 'Never', lastName: 'Leavin', description: 'Near grad scenario, Alumni app type, enrollment status C',
  },
  {
    personId: '45', firstName: 'Submitted', lastName: 'Steven', description: 'Alumni App, app unsigned',
  },
  {
    personId: '46', firstName: 'Complete', lastName: 'Claire', description: 'Alumni App, Enrollment Tasks Complete',
  },
  {
    personId: '47', firstName: 'Todo', lastName: 'Tucker', description: 'Alumni App, Enrollment Tasks Incomplete',
  },
  {
    personId: '48', firstName: 'Nearly', lastName: 'Derrick', description: 'Near Grad, Enrollment Tasks Incomplete, enrollment status C',
  },
  {
    personId: '49', firstName: 'Proactive', lastName: 'Perry', description: 'Near Grad, Enrollment Tasks complete, enrollment status G',
  },
  {
    personId: '65', firstName: 'Dipped out', lastName: 'Dylan', description: 'Near Grad, Enrollment Tasks complete, enrollment status W',
  },
  {
    personId: 'PROGRAM_CHANGE', firstName: 'Pete', lastName: 'Changeling', description: 'Program Change student, with active application, signed',
  },
];

const ScenarioIdKey = 'MyPhoenix.ScenarioId';
export { Scenarios, ScenarioIdKey };
