import STATUS from '../../globalStatuses';
import {
  REQUEST_CBE_DEADLINE,
  REQUEST_CBE_DEADLINE_ERROR,
  RECEIVE_CBE_DEADLINE,
} from './types';

function CBEData(state = { }, action) {
  switch (action.type) {
    case REQUEST_CBE_DEADLINE:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case REQUEST_CBE_DEADLINE_ERROR:
      return {
        ...state,
        error: action.error,
        status: STATUS.ERROR,
      };
    case RECEIVE_CBE_DEADLINE:
      return {
        ...state,
        ...action.data,
        status: STATUS.FETCHED,
      };
    default:
      return state;
  }
}

export default CBEData;
