import STATUS from '../../globalStatuses';
import {
  REQUEST_ACADEMIC_YEAR_DATA, RECEIVE_ACADEMIC_YEAR_DATA, REQUEST_ACADEMIC_YEAR_DATA_ERROR,
} from './types';

export default function academicYear(state = { academicYear: {} }, action) {
  switch (action.type) {
    case REQUEST_ACADEMIC_YEAR_DATA:
      return {
        ...state,
        byCode: {
          ...state.byCode,
          [action.ayCode]: {
            ...state.byCode[action.ayCode],
            status: STATUS.FETCHING,
          },
        },
      };
    case RECEIVE_ACADEMIC_YEAR_DATA:
      return {
        ...state,
        byCode: {
          ...state.byCode,
          [action.ayCode]: {
            ...state.byCode[action.ayCode],
            oid: action.academicYearData.oid,
            ayCode: action.academicYearData.code,
            previousAYOid: action.academicYearData.previousAYOid,
            startDate: action.academicYearData.startDate,
            endDate: action.academicYearData.endDate,
            firstTermCode: action.academicYearData.academicTerms[0].termCode,
            status: STATUS.FETCHED,
          },
        },
      };
    case REQUEST_ACADEMIC_YEAR_DATA_ERROR:
      return {
        ...state,
        byCode: {
          ...state.byCode,
          [action.ayCode]: {
            ...state.byCode[action.ayCode],
            error: action.error,
            status: STATUS.ERROR,
          },
        },
      };
    default:
      return state;
  }
}
