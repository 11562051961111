import STATUS from '../redux/globalStatuses';

export const RANGE = {
  DEFAULT: { min: -455, max: 180 },
  ACADEMIC_PLAN: { min: -7305, max: 1826 },
};

/**
 * Turns a range's min startdate and max startdate into an id
 *
 * @param range
 * @returns {string}
 */
export const getIdForRange = (range = RANGE.DEFAULT) => (`${range.min}|${range.max}`);

/**
 * Grabs the specified range object from state OR returns an empty range
 *
 * @param courses
 * @param range
 * @returns {*|{membershipIds: [], status: string}}
 */
export const getRangeInfoFromCourses = (
  courses,
  range = RANGE.DEFAULT,
) => (courses.byRangeId[getIdForRange(range)] || {
  allMembershipIds: [],
  currentMembershipIds: [],
  futureMembershipIds: [],
  pastMembershipIds: [],
  status: STATUS.UNFETCHED,
});

/**
 * Maps all membership ids in the given range to memberships data
 *
 * @param courses
 * @param range
 * @returns {*[]}
 */
export const getAllCoursesByRange = (
  courses,
  range = RANGE.DEFAULT,
) => {
  const rangeInfo = getRangeInfoFromCourses(courses, range);
  return rangeInfo.allMembershipIds.map((membershipId) => courses.byMembershipId[membershipId]);
};

/**
 * Maps current membership ids in the given range to memberships data
 *
 * @param courses
 * @param range
 * @returns {*[]}
 */
export const getCurrentCoursesByRange = (
  courses,
  range = RANGE.DEFAULT,
) => {
  const rangeInfo = getRangeInfoFromCourses(courses, range);
  return rangeInfo.currentMembershipIds.map((membershipId) => courses.byMembershipId[membershipId]);
};

/**
 * Maps future membership ids in the given range to memberships data
 *
 * @param courses
 * @param range
 * @returns {*[]}
 */
export const getFutureCoursesByRange = (
  courses,
  range = RANGE.DEFAULT,
) => {
  const rangeInfo = getRangeInfoFromCourses(courses, range);
  return rangeInfo.futureMembershipIds.map((membershipId) => courses.byMembershipId[membershipId]);
};

/**
 * Maps past membership ids in the given range to memberships data
 *
 * @param courses
 * @param range
 * @returns {*[]}
 */
export const getPastCoursesByRange = (
  courses,
  range = RANGE.DEFAULT,
) => {
  const rangeInfo = getRangeInfoFromCourses(courses, range);
  return rangeInfo.pastMembershipIds.map((membershipId) => courses.byMembershipId[membershipId]);
};

export const convertClassRostNumToMembershipId = (item) => `OSIRIS:${item}`;
