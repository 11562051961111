import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
    position: 'fixed',
    top: '2.7rem',
    zIndex: 1063,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.2)',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3),
      top: 0,
    },

    '@media print': {
      visibility: 'hidden',
    },
  },

  myPhoenixTitle: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  avatar: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  headerEyebrow: {
    position: 'fixed',
    top: 0,
    display: 'block',
    height: '2.7rem',
    backgroundColor: theme.palette.grays.borderLightGray,
    width: '100%',
    color: theme.palette.text.primary,

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },

    '&:hover, &:focus': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
  },

  headerEyebrowContainer: {
    paddingTop: theme.spacing(0.7),
  },

  profileInfo: {
    fontWeight: 'normal',
    fontSize: '1.2rem',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: theme.spacing(0.7),
    marginLeft: theme.spacing(0.9),
  },

  irnText: {
    fontSize: '1.1rem',
    color: theme.palette.text.disabled,
    '&:hover, &:focus': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
  },

  copyText: {
    color: theme.palette.text.primary,
    fontSize: '1rem',
    transition: 'opacity 0.5s',
    opacity: 1,
    '& .hidden': {
      opacity: 0,
    },

    '&:hover, &:focus': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
  },

  copyIcon: {
    cursor: 'pointer',
    height: '1rem',
    width: '1rem',
    transition: 'fill 0.5s',

    '&:hover, &:focus': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
  },

  hidden: {
    opacity: 0,
  },

  scroll: {
    boxShadow: '0rem 0.3rem 1.6rem 0rem rgba(0, 0, 0, 0.2)',
  },

  headerIconLabel: {
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    textDecoration: 'none',
    zIndex: 0,
  },

  emailLabel: {
    marginTop: theme.spacing(0.5),
  },

  menuLabel: {
    marginTop: theme.spacing(0.9),
  },

  headerRowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  headerRow: {
    height: '7.2rem',
    maxWidth: '106.6rem',
    [theme.breakpoints.up('md')]: {
      height: '8.2rem',
    },
  },

  logo: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(1.0),
    [theme.breakpoints.up('md')]: {
      display: 'initial',
      paddingBottom: 0,
    },

    '& svg': {
      marginRight: 0,
      display: 'flex',
      alignItems: 'flex-start',
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(0.8),
        display: 'initial',
      },
    },

    '& span': {
      fontSize: '2rem',
      fontWeight: 600,
      color: theme.palette.common.black,
      position: 'relative',
      top: '0.5rem',
    },

    '& a': {
      display: 'inline-block',

      '&:hover, &:focus': {
        textDecoration: 'none',
        '& span': {
          color: theme.palette.primary.main,
        },

        '& .icon-phoenix-logo': {
          fill: theme.palette.primary.main,
        },
      },
    },
  },

  notificationContainer: {
    height: 'inherit',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(2.2),
    '& a': {
      paddingTop: theme.spacing(0.4),
    },
  },

  emailIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(0.2),
    marginBottom: theme.spacing(1.0),
    border: 'none',
    backgroundColor: 'transparent',
  },

  emailLink: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(0.7),
  },

  emailContainer: {
    height: 'inherit',
    marginLeft: theme.spacing(2.4),
    paddingBottom: theme.spacing(0.1),
  },

  searchInputContainer: {
    flex: '1 1 auto',
  },

  menuToggle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '6rem',
  },

  menuContainer: {
    width: '4.5rem',
    height: 'inherit',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    alignSelf: 'start',
    paddingTop: theme.spacing(0.3),
    marginLeft: theme.spacing(1.8),

    '& button': {
      cursor: 'pointer',
      zIndex: 100,
      border: 'none',
      background: 'none',
    },
  },

  hamburger: {
    position: 'relative',
    alignSelf: 'center',
    padding: 0,
    width: '3.5rem',
    height: '2.3rem',

    '& span, &:before, &:after': {
      display: 'block',
      position: 'absolute',
      borderRadius: '0.3rem',
      left: 0,
      width: '100%',
      height: '0.4rem',
      background: theme.palette.common.black,
    },

    '& span': {
      top: '50%',
      transition: 'all 0.3s ease-in-out',
    },

    '&:before, &:after': {
      content: '\'\'',
      transition: 'all 0.3s ease-in-out 0.2s',
    },

    '&:before': {
      top: '0.3rem',
    },

    '&:after': {
      bottom: '-0.2rem',
    },
  },

  active: {
    '& span': {
      transition: 'all 0.3s ease-in-out 0.2s',
    },

    '& span:first-child': {
      transform: 'rotate(45deg)',
    },

    '& span:last-child': {
      transform: 'rotate(-45deg)',
    },

    '&:before, &:after': {
      opacity: (0),
      transition: 'all 0.3s ease-in-out 0.2s',
    },

    '&:before': {
      top: '50%',
    },

    '&:after': {
      bottom: '50%',
    },
  },

  navContainer: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    zIndex: 1062,
    transformOrigin: '0 0',
    transform: 'translate(0, -100%)',
    transition: 'transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1)',
    top: 0,

    '@media print': {
      visibility: 'hidden',
    },
  },

  navCenterer: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: '106.6rem',
    width: '100%',
    zIndex: 1062,

    '& nav': {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '100vh',
      width: '30rem',
      overflowY: 'scroll',
      padding: theme.spacing(12, 3, 2, 3),
      background: theme.palette.common.white,
      height: '100vh',
      boxShadow: '0 0.3rem 3.5rem 0.3rem rgba(0, 0, 0, 0.2)',
      [theme.breakpoints.up('md')]: {
        overflowY: 'auto',
        height: 'auto',
      },
    },
  },

  navCentererHidden: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: '106.6rem',
    width: '100%',
    zIndex: 1062,
    visibility: 'hidden',
    '& nav': {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '100vh',
      width: '30rem',
      overflowY: 'scroll',
      padding: theme.spacing(12, 3, 2, 3),
      background: theme.palette.common.white,
      height: '100vh',
      boxShadow: '0 0.3rem 3.5rem 0.3rem rgba(0, 0, 0, 0.2)',
      [theme.breakpoints.up('md')]: {
        overflowY: 'auto',
        height: 'auto',
      },
    },
  },

  linkListsContainer: {
    '& h2': {
      color: theme.palette.primary.main,
    },

    '& a': {
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },

  iconLinksContainer: {
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(3.5),
    '& a': {
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },

    '& .icon-link': {
      marginTop: theme.spacing(2.0),
    },
  },

  logoutLink: {
    marginTop: theme.spacing(2),
  },

  open: {
    transform: 'scale(1, 1)',
  },

  menuOpenOverlay: {
    transform: 'scale(1, 1)',
    position: 'fixed',
    top: '8.2rem',
    left: 0,
    right: 0,
    bottom: 0,
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.common.black,
      opacity: 0.3,
      visibility: 'visible',
      transition: '(all 0.5s)',
      zIndex: 1061,
    },
  },
}));
