export const injectStateIntoArguments = (extraArgumentsNeedingState, dispatch, getState) => {
  if (!extraArgumentsNeedingState || Object.keys(extraArgumentsNeedingState).length === 0) {
    return null;
  }
  const keys = Object.keys(extraArgumentsNeedingState);
  const injectedArguments = keys.reduce(
    (alreadyInjected, key) => ({
      ...alreadyInjected,
      [key]: extraArgumentsNeedingState[key](getState, dispatch),
    }),
    {},
  );
  return injectedArguments;
};

const createThunkMiddleware = ({
  withExtraArguments = {},
  withExtraArgumentsNeedingState = {},
} = {}) => ({ dispatch, getState }) => (next) => (action) => {
  if (typeof action === 'function') {
    const extraArgumentWithState = injectStateIntoArguments(
      withExtraArgumentsNeedingState,
      dispatch,
      getState,
    );
    return action(dispatch, getState, { ...withExtraArguments, ...extraArgumentWithState });
  }

  return next(action);
};

const thunk = createThunkMiddleware();
thunk.withExtraArguments = createThunkMiddleware;

export { thunk };

export default createThunkMiddleware;
