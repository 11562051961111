const anchoredNavigation = document.querySelector('.cmp-anchor-navigation');
const alteredScroll = (event, destinationSection) => {
  event.preventDefault();
  window.scroll({
    top: destinationSection.getBoundingClientRect().top + window.pageYOffset - 195,
    behavior: 'smooth',
  });
};
const adjustForAnchorNavigation = (siteStripeOn, hasSiteStripeAlert) => {
  if (anchoredNavigation) {
    if (anchoredNavigation.classList.contains('cmp-anchor-navigation--no-header')) {
      anchoredNavigation.classList.remove('cmp-anchor-navigation--no-header');
      anchoredNavigation.classList.add('cmp-anchor-navigation--myphx-no-header');
    }
    if (siteStripeOn || hasSiteStripeAlert) {
      anchoredNavigation.classList.add('hasSiteStripe');
    }
  }
};

const handleAnchorClick = () => {
  if (anchoredNavigation) {
    anchoredNavigation.addEventListener('click', (event) => {
      const destination = event.target.getAttribute('href');
      const { width } = window.screen;
      if (destination) {
        const destinationSection = document.querySelector(destination);
        if (destinationSection && !destinationSection.classList.contains('section-container--include-header')) {
          alteredScroll(event, destinationSection);
        }
        if (destinationSection && width <= 767) {
          const contentContainer = destinationSection.querySelector('.section-content-container');
          destinationSection.classList.add('show');
          contentContainer.classList.add('show');
          alteredScroll(event, destinationSection);
        }
      }
    });
  }
};

export {
  adjustForAnchorNavigation,
  handleAnchorClick,
};
