// Determines if current url contains a string
const fetchLocation = (value) => window.location.href.indexOf(value) !== -1;

/* Usage
async function functionName() {
  await waitFor(1000);
 ...
} */

const waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export {
  fetchLocation,
  waitFor,
};
