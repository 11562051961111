export default function StudentService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = `${MicroServices.Base}/student`;

  const getPLA = async (irn) => client.get(
    `${baseEndpointUrl}/v2/pla?$filter=irn eq ${irn}`,
  );

  const uploadDocument = async (irn, file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('IRN', irn);
    return client.post(
      `${baseEndpointUrl}/v2/pla/documents`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        // taking off timeout limit for uploading files for now until we get a feel for how long
        // bigger files take
        timeout: 0,
      },
    );
  };

  const deleteDocument = async (irn, fileId) => client.delete(
    `${baseEndpointUrl}/v2/pla/documents/${fileId}?IRN=${irn}`,
  );

  const submitApplication = async (irn, applicationId) => client.post(
    `${baseEndpointUrl}/v2/pla/applications/${applicationId}?IRN=${irn}`,
  );

  const getMembershipsByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v2/course/memberships?$filter=personId eq '${personId}'`,
  );

  const getMembershipsByPersonIdAndDateRange = async (personId, min, max) => client.get(
    `${baseEndpointUrl}/v2/course/memberships?$filter=personId eq '${personId}' and startDate ge ${min} and startDate le ${max}`,
  );

  return {
    getPLA,
    uploadDocument,
    deleteDocument,
    submitApplication,
    getMembershipsByPersonId,
    getMembershipsByPersonIdAndDateRange,
  };
}
