import axios from 'axios';
import { trackingIdInterceptor } from '../interceptors/trackingIdInterceptors';
import {
  invalidateAccessTokenInterceptor,
  includeAccessTokenInterceptor,
  salesforceTokenInterceptor,
  salesforceTokenResponseInterceptor,
} from '../interceptors/authorizationInterceptors';
import eTagConflictInterceptor from '../interceptors/etagInterceptors';
import retryInterceptor from '../interceptors/retryInterceptors';
import errorTrackingInterceptor from '../interceptors/errorTrackingInterceptors';

export const apiClient = (getState = {}, dispatch = {}) => {
  const client = axios.create({
    timeout: window.MyPhoenix.Config.MicroServices.Timeout,
  });
  // Request Interceptors
  client.interceptors.request.use(trackingIdInterceptor);
  client.interceptors.request.use(
    (config) => includeAccessTokenInterceptor(config, getState, dispatch),
  );
  // Response Interceptors
  client.interceptors.response.use(
    (response) => response,
    (error) => invalidateAccessTokenInterceptor(error, getState, dispatch, client),
  );
  client.interceptors.response.use(
    (response) => response,
    (error) => eTagConflictInterceptor(error, dispatch),
  );
  client.interceptors.response.use(
    (response) => response,
    (error) => retryInterceptor(error, client,
      window.MyPhoenix.Config.MicroServices.InterceptorRetryEnabled,
      window.MyPhoenix.Config.MicroServices.InterceptorRetryAmount),
  );
  client.interceptors.response.use(
    (response) => response,
    (error) => errorTrackingInterceptor(error),
  );
  return client;
};

// apiClient used for single calls and does not have any ties to redux
export const basicClient = () => {
  const client = axios.create({
    timeout: window.MyPhoenix.Config.MicroServices.Timeout,
  });
  // Request Interceptors
  client.interceptors.request.use(trackingIdInterceptor);
  // Response Interceptors
  client.interceptors.response.use(
    (response) => response,
    (error) => retryInterceptor(error, client,
      window.MyPhoenix.Config.MicroServices.InterceptorRetryEnabled,
      window.MyPhoenix.Config.MicroServices.InterceptorRetryAmount),
  );
  client.interceptors.response.use(
    (response) => response,
    (error) => errorTrackingInterceptor(error),
  );
  return client;
};

export const salesforceClient = (getState = {}, dispatch = {}) => {
  const client = axios.create({
    timeout: window.MyPhoenix.Config.MicroServices.Timeout,
  });
  // Request Interceptors
  client.interceptors.request.use(
    (config) => salesforceTokenInterceptor(config, getState, dispatch),
  );
  // Response Interceptors - call refresh endpoint if expired
  client.interceptors.response.use(
    (response) => response,
    (error) => salesforceTokenResponseInterceptor(error, getState, dispatch, client),
  );
  return client;
};
// used for non uop calls, does not include tracking headers
export const basicThirdPartyClient = () => {
  const client = axios.create({
    timeout: window.MyPhoenix.Config.MicroServices.Timeout,
  });
  // Response Interceptors
  client.interceptors.response.use(
    (response) => response,
    (error) => retryInterceptor(error, client,
      window.MyPhoenix.Config.MicroServices.InterceptorRetryEnabled,
      window.MyPhoenix.Config.MicroServices.InterceptorRetryAmount),
  );
  client.interceptors.response.use(
    (response) => response,
    (error) => errorTrackingInterceptor(error),
  );
  return client;
};
