export default function CommunicationService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = `${MicroServices.Base}/communication`;

  const isNotificationV2 = () => Boolean(
      window?.MyPhoenix?.Config?.Features?.NotificationsV2Enabled || false,
  );

  const getNotificationsByPersonId = async (personId) => {
    let version = 'v1';
    if (isNotificationV2()) version = 'v2';
    return client.get(
      `${baseEndpointUrl}/${version}/notifications/${personId}`,
    );
  };

  const getAlertsByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/alerts/${personId}`,
  );

  const putNotificationIdAsReadOrUnReadByPersonID = async (
    personId, notificationId, markRead = true) => {
    let requestBody = { states: { status: { action: 'COMPLETED' } } };
    let version = 'v1';
    if (!markRead) {
      requestBody = { states: { status: { action: 'ASSIGNED' } } };
    }
    if (isNotificationV2()) {
      version = 'v2';
      requestBody = { read: markRead, ...requestBody };
    }
    return client.put(
      `${baseEndpointUrl}/${version}/notifications/${personId}/${notificationId}`,
      requestBody,
    );
  };

  return {
    getNotificationsByPersonId,
    getAlertsByPersonId,
    putNotificationIdAsReadOrUnReadByPersonID,
  };
}
