import {
  REQUEST_APPLICATION_WORKFLOW, RECEIVE_APPLICATION_WORKFLOW, REQUEST_APPLICATION_WORKFLOW_ERROR,
  REQUEST_APPLICATION_PROGRAM, RECEIVE_APPLICATION_PROGRAM, REQUEST_APPLICATION_PROGRAM_ERROR,
  REQUEST_APPLICATION_INFO, REQUEST_APPLICATION_INFO_ERROR,
} from './types';
import { errorToMessage } from '../../../utils/error-functions';
import STATUS from '../../globalStatuses';

function requestApplicationWorkflow() {
  return { type: REQUEST_APPLICATION_WORKFLOW };
}

function receiveApplicationWorkflow(workflows) {
  return { type: RECEIVE_APPLICATION_WORKFLOW, workflows, receivedAt: Date.now() };
}

function requestApplicationWorkflowError(error) {
  return { type: REQUEST_APPLICATION_WORKFLOW_ERROR, error, receivedAt: Date.now() };
}

function requestApplicationInfo() {
  return { type: REQUEST_APPLICATION_INFO };
}

function requestApplicationInfoError(error) {
  return { type: REQUEST_APPLICATION_INFO_ERROR, error, receivedAt: Date.now() };
}

function requestApplicationProgram() {
  return { type: REQUEST_APPLICATION_PROGRAM };
}

function receiveApplicationProgram(programDetails, programOfferingId) {
  return {
    type: RECEIVE_APPLICATION_PROGRAM,
    programDetails,
    programOfferingId,
    receivedAt: Date.now(),
  };
}

function requestApplicationProgramError(error) {
  return { type: REQUEST_APPLICATION_PROGRAM_ERROR, error, receivedAt: Date.now() };
}

function fetchApplicationWorkflow() {
  return async (dispatch, getState, { Services }) => {
    dispatch(requestApplicationWorkflow());
    const { auth } = getState();
    return Services.SagasService.getApplicationWorkflowByPersondId(auth.personId)
      .then((response) => dispatch(receiveApplicationWorkflow(response.data)))
      .catch((error) => dispatch(requestApplicationWorkflowError(errorToMessage(error))));
  };
}

export function shouldFetchApplicationWorkflow(state) {
  const { enrollmentApplication } = state;
  return enrollmentApplication.status !== STATUS.FETCHED
    && enrollmentApplication.status !== STATUS.FETCHING;
}

export function fetchApplicationWorkflowIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchApplicationWorkflow(getState())) {
      return dispatch(fetchApplicationWorkflow());
    }
    return Promise.resolve();
  };
}

function fetchApplicationProgramDetails(programOfferingId) {
  return async (dispatch, getState, { Services }) => {
    dispatch(requestApplicationProgram());
    return Services.ProgramsService
      .getProgramInfoByProgramOfferingId(programOfferingId)
      .then((response) => {
        dispatch(receiveApplicationProgram(response.data, programOfferingId));
      })
      .catch((error) => dispatch(requestApplicationProgramError(errorToMessage(error))));
  };
}

function fetchApplicationProgramInfo() {
  return async (dispatch, getState, { Services }) => {
    const { enrollmentApplication } = getState();
    dispatch(requestApplicationInfo());
    return Services.ApplicationService
      .getApplicationInfoByApplicationId(enrollmentApplication.applicationId)
      .then(async (applicationResponse) => {
        const programOfferingId = (applicationResponse.data
            && applicationResponse.data.programOfferingId)
          ? applicationResponse.data.programOfferingId : null;
        if (programOfferingId) {
          dispatch(fetchApplicationProgramDetails(programOfferingId));
        }
      })
      .catch((error) => dispatch(requestApplicationInfoError(errorToMessage(error))));
  };
}

export function shouldFetchApplicationProgramInfo(state) {
  const { enrollmentApplication } = state;
  return enrollmentApplication.applicationId !== null
      && enrollmentApplication.status !== STATUS.FETCHING
      && enrollmentApplication.status === STATUS.FETCHED
      && enrollmentApplication.programInfo.status !== STATUS.FETCHED
      && enrollmentApplication.programInfo.status !== STATUS.FETCHING;
}

export function fetchApplicationProgramIfNeeded() {
  return async (dispatch, getState) => {
    if (shouldFetchApplicationProgramInfo(getState())) {
      return dispatch(fetchApplicationProgramInfo());
    }
    return Promise.resolve();
  };
}
