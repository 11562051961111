import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Icon from '../../icon/v1/Icon';
import useStyles from './SectionLink.styles';
import { getClassroomClassUrl, getClassroomKey } from '../../../utils/classroom-functions';
import theme from '../../../theme';
import { componentNameToMyPhxKebabFormat } from '../../../utils/stringUtils';
import { createEventObject } from '../../../utils/event-functions';

const getCondensedSectionLink = (classes, section, componentName, url) => {
  const { sectionId } = section;
  return (
    <>
      <Link
        className={`${classes.templateLink} ${classes.templateCode}`}
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        data-raise-event-on-click={createEventObject(
          `${componentName}_Link`,
          [{ url }, { sectionId }],
        )}
      >
        {section.templateCode}
      </Link>
      <Typography variant="body1" className={classes.sectionTitle}>
        {section.title}
      </Typography>
    </>
  );
};

const getNormalSectionLink = (classes, section, componentName, url) => (
  <div className={classes.sectionDetails}>
    <Typography className={classes.sectionTitle} variant="h3">{ section.title }</Typography>
    <Typography className={`${classes.templateCode}`} variant="body1">{ section.templateCode }</Typography>
    <Link
      className={classes.sectionLink}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      data-raise-event-on-click={createEventObject(
        `${componentName}_Link`,
        [{ url }, { text: section.title },
          { templateCode: section.templateCode }, { sectionId: section.sectionId }],
      )}
    >
      <span className={classes.goToSectionText}>Go to class</span>
      <Icon icon="icon-long-arrow" color={theme.palette.primary.main} />
    </Link>
  </div>
);

const getCBEDASectionLink = (classes, section, componentName, isLastLink, url) => (
  <div className={classes.sectionDetails}>
    <Typography className={classes.cbeDASectionTitle} variant="h3">{ section.title }</Typography>
    <Typography className={`${classes.cbeDATemplateCode}`} variant="body1">{ section.templateCode }</Typography>
    {isLastLink && (
    <Link
      className={classes.sectionLink}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      data-raise-event-on-click={createEventObject(
        `${componentName}_Link`,
        [{ url }, { text: section.title },
          { templateCode: section.templateCode }, { sectionId: section.sectionId }],
      )}
    >
      <span className={classes.goToSectionText}>Go to class</span>
      <Icon icon="icon-long-arrow" color={theme.palette.primary.main} />
    </Link>
    )}
  </div>
);

export default function SectionLink(props) {
  const {
    condensed, section, parentComponentName, isCBEDA, isLastLink,
  } = props;
  const componentName = 'SectionLink';
  const classes = useStyles({ condensed });
  const { sectionId } = section;
  const url = getClassroomClassUrl(getClassroomKey({ isCBEDA }), { sectionId });
  return (
    <div className={classes.root} data-component={componentNameToMyPhxKebabFormat(`${parentComponentName}${componentName}`)}>
      {!isCBEDA && (
        <Icon
          data-testid="myphx-section-link-book-icon"
          className={classes.sectionIcon}
          icon="icon-note-book"
          color={theme.palette.primary.main}
        />
      )}
      {!isCBEDA && condensed && getCondensedSectionLink(classes, section, `${parentComponentName}_${componentName}`, url)}
      {!isCBEDA && !condensed && getNormalSectionLink(classes, section, `${parentComponentName}_${componentName}`, url)}
      {isCBEDA && getCBEDASectionLink(classes, section, `${parentComponentName}_${componentName}`, isLastLink, url)}
    </div>
  );
}

SectionLink.propTypes = {
  parentComponentName: PropTypes.string,
  condensed: PropTypes.bool,
  section: PropTypes.objectOf(PropTypes.any).isRequired,
  isCBEDA: PropTypes.bool,
  isLastLink: PropTypes.bool,
};

SectionLink.defaultProps = {
  parentComponentName: '',
  condensed: false,
  isCBEDA: false,
  isLastLink: false,
};
