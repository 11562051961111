export default function CoursesService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = `${MicroServices.Base}/courses`;
  const getCurriculum = async (courseCode) => client.get(
    `${baseEndpointUrl}/v1/templates/curriculum?courseCode=${courseCode}`,
  );
  return {
    getCurriculum,
  };
}
