import { connect } from 'react-redux';
import fetchAlertsIfNeeded from '../domains/alerts/actions';
import Header from '../../components/header/v1/Header';
import fetchUserDefinedAttributesIfNeeded from '../domains/userDefinedAttributes/actions';
import fetchCurrentUserIfNeeded from '../domains/currentUser/actions';
import fetchSiteStripeIfNeeded from '../domains/siteStripe/actions';

const mapStateToProps = (state) => ({
  statusAuth: state.auth.status,
  statusAlerts: state.alerts.status,
  siteStripe: state.siteStripe,
  siteStripeAlerts: state.alerts.systemAlerts
    ? state.alerts.systemAlerts.filter((object) => object.category === 'SYSTEM') : [],
  statusUserDefinedAttributes: state.userDefinedAttributes.status,
  userDefinedAttributes: state.userDefinedAttributes.attributeValue || [],
  firstName: state.currentUser.firstName,
  lastName: state.currentUser.lastName,
  issuerId: state.currentUser.issuerId,
});
const mapDispatchToProps = (dispatch) => ({
  loadAlerts: () => {
    dispatch(fetchAlertsIfNeeded());
  },
  loadProfile: () => {
    dispatch(fetchCurrentUserIfNeeded());
  },
  loadUserDefinedAttributes: () => {
    dispatch(fetchUserDefinedAttributesIfNeeded());
  },
  loadSiteStripe: () => {
    dispatch(fetchSiteStripeIfNeeded());
  },
});

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);

export default HeaderContainer;
