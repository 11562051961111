import STATUS from '../../globalStatuses';
import {
  REQUEST_COURSE_OUTCOMES, RECEIVE_COURSE_OUTCOMES, REQUEST_COURSE_OUTCOMES_ERROR,
} from './types';
import { convertClassRostNumToMembershipId } from '../../../utils/course-functions';

export default function courseOutcomes(state = { courseOutcomes: {} }, action) {
  switch (action.type) {
    case REQUEST_COURSE_OUTCOMES:
      return {
        ...state,
        byProgramId: {
          ...state.byProgramId,
          [action.programId]: {
            ...state.byProgramId[action.programId],
            programId: action.programId,
            status: STATUS.FETCHING,
          },
        },
      };
    case RECEIVE_COURSE_OUTCOMES:
      return {
        ...state,
        byProgramId: {
          ...state.byProgramId,
          [action.programId]: {
            [action.membershipId]: {
              membershipId: action.membershipId,
              courseTitle: action.courseOutcomeData.courseTitle,
              templateCode: action.courseOutcomeData.courseId,
              sectionId:
                convertClassRostNumToMembershipId(action.courseOutcomeData.courseOfferingId),
              deadDate: action.courseOutcomeData.deadDate,
              groupId: action.courseOutcomeData.groupId,
              offeringStartDate: action.courseOutcomeData.offeringStartDate,
              offeringEndDate: action.courseOutcomeData.offeringEndDate,
              qualificationPoints: action.courseOutcomeData.qualificationPoints,
            },
            ...state.byProgramId[action.programId],
            programId: action.programId,
            status: STATUS.FETCHED,
            receivedAt: action.receivedAt,
          },
        },
      };
    case REQUEST_COURSE_OUTCOMES_ERROR:
      return {
        ...state,
        byProgramId: {
          ...state.byProgramId,
          [action.programId]: {
            ...state.byProgramId[action.programId],
            programId: action.programId,
            status: STATUS.ERROR,
          },
        },
      };
    default:
      return state;
  }
}
