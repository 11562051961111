import STATUS from '../../globalStatuses';
import {
  REQUEST_LOA_DATA,
  RECEIVE_LOA_DATA,
  RECEIVE_EMPTY_LOA_DATA,
  REQUEST_LOA_DATA_ERROR,
} from './types';

export default function leaveOfAbsenceData(state = { leaveOfAbsenceData: {} }, action) {
  switch (action.type) {
    case REQUEST_LOA_DATA:
      return { ...state, receivedAt: action.receivedAt, status: STATUS.FETCHING };
    case RECEIVE_LOA_DATA: {
      return {
        ...state,
        receivedAt: action.receivedAt,
        requestDate: action.leaveOfAbsenceData.requestDate,
        startDate: action.leaveOfAbsenceData.startDate,
        endDate: action.leaveOfAbsenceData.endDate,
        statusCode: action.leaveOfAbsenceData.statusCode,
        statusCodeDescription: action.leaveOfAbsenceData.status,
        status: STATUS.FETCHED,
      };
    }
    case RECEIVE_EMPTY_LOA_DATA: {
      return {
        ...state,
        receivedAt: action.receivedAt,
        status: STATUS.FETCHED,
      };
    }
    case REQUEST_LOA_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        receivedAt: action.receivedAt,
        status: STATUS.ERROR,

      };
    default:
      return state;
  }
}
