import STATUS from '../../globalStatuses';
import {
  REQUEST_ALERTS, RECEIVE_ALERTS, REQUEST_ALERTS_ERROR,
  INVALIDATE_ALERTS, REQUEST_NOTIFICATIONS, RECEIVE_NOTIFICATIONS, REQUEST_NOTIFICATIONS_ERROR,
  RECEIVE_MARK_NOTIFICATION_AS_READ_OR_UNREAD,
  REQUEST_MARK_NOTIFICATION_AS_READ_OR_UNREAD,
  REQUEST_MARK_NOTIFICATION_AS_READ_OR_UNREAD_ERROR,
} from './types';

export default function alerts(state = { alerts: {} }, action) {
  switch (action.type) {
    case REQUEST_ALERTS:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case REQUEST_NOTIFICATIONS:
      return {
        ...state,
        notificationsStatus: STATUS.FETCHING,
      };
    case RECEIVE_ALERTS: {
      const systemAlerts = action.alerts.alerts;
      return {
        ...state,
        systemAlerts,
        status: STATUS.FETCHED,
      };
    }
    case RECEIVE_NOTIFICATIONS: {
      const notifications = action.notifications.notifications.filter((alts) => alts.notificationSubType !== 'LOGONEVENT'
          && alts.contexts.some((context) => context.endsWith('home/alerts')));

      const logonEvents = action.notifications.notifications.filter((alts) => alts.notificationSubType === 'LOGONEVENT'
        && alts.contexts.some((context) => context.endsWith('home/alerts')));

      return {
        ...state,
        notifications,
        logonEvents,
        notificationsStatus: STATUS.FETCHED,
      };
    }

    case REQUEST_MARK_NOTIFICATION_AS_READ_OR_UNREAD:
      return {
        ...state,
        notificationsStatus: STATUS.FETCHING,
      };

    case REQUEST_MARK_NOTIFICATION_AS_READ_OR_UNREAD_ERROR:
      return {
        ...state,
        notificationsStatus: STATUS.ERROR,
        error: action.error,
      };

    case RECEIVE_MARK_NOTIFICATION_AS_READ_OR_UNREAD: {
      const updatedNotifications = [];
      if (state.notifications) {
        state.notifications.forEach((alts) => {
          if (alts.id === action.notificationId) {
            updatedNotifications.push({
              ...alts,
              read: true,
            });
          } else {
            updatedNotifications.push(alts);
          }
          return alts;
        });
      }

      return {
        ...state,
        notifications: updatedNotifications,
        notificationsStatus: STATUS.FETCHED,
      };
    }

    case REQUEST_ALERTS_ERROR:
      return {
        ...state,
        status: STATUS.ERROR,
        error: action.error,
      };
    case REQUEST_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notificationsStatus: STATUS.ERROR,
        error: action.error,
      };
    case INVALIDATE_ALERTS:
      return {
        ...state,
        status: STATUS.INVALIDATED,
        notificationsStatus: STATUS.INVALIDATED,
      };
    default:
      return state;
  }
}
