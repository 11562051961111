export const PAGE_LOAD_INDEX = 'myphx-page-load-index';

export const getPageLoadIndex = () => sessionStorage.getItem(PAGE_LOAD_INDEX);

// count the number of page loads
export const setPageLoadIndex = () => {
  const currentIndex = getPageLoadIndex();
  if (!currentIndex) {
    sessionStorage.setItem(PAGE_LOAD_INDEX, '1');
  }
  const newIndex = !Number.isNaN(currentIndex) ? Number(currentIndex) + 1 : 1;
  sessionStorage.setItem(PAGE_LOAD_INDEX, `${newIndex}`);
};
