export default function SagasService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = `${MicroServices.Base}/sagas`;
  const config = {
    /**
     * Rajani says this header is necessary for all sagas calls to improve performance
     * by routing the calls, to an alternate 'blue' farm >.>
     */
    headers: {
      'out-of-the-blue': 1,
    },
  };

  const getTasksByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/persontasks/${personId}?$filter=context eq '/tenantid:831D8B107020BBEBE0400F0A32207789/taxonomy:tasks/contexts/home'`,
    config,
  );

  const getApplicationWorkflowByPersondId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/workflow/ADMISSION/person/${personId}?includeTasks=true&includeClosed=true`,
    config,
  );

  const updateTask = async (personId, taskId, payload) => client.put(
    `${baseEndpointUrl}/v1/persontask/${personId}/${taskId}`,
    payload,
    config,
  );

  return {
    getTasksByPersonId,
    getApplicationWorkflowByPersondId,
    updateTask,
  };
}
