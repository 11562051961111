import {
  REQUEST_SERVICE_TYPE,
  REQUEST_SERVICE_TYPE_ERROR,
  RECEIVE_SERVICE_TYPE,
} from './types';
import STATUS from '../../globalStatuses';

function militaryStatus(state = { }, action) {
  switch (action.type) {
    case REQUEST_SERVICE_TYPE:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case REQUEST_SERVICE_TYPE_ERROR:
      return {
        ...state,
        error: action.error,
        status: STATUS.ERROR,
      };
    case RECEIVE_SERVICE_TYPE:
      return {
        ...state,
        serviceType: action.data,
        status: STATUS.FETCHED,
      };
    default:
      return state;
  }
}

export default militaryStatus;
