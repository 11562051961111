/* get emails */
export const REQUEST_EMAILS = 'REQUEST_EMAILS';
export const RECEIVE_EMAILS = 'RECEIVE_EMAILS';
export const REQUEST_EMAILS_ERROR = 'REQUEST_EMAILS_ERROR';
/* post email */
export const REQUEST_POST_EMAIL = 'REQUEST_POST_EMAIL';
export const RECEIVE_POST_EMAIL = 'RECEIVE_POST_EMAIL';
export const REQUEST_POST_EMAIL_ERROR = 'REQUEST_POST_EMAIL_ERROR';
/* PUT email */
export const REQUEST_UPDATE_EMAIL = 'REQUEST_UPDATE_EMAIL';
export const RECEIVE_UPDATE_EMAIL = 'RECEIVE_UPDATE_EMAIL';
export const REQUEST_UPDATE_EMAIL_ERROR = 'REQUEST_UPDATE_EMAIL_ERROR';
/* delete email */
export const REQUEST_DELETE_EMAIL = 'REQUEST_DELETE_EMAIL';
export const RECEIVE_DELETE_EMAIL = 'RECEIVE_DELETE_EMAIL';
export const REQUEST_DELETE_EMAIL_ERROR = 'REQUEST_DELETE_EMAIL_ERROR';
