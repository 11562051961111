export default function BuilderService(client) {
    const  { Builder } = window?.MyPhoenix?.Config;
    const { Base, PUBLIC_API_KEY} = Builder;
    const baseEndpointUrl = `${Builder.Base}`;
  
    const getContentByModelName = async (modelName) => client.get(
      `${baseEndpointUrl}/${modelName}?apiKey=${PUBLIC_API_KEY}`,
    );
  
    return {
      getContentByModelName,
    };
  }
  