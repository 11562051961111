import STATUS from './globalStatuses';

export default {
  activities: {
    bySectionId: {},
  },
  addresses: {
    byId: {},
    allIds: [],
    status: STATUS.UNFETCHED,
  },
  alerts: {
    systemAlerts: [],
    notifications: [],
    messages: [],
    logonEvents: [],
    notificationsStatus: STATUS.UNFETCHED,
    status: STATUS.UNFETCHED,
  },
  applicationState: {
    selectedMembershipId: '',
    selectedProgramId: '',
    loaSelected: false,
    socialModalOpen: null,
    notificationAlert: null,
  },
  attendances: {
    byMembershipId: {},
  },
  auth: {
    personId: '',
    request: Promise.resolve(),
    token: '',
    email: '',
    serverSideReceivedAt: '',
    clientSideReceivedAt: '',
    status: STATUS.UNFETCHED,
  },
  courseCurriculum: {
    byCourseId: {},
  },
  courseInstructor: {
    byMembershipId: {
    },
  },
  courses: {
    byMembershipId: {},
    byRangeId: {},
  },
  currentUser: {
    id: '',
    issuerId: '',
    profileId: '',
    userName: '',
    firstName: '',
    lastName: '',
    preferredName: '',
    universityEmail: '',
    avatarContentType: '',
    encodedAvatar: '',
    salesForceId: '',
    status: STATUS.UNFETCHED,
    avatarStatus: STATUS.UNFETCHED,
  },
  demographics: {
    dateOfBirth: '',
    status: STATUS.UNFETCHED,
  },
  emails: {
    byId: {},
    allIds: [],
    primaryOrFirstId: null,
    status: STATUS.UNFETCHED,
  },
  enrollmentApplication: {
    applicationId: null,
    applicationStartTime: null,
    applicationType: null,
    programOfferingId: null,
    programInfo: {
      programId: null,
      programName: null,
      status: STATUS.UNFETCHED,
    },
    submissionStatus: null,
    submittedAt: null,
    reviewStatus: null,
    version: null,
    status: STATUS.UNFETCHED,
  },
  feedback: {
    emailConsent: '',
    feedbackCategory: '',
    selectedEmojiValue: '',
    feedbackReason: '',
  },
  salesforce: {
    salesforceTokenValue: '',
    salesforceTokenStatus: STATUS.UNFETCHED,
  },
  leaveOfAbsenceData: {
    requestDate: '',
    startDate: '',
    endDate: '',
    statusCode: '',
    statusCodeDescription: '',
    status: STATUS.UNFETCHED,
  },
  phones: {
    byId: {},
    allIds: [],
    primaryOrFirstId: null,
    status: STATUS.UNFETCHED,
  },
  pla: {
    applications: [],
    status: STATUS.UNFETCHED,
  },
  programContacts: {
    byProgramId: {
    },
  },
  programProgress: {
    byId: {},
    status: STATUS.UNFETCHED,
  },
  programs: {
    byId: {},
    allIds: [],
    primaryProgramId: '',
    status: STATUS.UNFETCHED,
  },
  skills: {
    byCourseId: {},
    byProgramId: {},
    status: STATUS.UNFETCHED,
  },
  social: {
    linkedinStateValue: '',
    linkedin_redirect: '',
    linkedin_token: '',
    linkedinTokenExpires: 0,
    linkedinTokenStatus: STATUS.UNFETCHED,
    chosenPlatform: '',
  },
  student: {
    statuses: [],
    status: STATUS.UNFETCHED,
  },
  tasks: {
    completedTasks: [],
    assignedTasks: [],
    enrollmentTasks: [],
    status: STATUS.UNFETCHED,
  },
  transferCreditsData: {
    byProgramId: {},
    status: STATUS.UNFETCHED,
  },
  userDefinedAttributes: {
    attributeValue: '',
    status: STATUS.UNFETCHED,
  },
  courseOutcomes: {
    byProgramId: {
    },
  },
  militaryStatus: {
    serviceType: '',
    status: STATUS.UNFETCHED,
  },
  CBEData: {
    lastARADate: '',
    estimatedDropDate: '',
    status: STATUS.UNFETCHED,
  },
  acmtSkills: {
    byTemplateId: [],
  },
  skillsBasedPrograms: {
    programs: [],
    status: STATUS.UNFETCHED,
  },
  academicTerms: {
    byCode: {
    },
    allCodes: [],
  },
  academicYear: {
    byCode: {},
  },
  siteStripe: {
    ctaUrl: '',
    ctaText: '',
    displayFlag: false,
    message: '',
    target: '',
    status: STATUS.UNFETCHED,
  },
  events: {
    alertCount: 0,
    loginCount: 0,
    messageCount: 0,
    status: STATUS.UNFETCHED,
  },
};
