import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './IconTile.scss';
import Icon from '../../icon/v1/Icon';
import theme from '../../../theme';
import { createEventObject, useRaiseEventOnClick } from '../../../utils/event-functions';

/**
 * Icon Link React Component
 */
function IconTile(props) {
  const {
    styleType, target, url, icon, text, parentComponent,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const componentName = `${parentComponent}_IconTile`;
  const linkRef = useRef(null);
  useRaiseEventOnClick([linkRef]);
  const iconColor = styleType === 'white'
    ? theme.palette.common.white : theme.palette.grays.almostBlack;

  return (
    <div className={`icon-tile ${styleType}`}>
      <a
        href={url}
        target={target}
        ref={linkRef}
        data-raise-event-on-click={createEventObject(
          componentName,
          [{ url }, { target }, { text }, { styleType }],
        )}
      >
        <Icon icon={icon} color={iconColor} />
        <div className="arrow-container">
          <span>{text}</span>
          <Icon className={styleType} color={iconColor} icon="icon-right-arrow" />
        </div>
      </a>
    </div>
  );
}

IconTile.defaultProps = {
  target: '_blank',
  styleType: 'default',
  parentComponent: '',
};

IconTile.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  styleType: PropTypes.string,
  parentComponent: PropTypes.string,
};

export default IconTile;
