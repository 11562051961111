import resolveCBEPostData from '../../../../myphoenix/utils/cbe-postdata';
import resolveESTGraduationPostData from '../../../../myphoenix/utils/estimate-date-postdata';

export default function StudentService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = `${MicroServices.Base}/student`;
  const getStatusesByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/statuses?$filter=personId eq '${personId}'`,
  );
  const getPrimaryProgramByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/program/membershipsdetails?$filter=personId eq '${personId}' and primary eq true`,
  );
  const getAllProgramsByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/program/membershipsdetails?$filter=personId eq '${personId}'`,
  );
  const getStudentLOAdataByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/leaveofabsence?$filter=personId eq ${personId}`,
  );
  const getProgramProgressDataByProgramId = async (programId) => client.get(
    `${baseEndpointUrl}/v1/program/detailprogression?$filter=studentProgramId eq ${programId}`,
  );
  const getPLAByIRN = async (irn) => client.get(
    `${baseEndpointUrl}/v2/pla?$filter=irn eq ${irn}`,
  );
  const getCourseOutcomes = async (programId) => client.get(
    `${baseEndpointUrl}/v1/course/memberships/outcome?csph=${programId}`,
  );
  const getCBEDropDate = async (persondId, programCode, programVersion) => client.post(
    `${baseEndpointUrl}/v1/rules/stateless/cbeAnticipatedDropDate`,
    resolveCBEPostData(persondId, programCode, programVersion),
  );
  const getESTGraduationDate = async (requestBody) => client.post(
    `${baseEndpointUrl}/v1/stateless/ruleset/estimatedGraduationDate`,
    resolveESTGraduationPostData(requestBody),
  );

  const getAcademicTermsByTermCode = async (termCode) => client.get(
    `${baseEndpointUrl}/v1/academic/terms?code=${termCode}`,
  );
  const getOidByAyCode = async (ayCode) => client.get(
    `${baseEndpointUrl}/v1/academic/years?code=${ayCode}`,
  );

  const getAcademicYearDataByOid = async (oid) => client.get(
    `${baseEndpointUrl}/v1/academic/yearterms?ayOid=${oid}`,
  );
  const getStudentTransferCreditsDataByPersonId = async (personId) => client.get(
    `${baseEndpointUrl}/v1/course/transferCreditDetails?personId=${personId}`,
  );
  return {
    getStatusesByPersonId,
    getPrimaryProgramByPersonId,
    getAllProgramsByPersonId,
    getStudentLOAdataByPersonId,
    getProgramProgressDataByProgramId,
    getPLAByIRN,
    getCourseOutcomes,
    getCBEDropDate,
    getESTGraduationDate,
    getAcademicTermsByTermCode,
    getOidByAyCode,
    getAcademicYearDataByOid,
    getStudentTransferCreditsDataByPersonId,
  };
}
