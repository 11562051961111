export default function DocumentService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = `${MicroServices.Base}/document`;

  /**
   * Returns submitted application as HTML/JS/CSS.
   *
   * @returns Promise<*>
   */
  const getSubmittedApplicationHTML = async (applicationId, packetName) => client.get(
    `${baseEndpointUrl}/v1/formOrPacket?applicationId=${applicationId}&formOrPacketName=${packetName ?? 'BECOME_POST_SIGN_PACKET'}`,
  );

  return {
    getSubmittedApplicationHTML,
  };
}
