import React from 'react';
import { LinearProgress, Fade } from '@material-ui/core';

const LazyProgress = () => (
  <Fade in style={{ transitionDelay: '500ms' }} unmountOnExit>
    <LinearProgress />
  </Fade>
);

export default LazyProgress;
