export default function SkillsBasedProgramsService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = MicroServices.Base.includes('localhost:3000')
    ? `${MicroServices.Base}`
    : '';

  const getAllSkillsBasedPrograms = async () => client.get(
    `${baseEndpointUrl}/myphx-static-assets/myphoenix/json/skillsBasedPrograms.json`,
  );

  return {
    getAllSkillsBasedPrograms,
  };
}
