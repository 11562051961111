import STATUS from '../../globalStatuses';
import {
  REQUEST_CURRENT_USER, RECEIVE_CURRENT_USER, REQUEST_CURRENT_USER_ERROR,
  RECEIVE_AVATAR, REQUEST_AVATAR_ERROR, REQUEST_AVATAR,
} from './types';
import setCookie from '../../../utils/cookie-functions';

function currentUser(state = { currentUser: {} }, action) {
  switch (action.type) {
    case REQUEST_CURRENT_USER:
      return {
        ...state,
        receivedAt: action.receivedAt,
        status: STATUS.FETCHING,
      };
    case RECEIVE_CURRENT_USER: {
      let officialNames = action.names.filter((nameObject) => (nameObject.type.toLowerCase() === 'official'));
      if (!officialNames.length) {
        // fallback in case there is no name object with type official
        officialNames = action.names;
      }
      const profileId = action.person.externalSystemIds
        && action.person.externalSystemIds.PROFILE_ID
        ? action.person.externalSystemIds.PROFILE_ID[0]
        : '';
      const salesForceId = action.person.externalSystemIds
      && action.person.externalSystemIds.SALESFORCE_ID
        ? action.person.externalSystemIds.SALESFORCE_ID[0]
        : '';
      const universityEmail = action.person.externalSystemIds
      && action.person.externalSystemIds.UNIVERSITY_EMAIL
        ? action.person.externalSystemIds.UNIVERSITY_EMAIL[0]
        : '';
      window.MyPhoenix.User.profileId = profileId;
      window.MyPhoenix.User.issuerId = action.person.issuerId;
      window.MyPhoenix.User.userName = action.person.username;
      window.MyPhoenix.User.firstName = officialNames[0].firstName;
      window.MyPhoenix.User.lastName = officialNames[0].lastName;
      window.MyPhoenix.User.type = action.person.type;
      if (window.MyPhoenix.Config.Features.ContactUsCookieEnabled) {
        setCookie('ContactUs', universityEmail);
      }
      return {
        ...state,
        id: action.person.id,
        issuerId: action.person.issuerId || '',
        profileId,
        salesForceId,
        userName: action.person.username || '',
        firstName: officialNames[0].firstName,
        lastName: officialNames[0].lastName,
        preferredName: officialNames[0].preferredName,
        universityEmail,
        type: action.person.type,
        receivedAt: action.receivedAt,
        status: STATUS.FETCHED,
      };
    }
    case REQUEST_CURRENT_USER_ERROR:
      return {
        ...state,
        error: action.error,
        receivedAt: action.receivedAt,
        status: STATUS.ERROR,
      };
    case REQUEST_AVATAR:
      return {
        ...state,
        avatarStatus: STATUS.FETCHING,
      };
    case RECEIVE_AVATAR:
      return {
        ...state,
        avatarContentType: action.avatar.imageContentType,
        encodedAvatar: action.avatar.encodedImageBytes,
        avatarStatus: STATUS.FETCHED,
      };
    case REQUEST_AVATAR_ERROR:
      return {
        ...state,
        avatarStatus: STATUS.ERROR,
        error: action.error,
      };
    default:
      return state;
  }
}

export default currentUser;
