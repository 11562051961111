import axios from 'axios';
import STATUS from '../../myphoenix/redux/globalStatuses';
import { fetchTokenIfNeeded, invalidateTokenIfNeeded } from '../../myphoenix/redux/domains/auth/actions';
import { fetchSalesForceTokenIfNeeded, invalidateSFTokenIfNeeded } from '../../myphoenix/redux/domains/salesforce/actions';

export const includeAccessTokenInterceptor = async (config, getState, dispatch) => {
  const configUpdate = config;
  await dispatch(fetchTokenIfNeeded());
  const { auth: { status, token } } = getState();
  if (status !== STATUS.FETCHED || !token) {
    throw new axios.Cancel('Failed to retrieve token.');
  }
  configUpdate.headers.Authorization = `Bearer ${token}`;
  return configUpdate;
};

export const invalidateAccessTokenInterceptor = (error, getState, dispatch, client) => {
  if (error.config && error.response && error.response.status === 401) {
    dispatch(invalidateTokenIfNeeded());
    return dispatch(fetchTokenIfNeeded()).then(() => {
      const { auth: { status } } = getState();
      if (status === STATUS.FETCHED) {
        return client.request(error.config);
      }
      return Promise.reject(error);
    });
  }
  return Promise.reject(error);
};

export const salesforceTokenInterceptor = async (config, getState, dispatch) => {
  const configUpdate = config;
  await dispatch(fetchSalesForceTokenIfNeeded());
  const { salesforce: { salesforceTokenStatus, salesforceTokenValue } } = getState();

  if (salesforceTokenStatus !== STATUS.FETCHED || !salesforceTokenValue) {
    throw new axios.Cancel('Failed to retrieve token.');
  }

  configUpdate.headers.Authorization = `OAuth ${salesforceTokenValue}`;

  return configUpdate;
};

export const salesforceTokenResponseInterceptor = async (error, getState, dispatch, client) => {
  if (error.message.indexOf('Failed to retrieve token.') === -1 && (!error.response || error.response.status === 401)) {
    dispatch(invalidateSFTokenIfNeeded());
    return dispatch(fetchSalesForceTokenIfNeeded(true)).then(() => {
      const { salesforce: { salesforceTokenStatus } } = getState();

      if (salesforceTokenStatus === STATUS.FETCHED) {
        return client.request(error.config);
      }
      return Promise.reject(error);
    });
  }
  return Promise.reject(error);
};
