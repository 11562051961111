import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import theme from '../../../theme';
import Icon from '../../icon/v1/Icon';
import styles from './EmojiButtons.styles';

const EmojiButtons = ({
  listItems,
  setSelectedValue,
  selected,
}) => {
  const classes = styles();
  const iconList = [
    {
      name: 'Hate',
      iconName: 'icon-strongly-disagree-red',
    },
    {
      name: 'Dislike',
      iconName: 'icon-disagree-red',
    },
    {
      name: 'Neutral',
      iconName: 'icon-not-sure-red',
    },
    {
      name: 'Like',
      iconName: 'icon-agree-red',
    },
    {
      name: 'Love',
      iconName: 'icon-strongly-agree-red',
    },
  ];

  let updatedList = iconList;
  if (listItems && listItems.length > 0) {
    updatedList = listItems;
  }

  return (
    <Box className={classes.root}>
      {updatedList.map((list) => (
        <Box key={list.name} data-testid="emoji-box">
          <Button
            data-testid="emoji-box-button"
            className={`${classes.iconButton} ${list.name === selected ? classes.iconButtonSelected : ''}`}
            classes={{ label: classes.iconLabel }}
            onClick={setSelectedValue}
            aria-pressed={list.name === selected}
            value={list.name}
          >
            <span>
              <Icon
                className={classes.icon}
                icon={list.iconName}
                color={theme.palette.custom.brandRed}
              />
            </span>
            <h6 className={classes.iconButtonName}>{list.name}</h6>
          </Button>
        </Box>
      ))}
    </Box>
  );
};

EmojiButtons.defaultProps = {
  setSelectedValue: () => {},
  listItems: [],
  selected: '',
};

EmojiButtons.propTypes = {
  setSelectedValue: PropTypes.func,
  listItems: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.string,

};

export default EmojiButtons;
