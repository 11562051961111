import STATUS from '../../globalStatuses';
import {
  REQUEST_SALESFORCE_TOKEN,
  RECEIVE_SALESFORCE_TOKEN,
  REQUEST_SALESFORCE_TOKEN_ERROR,
  INVALIDATE_SALESFORCE_TOKEN,
} from './types';

export default function salesforce(
  state = {
    salesforce: {},
  },
  action,
) {
  switch (action.type) {
    case REQUEST_SALESFORCE_TOKEN: {
      return {
        ...state,
        salesforceTokenStatus: STATUS.FETCHING,
      };
    }
    case RECEIVE_SALESFORCE_TOKEN: {
      return {
        ...state,
        salesforceTokenStatus: STATUS.FETCHED,
        salesforceTokenValue: action.token,
      };
    }
    case REQUEST_SALESFORCE_TOKEN_ERROR: {
      return {
        ...state,
        salesforceTokenStatus: STATUS.ERROR,
      };
    }
    case INVALIDATE_SALESFORCE_TOKEN:
      return {
        ...state,
        salesforceTokenStatus: STATUS.INVALIDATED,
      };

    default:
      return state;
  }
}
