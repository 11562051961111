const allowedAttempts = 2;
/**
 * This Interceptor is intended to handle eTag Conflicts.
 * In order to work generically, the original axios request needs to supply an actions {} object.
 *
 * actions.invalidate:
 * the invalidate redux action that should change the status of the domain to INVALIDATED
 * actions.fetch:
 * the fetch redux action that should fetch the invalidated domain
 * actions.retry:
 * the update redux action that should reattempt the original action
 * actions.attempts:
 * the number of attempts made
 *
 * @param error
 * @param dispatch
 * @returns {Promise<never>|*}
 */
const eTagConflictInterceptor = (error, dispatch) => {
  const { config, response } = error;
  if (config && response && response.status === 409) {
    if (!config.actions || !config.actions.attempts || config.actions.attempts > allowedAttempts
      || !config.actions.invalidate || !config.actions.fetch || !config.actions.retry) {
      // eslint-disable-next-line no-console
      console.log('Encountered 409 Conflict without reattempting.\n'
        + 'Please provide the request with invalidate, fetch, and retry actions.');
      return Promise.reject(error);
    }
    dispatch(config.actions.invalidate());
    return dispatch(config.actions.fetch())
      .then(() => (dispatch(config.actions.retry(config.actions.attempts + 1))))
      .catch((retryError) => (Promise.reject(retryError)));
  }
  return Promise.reject(error);
};

export default eTagConflictInterceptor;
