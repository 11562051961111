export default function ContactsService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = `${MicroServices.Base}/contacts`;
  const getAddresses = async (personId) => client.get(
    `${baseEndpointUrl}/v1/addresses?linkId=${personId}&linkType=person`,
  );

  const getAddress = async (addressId) => client.get(
    `${baseEndpointUrl}/v1/address/${addressId}`,
  );

  const putAddress = async (addressId, body) => client.put(
    `${baseEndpointUrl}/v1/address/${addressId}`, body,
  );

  const getPhones = async (personId) => client.get(
    `${baseEndpointUrl}/v1/phones?linkId=${personId}&linkType=person`,
  );

  const getPhone = async (phoneId) => client.get(
    `${baseEndpointUrl}/v1/phones/${phoneId}`,
  );

  const postPhone = async (body) => client.post(
    `${baseEndpointUrl}/v1/phone`, body,
  );

  const putPhone = async (phoneId, body) => client.put(
    `${baseEndpointUrl}/v1/phone/${phoneId}`, body,
  );

  const deletePhone = async (phoneId) => client.delete(
    `${baseEndpointUrl}/v1/phone/${phoneId}`,
  );

  const getEmails = async (personId) => client.get(
    `${baseEndpointUrl}/v1/emails?linkId=${personId}&linkType=person`,
  );

  const getEmail = async (emailId) => client.get(
    `${baseEndpointUrl}/v1/emails/${emailId}`,
  );

  const postEmail = async (body) => client.post(
    `${baseEndpointUrl}/v1/email`, body,
  );

  const putEmail = async (emailId, body) => client.put(
    `${baseEndpointUrl}/v1/email/${emailId}`, body,
  );

  const deleteEmail = async (emailId) => client.delete(
    `${baseEndpointUrl}/v1/email/${emailId}`,
  );

  const getStaffContacts = async (personId, program) => client.get(
    `${baseEndpointUrl}/v2/support/${personId}?academicprogramcode=${program}`,
  );

  return {
    getAddresses,
    getAddress,
    putAddress,
    getPhones,
    getPhone,
    postPhone,
    putPhone,
    deletePhone,
    getEmails,
    getEmail,
    postEmail,
    putEmail,
    deleteEmail,
    getStaffContacts,
  };
}
