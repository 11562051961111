import STATUS from '../../globalStatuses';
import {
  REQUEST_ADDRESSES, RECEIVE_ADDRESSES, REQUEST_ADDRESSES_ERROR,
  REQUEST_ADDRESS, RECEIVE_ADDRESS, REQUEST_ADDRESS_ERROR,
  REQUEST_UPDATE_ADDRESS, RECEIVE_UPDATE_ADDRESS, REQUEST_UPDATE_ADDRESS_ERROR,
} from './types';
import { transformById } from '../../../utils/action-reducer-functions';

function addresses(state = { addresses: {} }, action) {
  switch (action.type) {
    case REQUEST_ADDRESSES:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case RECEIVE_ADDRESSES:
      return transformById(state, action.addresses);
    case REQUEST_ADDRESSES_ERROR:
      return {
        ...state,
        status: STATUS.ERROR,
      };
    case REQUEST_ADDRESS:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case RECEIVE_ADDRESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.address.id]: action.address,
        },
        allIds: state.allIds.includes(action.address.id)
          ? state.allIds : [...state.allIds, action.address.id],
        status: STATUS.FETCHED,
      };
    case REQUEST_ADDRESS_ERROR:
      return {
        ...state,
        status: STATUS.ERROR,
      };
    case REQUEST_UPDATE_ADDRESS:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case RECEIVE_UPDATE_ADDRESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.address.id]: action.address,
        },
        allIds: state.allIds.includes(action.address.id)
          ? state.allIds : [...state.allIds, action.address.id],
        status: STATUS.FETCHED,
      };
    case REQUEST_UPDATE_ADDRESS_ERROR:
      return {
        ...state,
        status: STATUS.ERROR,
      };
    default:
      return state;
  }
}

export default addresses;
