import STATUS from '../../globalStatuses';
import { REQUEST_CURRICULUM_SKILLS, REQUEST_CURRICULUM_SKILLS_ERROR, RECEIVE_CURRICULUM_SKILLS } from './types';

export default function acmtSkills(state = { acmtSkills: {} }, action) {
  const byTemplateId = { ...state.byTemplateId };
  switch (action.type) {
    case REQUEST_CURRICULUM_SKILLS:
      action.templateIds.forEach((templateId) => {
        byTemplateId[templateId] = {
          ...byTemplateId[templateId],
          status: STATUS.FETCHING,
        };
      });
      return {
        ...state,
        byTemplateId,
      };
    case RECEIVE_CURRICULUM_SKILLS:
      return {
        ...state,
        byTemplateId: {
          ...byTemplateId,
          [action.templateId]: {
            ...byTemplateId[action.templateId],
            ...action.skillData,
            status: STATUS.FETCHED,
          },
        },
      };
    case REQUEST_CURRICULUM_SKILLS_ERROR:
      byTemplateId[action.templateId] = {
        ...byTemplateId[action.templateId],
        receivedAt: action.receivedAt,
        error: action.error,
        status: STATUS.ERROR,
      };
      return {
        ...state,
        byTemplateId,
      };
    default:
      return state;
  }
}
