import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  stripe: {
    zIndex: 1060,
    opacity: 1,
    backgroundColor: theme.palette.grays.mediumDarkGray,
    padding: theme.spacing(1.6),
    top: theme.spacing(9.9),
    [theme.breakpoints.up('md')]: {
      top: theme.spacing(8.2),
      padding: `${theme.spacing(1.6)} ${theme.spacing(7.0)}`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(1.6)} ${theme.spacing(9.0)}`,
    },
    width: '100%',
    position: 'fixed',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.common.white,
    fontSize: '1.6rem',
    lineHeight: '1.5',
    // TODO: remove style below when /document/v1/formOrPacket stops returning overly general
    //  p styles that overwrite above inherited style
    '& p': {
      fontSize: '1.6rem',
    },
  },
  icon: {
    display: 'none',
    fill: theme.palette.common.white,
    marginRight: theme.spacing(0.8),
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  message: {
    display: 'block',
    textAlign: 'left',
    '& p': {
      display: 'inline',
      margin: 0,
    },
  },
  messageText: {
    height: theme.spacing(2.4),
    verticalAlign: 'middle',
    '& a': {
      color: theme.palette.common.white,
      textDecoration: 'underline',
      '&:hover': {
        textDecorationColor: theme.palette.primary.main,
      },
      '&&:focus': {
        outline: `${theme.spacing(0.1)} solid ${theme.palette.common.white}`,
        outlineOffset: theme.spacing(0.3),
      },
    },
  },
  cta: {
    color: theme.palette.common.white,
    textDecoration: 'none',
    verticalAlign: 'middle',
    marginLeft: '1rem',
    '& svg': {
      marginLeft: '0.8rem',
      fill: theme.palette.common.white,
      transition: 'transform 0.2s linear',
    },
    '&:hover': {
      color: theme.palette.common.white,
      textDecoration: 'none',
      '& svg': {
        transform: 'translate(0.4rem, 0)',
      },
    },
    '&&:focus': {
      textDecoration: 'none',
      outline: `${theme.spacing(0.1)} solid ${theme.palette.common.white}`,
      outlineOffset: theme.spacing(0.3),
    },
  },
  alertMessage: {
    display: 'inline-flex',
    textAlign: 'left',
    width: (truncateWidth) => {
      if (truncateWidth !== 0) {
        return 'auto';
      }
      return '100%';
    },
  },
  alertExpanded: {
    display: 'block',
    textAlign: 'left',
    width: (truncateWidth) => {
      if (truncateWidth !== 0) {
        return 'auto';
      }
      return '100%';
    },
  },
  truncateButton: {
    background: 'transparent',
    border: 'none',
    fontSize: '1.6rem',
    lineHeight: '1.5',
    color: `${theme.palette.common.white}`,
    textDecoration: 'underline',
    '&&:focus': {
      outline: 'none',
    },
  },
}));

export default useStyles;
