import STATUS from '../../globalStatuses';
import {
  REQUEST_TRANSFER_CREDITS_DATA,
  RECEIVE_TRANSFER_CREDITS_DATA,
  REQUEST_TRANSFER_CREDITS_ERROR,
} from './types';

const getTransferCredits = (auditDetails, transfer) => {
  const indexedTransferArr = {};
  if (auditDetails) {
    Object.entries(auditDetails).forEach(([keyPid, detailPindexedArr]) => {
      const transferCourses = [];
      const filteredArr = detailPindexedArr ? detailPindexedArr
        .filter((college) => college.activity.institutionName !== null
        && college.activity.institutionName.toUpperCase() !== 'UNIVERSITY OF PHOENIX') : [];
      filteredArr.forEach((course) => {
        const dpCourseId = course.activity.id.replace(/[^a-zA-Z0-9 ]/g, '').replace(' ', '');
        transfer.forEach((transferCourse) => {
          const tcCourseId = transferCourse.courseId.replace(/[^a-zA-Z0-9 ]/g, '').replace(' ', '');
          if (tcCourseId === dpCourseId
            && transferCourse.orgaName
            === course.activity.institutionName) {
            const courseObj = {
              courseId: transferCourse.courseId,
              title: course.activity.name,
              // eslint-disable-next-line no-nested-ternary
              applied: course.requirementDetails
                ? course.requirementDetails[0].creditsSummary.applied
                : transferCourse.creditsAccepted === 'Y'
                  ? transferCourse.creditsEarned : 0,
              label: course.requirementDetails
                ? course.requirementDetails[0].requirement.name : '',
              grade: course.activity.grade,
              levelType: course.activity.creditLevelCode,
              orgaName: transferCourse.orgaName,
              creditsEarned: transferCourse.creditsEarned !== null
                ? transferCourse.creditsEarned : 0,
              creditsAttempted: transferCourse.creditsAttempted !== null
                ? transferCourse.creditsAttempted : 0,
              creditsAccepted: transferCourse.creditsAccepted,
              creditsApplied: transferCourse.creditsAccepted !== null
                && transferCourse.creditsAccepted === 'Y'
                ? transferCourse.creditsEarned : 0,
              nonTransferReason: transferCourse.nonTransferReason ? transferCourse.nonTransferReason : '',
            };
            transferCourses.push(courseObj);
          }
        });
      });

      indexedTransferArr[keyPid] = transferCourses;
    });
  }
  return indexedTransferArr;
};

export default function transferCreditsData(state = {}, action) {
  switch (action.type) {
    case REQUEST_TRANSFER_CREDITS_DATA:
      return {
        ...state,
        receivedAt: action.receivedAt,
        status: STATUS.FETCHING,
      };
    case RECEIVE_TRANSFER_CREDITS_DATA: {
      return {
        ...state,
        receivedAt: action.receivedAt,
        byProgramId: getTransferCredits(action.indexedAuditDetailsArr,
          action.transferCreditResponseData),
        status: STATUS.FETCHED,
      };
    }
    case REQUEST_TRANSFER_CREDITS_ERROR: {
      return {
        ...state,
        status: STATUS.ERROR,
        receivedAt: action.receivedAt,
      };
    }

    default:
      return state;
  }
}
