export default function SearchService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = `${MicroServices.Base}`;

  const getSearchResults = async (body, collectionNameSelection) => client.post(
    `${baseEndpointUrl}/search/v1/${collectionNameSelection}/search`, body,
  );

  return { getSearchResults };
}
