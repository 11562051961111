import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiAccordion from '@material-ui/core/Accordion';
import { AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import Icon from '../../icon/v1/Icon';
import useStyles from './Accordion.styles';
import { labelize } from '../../../utils/stringUtils';
import { isDate, formatDate } from '../../../utils/date-time-functions';

export default function Accordion(props) {
  const {
    header,
    links,
    data,
    styleType,
    sectionIndex,
    expandIcon,
    collapseIcon,
    externalExpanded,
    onChange,
    boldHeader,
    multiItem,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const componentName = 'Accordion';
  const classes = useStyles({ boldHeader });
  const id = `accordion${sectionIndex}`;
  const [expanded, setExpanded] = React.useState(false);
  useEffect(() => {
    if (externalExpanded !== null) {
      setExpanded(externalExpanded);
    }
  }, [externalExpanded, expanded]);
  /* eslint-disable react/no-array-index-key */
  const linkItems = links.map((item, index) => (
    <li
      className={`${classes.item} styleType-${styleType}`}
      key={`${sectionIndex}-${index}`}
    >
      <a href={item.url} target={item.target ? item.target : '_self'}>{item.text}</a>
    </li>
  ));

  const objectValueType = (objectValue) => {
    if (isDate(objectValue)) {
      return formatDate('', objectValue);
    } if (typeof objectValue === 'string' && objectValue.startsWith('<a', 0)) {
      return (
        <span dangerouslySetInnerHTML={{ __html: objectValue }} />
      );
    }
    return objectValue;
  };

  const listItem = (item, key, index) => {
    if (item) {
      return (
        <li
          className={`${classes.item} styleType-${styleType}`}
          key={`${sectionIndex}-${index}`}
        >
          <Typography component="h5" variant="subtitle1">{labelize(key)}</Typography>
          <Typography variant="h6">{objectValueType(item)}</Typography>
        </li>
      );
    }
    return null;
  };
  const multiItemList = [];
  if (multiItem) {
    const appendedList = {};
    Object.keys(data).forEach((key, index) => {
      if (typeof data[key] === 'string' && (key === 'Program Requirements' || key === 'Credits')) {
        // check to see if string has \n and create an array of multi valued strings
        const lines = data[key].trim().split('\n');
        /*
        iterate through the multi value array and create an appended list of values by index number
        example of what the appendedList array might be
         {
          0: ["program1", "program2" ],
          1: ["3", "1" ],
         }
         */
        lines.forEach((item, i) => {
          if (item) {
            if (appendedList[i]) {
              const current = [...appendedList[i], listItem(item, key, index)];
              appendedList[i] = current;
            } else {
              appendedList[i] = [listItem(item, key, index)];
            }
          }
        });
      } else {
        multiItemList.push(listItem(data[key], key, index));
      }
    });

    const appendedListKeys = Object.keys(appendedList);
    /*
    take the appendedList and add listItems to the multiItemList so that they are grouped
    by the item value correlated to the index
    example program1 would be added then "3" would follow
    */
    appendedListKeys.forEach((item) => {
      multiItemList.push(...appendedList[item]);
    });
  }
  const dataItems = Object.keys(data).map((key, index) => (listItem(data[key], key, index)));

  return (
    <MuiAccordion
      id={id}
      className={`${classes.root} styleType-${styleType}`}
      square
      elevation={0}
      onChange={onChange || (() => { setExpanded(!expanded); })}
      expanded={externalExpanded === null ? expanded : externalExpanded}
    >
      <AccordionSummary
        id={`${id}Summary`}
        className={`${classes.summary} styleType-${styleType}`}
        aria-controls={`${id}Details`}
        expandIcon={(
          <Icon
            className={`${classes.expandIcon} styleType-${styleType}`}
            icon={expanded ? collapseIcon : expandIcon}
            aria-hidden="true"
          />
        )}
      >
        <Typography
          className={`${classes.summaryHeader} styleType-${styleType}`}
        >
          {objectValueType(header)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        id={`${id}Details`}
        className={`${classes.details} styleType-${styleType}`}
      >
        <Typography
          component="div"
          className={classes.detailsContent}
        >
          {!multiItem && (
          <ul>
            {Object.keys(data).length ? dataItems : linkItems}
          </ul>
          )}
          {multiItem && (
          <ul>
            {Object.keys(data).length ? multiItemList.map((item) => (item)) : linkItems}
          </ul>
          )}
        </Typography>
      </AccordionDetails>
    </MuiAccordion>
  );
}

Accordion.propTypes = {
  header: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      target: PropTypes.string,
    }),
  ),
  data: PropTypes.objectOf(PropTypes.any),
  styleType: PropTypes.string,
  sectionIndex: PropTypes.number.isRequired,
  expandIcon: PropTypes.string,
  collapseIcon: PropTypes.string,
  externalExpanded: PropTypes.bool,
  onChange: PropTypes.func,
  boldHeader: PropTypes.bool,
  multiItem: PropTypes.bool,
};

Accordion.defaultProps = {
  header: '',
  links: [],
  data: {},
  styleType: 'default',
  expandIcon: 'icon-toggle-open',
  collapseIcon: 'icon-toggle-close',
  externalExpanded: null,
  onChange: null,
  boldHeader: false,
  multiItem: false,
};
