import Cookies from 'js-cookie';
import STATUS from '../../globalStatuses';
import {
  REQUEST_TOKEN, RECEIVE_TOKEN, REQUEST_TOKEN_ERROR, INVALIDATE_TOKEN,
} from './types';
import { errorToMessage } from '../../../utils/error-functions';
import { logoutRedirect } from '../../../utils/redirect-functions';

function auth(state = { currentUser: {} }, action) {
  let redirect = window?.MyPhoenix?.Config?.Auth?.LogoutUrl || '/services/public/myphoenix/ssologout';
  switch (action.type) {
    case REQUEST_TOKEN:
      return {
        ...state,
        status: STATUS.FETCHING,
        request: action.request,
      };
    case RECEIVE_TOKEN:
      window.MyPhoenix.User.personId = action.token.personId;
      window.MyPhoenix.User.email = action.token.email;
      window.MyPhoenix.User.userName = action.token.userName;
      return {
        ...state,
        status: STATUS.FETCHED,
        token: action.token.token,
        personId: action.token.personId,
        email: action.token.email,
        userName: action.token.userName,
        serverSideReceivedAt: action.token.receivedAt || 'serverSideTokenInvalidationDisabled',
        clientSideReceivedAt: action.token.receivedAt ? Date.now() / 1000 : 'serverSideTokenInvalidationDisabled',
      };
    case REQUEST_TOKEN_ERROR:
      // Token Data Lost Reauthenticate
      if (action.error.response && action.error.response.status === 307) {
        const { Auth } = window.MyPhoenix.Config;
        redirect = Auth.RedirectUrl;
        Cookies.set('myphx_request_path',
          window.location.href,
          { domain: window.location.hostname, sameSite: 'Strict', secure: true });
      }
      logoutRedirect(redirect);
      return {
        ...state,
        status: STATUS.ERROR,
        error: errorToMessage(action.error),
      };
    case INVALIDATE_TOKEN:
      return {
        ...state,
        status: STATUS.INVALIDATED,
      };
    default:
      return state;
  }
}

export default auth;
