import {
  SET_SELECTED_EMAILCONSENT,
  SET_SELECTED_FEEDBACKCATEGORY,
  SET_SELECTED_EMOJIVALUE,
  SET_SELECTED_FEEDBACKREASON,
  CLEAR_FEEDBACK,
} from './types';

export function setSelectedEmailConsent(emailConsentData) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_EMAILCONSENT,
      emailConsentData,
    });
  };
}

export function setSelectedFeedbackCategory(feedbackCategoryData) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_FEEDBACKCATEGORY,
      feedbackCategoryData,
    });
  };
}

export function setSelectedEmojiValue(selectedEmojiValueData) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_EMOJIVALUE,
      selectedEmojiValueData,
    });
  };
}

export function setSelectedFeedbackReason(feedbackReasonData) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_FEEDBACKREASON,
      feedbackReasonData,
    });
  };
}

export function postFeedbackSurvey(endpoint) {
  return async (dispatch, getState, { Services }) => {
    const { feedback, currentUser } = getState();
    const payload = {
      Account__c: currentUser.salesForceId,
      Rating__c: feedback.selectedEmojiValue,
      Feedback__c: feedback.feedbackReason,
      Contact__c: feedback.emailConsent,
      Category__c: feedback.feedbackCategory, // Category__c needs to match with SRM API name
    };

    return Services.FeedbackSurveyService // FeedbackService
      .postFeedbackSurvey(payload, endpoint);
  };
}

export function clearSelectedFeedback() {
  return { type: CLEAR_FEEDBACK };
}
