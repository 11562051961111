import STATUS from '../../globalStatuses';
import {
  REQUEST_PLA, RECEIVE_PLA, REQUEST_PLA_ERROR,
} from './types';

export default function pla(state = {}, action) {
  switch (action.type) {
    case REQUEST_PLA:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case RECEIVE_PLA:
      return {
        ...state,
        applications: action.pla.plaApplication,
        receivedAt: action.receivedAt,
        status: STATUS.FETCHED,
      };
    case REQUEST_PLA_ERROR:
      return {
        ...state,
        receivedAt: action.receivedAt,
        status: STATUS.ERROR,
      };
    default:
      return state;
  }
}
