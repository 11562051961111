import {
  SAVE_LINKEDIN_CODE_PREREQUISITES,
  CLEAR_LINKEDIN_CODE_PREREQUISITES,
  REQUEST_LINKEDIN_TOKEN_ERROR,
  RECEIVE_LINKEDIN_TOKEN,
  REQUEST_LINKEDIN_TOKEN,
  CLEAR_CHOSEN_PLATFORM,
  CLEAR_SOCIAL,
} from './types';
import STATUS from '../../globalStatuses';
import { linkedin } from './platforms';

export default function applicationState(
  state = {
    linkedinStateValue: '',
    linkedin_redirect: '',
    linkedin_token: '',
    linkedinTokenExpires: 0,
    linkedinTokenStatus: STATUS.UNFETCHED,
    chosenPlatform: '',
  },
  action,
) {
  switch (action.type) {
    case SAVE_LINKEDIN_CODE_PREREQUISITES: {
      return {
        ...state,
        linkedinStateValue: action.stateValue,
        linkedin_redirect: action.redirectURL,
        chosenPlatform: linkedin,
      };
    }
    case CLEAR_LINKEDIN_CODE_PREREQUISITES: {
      return {
        ...state,
        linkedinStateValue: '',
        linkedin_redirect: '',
        linkedinTokenStatus: STATUS.UNFETCHED,
      };
    }
    case RECEIVE_LINKEDIN_TOKEN: {
      return {
        ...state,
        linkedin_token: action.token,
        linkedinTokenExpires: action.expires,
        linkedinTokenStatus: STATUS.FETCHED,
      };
    }
    case REQUEST_LINKEDIN_TOKEN: {
      return {
        ...state,
        linkedinTokenStatus: STATUS.FETCHING,
      };
    }
    case REQUEST_LINKEDIN_TOKEN_ERROR: {
      return {
        ...state,
        linkedinTokenError: action.error,
        linkedinTokenStatus: STATUS.ERROR,
      };
    }
    case CLEAR_CHOSEN_PLATFORM: {
      return {
        ...state,
        chosenPlatform: '',
      };
    }
    case CLEAR_SOCIAL:
      return {
        linkedinStateValue: '',
        linkedin_redirect: '',
        linkedin_token: '',
        linkedinTokenStatus: STATUS.UNFETCHED,
        chosenPlatform: '',
      };
    default:
      return state;
  }
}
