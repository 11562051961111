import { connect } from 'react-redux';
import fetchCoursesIfNeeded from '../domains/courses/actions';
import fetchCurrentUserIfNeeded from '../domains/currentUser/actions';
import Footer from '../../components/footer/v1/Footer';
import { getCurrentCoursesByRange, getFutureCoursesByRange } from '../../utils/course-functions';
import fetchProgramsIfNeeded from '../domains/programs/actions';

const mapStateToProps = (state, ownProps) => {
  const primaryProgram = state.programs.byId[state.programs.primaryProgramId] || {};
  const isCBEDA = primaryProgram && primaryProgram.cbeDaProgram === 'TRUE';
  return {
    copyrightLinks: ownProps.copyrightLinks,
    copyrightText: ownProps.copyrightText,
    currentCourses: getCurrentCoursesByRange(state.courses),
    futureCourses: getFutureCoursesByRange(state.courses),
    statusAuth: state.auth.status,
    statusPrograms: state.programs.status,
    linkLists: ownProps.linkLists,
    logo: ownProps.logo,
    tiles: ownProps.tiles,
    isCBEDA,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadProfile: () => {
    dispatch(fetchCurrentUserIfNeeded());
  },
  loadCourses: () => {
    dispatch(fetchCoursesIfNeeded());
  },
  loadPrograms: () => dispatch(fetchProgramsIfNeeded()),
});

const FooterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Footer);

export default FooterContainer;
