import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './HeaderAvatar.styles';
import Avatar from '../../avatar/v1/Avatar';
import STATUS from '../../../redux/globalStatuses';
import Icon from '../../icon/v1/Icon';
import { createEventObject, useRaiseEventOnClick } from '../../../utils/event-functions';

/**
 * Avatar React Component
 */
function HeaderAvatar({
  avatarContentType,
  encodedAvatar,
  firstName,
  lastName,
  loadProfile,
  loadAvatar,
  issuerId,
  profileLink,
  statusAuth,
  parentComponentName,
}) {
  const classes = styles();
  const [copiedInterval, setCopiedInterval] = useState(null);
  const componentName = 'HeaderAvatar';
  const linkRef = useRef(null);
  useRaiseEventOnClick([linkRef]);
  useEffect(() => {
    if (statusAuth === STATUS.FETCHED) {
      loadProfile();
      loadAvatar();
    }
  }, [loadProfile, loadAvatar, statusAuth]);

  const handleCopyClick = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(issuerId);
    const interval = setInterval(() => {
      clearInterval(copiedInterval);
      setCopiedInterval(null);
    }, 2000);
    setCopiedInterval(interval);
  };

  return (
    <div className={classes.main}>
      <a
        href={profileLink}
        className={`${classes.root} ${firstName || lastName ? '' : 'avatar-loading'}`}
        aria-label={issuerId ? `${firstName} ${lastName} IRN ${issuerId}` : `${firstName} ${lastName}`}
        ref={linkRef}
        data-raise-event-on-click={createEventObject(
          `${parentComponentName}_${componentName}_AvatarLink`,
          [{ profileLink }, { issuerId }, { text: 'PROFILE' }],
        )}
      >
        <Avatar
          avatarContentType={avatarContentType}
          encodedAvatar={encodedAvatar}
          firstName={firstName}
          icon="icon-phoenix-head"
          lastName={lastName}
          mobileSize="xs"
        />
        <div className="profile-info-container">
          <span className="profile-info">
            <span>{`${firstName} ${lastName}`}</span>
          </span>
        </div>
      </a>
      {issuerId && (
        <div
          className={classes.inrRoot}
          data-raise-event-on-click={createEventObject(
            `${parentComponentName}_${componentName}_AvatarLink`,
            [{ profileLink }, { issuerId }, { text: 'IRN' }],
          )}
        >
          <span className="irn-text">
            <span>IRN</span>
            &nbsp;
            {issuerId}
            &nbsp;
            <Icon color={copiedInterval ? 'phoenix-red' : 'icon-gray'} icon="icon-copy" onClick={handleCopyClick} />
            <span className={`copy-text ${copiedInterval ? '' : 'hidden'}`}>&nbsp;Copied!</span>
          </span>
        </div>
      )}
    </div>
  );
}

HeaderAvatar.propTypes = {
  avatarContentType: PropTypes.string.isRequired,
  encodedAvatar: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  issuerId: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  loadProfile: PropTypes.func.isRequired,
  loadAvatar: PropTypes.func.isRequired,
  profileLink: PropTypes.string.isRequired,
  statusAuth: PropTypes.string.isRequired,
  parentComponentName: PropTypes.string,
};

HeaderAvatar.defaultProps = {
  parentComponentName: '',
};

export default HeaderAvatar;
