import { makeStyles } from '@material-ui/core/styles';

function dimensionDecoder(size) {
  switch (size) {
    case 'xs':
      return '3.7rem';
    case 'sm':
      return '5rem';
    default:
      return '10rem';
  }
}

function fontSizeDecoder(size) {
  switch (size) {
    case 'xs':
      return '2.4rem';
    case 'sm':
      return '2.4rem';
    default:
      return '4.8rem';
  }
}

export default makeStyles((theme) => ({
  root: (props) => {
    const dimensions = dimensionDecoder(props.size);
    const fontSize = fontSizeDecoder(props.size);
    const mobileDimensions = dimensionDecoder(props.mobileSize);
    const mobileFontSize = fontSizeDecoder(props.mobileSize);
    return {
      backgroundColor: theme.palette.grays.darkGray,
      height: dimensions,
      width: dimensions,
      fontSize,
      fontWeight: 900,
      color: theme.palette.common.white,
      '& img,svg': {
        height: dimensions,
        width: dimensions,
      },
      [theme.breakpoints.down('sm')]: {
        height: mobileDimensions,
        width: mobileDimensions,
        fontSize: mobileFontSize,
        '& img,svg': {
          height: mobileDimensions,
          width: mobileDimensions,
        },
      },
    };
  },
}));
