import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  primaryText: {
    color: theme.palette.common.black,
    fontSize: '1.4rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
      marginLeft: theme.spacing(4.5),
    },
    fontWeight: 'bold',
    marginLeft: theme.spacing(0.0),
  },
  secondaryText: {
    color: theme.palette.common.black,
    fontSize: '1.4rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
      marginLeft: theme.spacing(4.5),
    },
    marginLeft: theme.spacing(0.0),
  },
  code: {
    color: theme.palette.error.main,
    fontSize: '1rem',
    fontWeight: '900',
  },
  h1: {
    padding: 0,
    fontSize: '2.4rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3.0rem',
    },
    fontWeight: '500',
    color: theme.palette.common.black,
    marginTop: theme.spacing(0.0),
  },
  icon: {
    fill: theme.palette.primary.main,
    fontSize: theme.spacing(1.5),
    marginBottom: theme.spacing(0.8),
  },
  primaryButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    float: 'left',
    [theme.breakpoints.up('sm')]: {
      float: 'right',
    },
    '&:hover': {
      backgroundColor: theme.palette.grays.darkGray,
    },
    marginTop: theme.spacing(2.0),
  },
  button: {
    width: '17.6rem',
    height: '4rem',
    borderRadius: '3rem',
    backgroundColor: theme.palette.primary.adaSafeMain,
    fontSize: '1.3rem',
    color: theme.palette.common.white,
    textAlign: 'center',
    fontWeight: 500,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.adaSafeMain,
      color: theme.palette.common.white,
    },
  },
}));
