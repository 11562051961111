import STATUS from '../../globalStatuses';
import { REQUEST_SITE_STRIPE, REQUEST_SITE_STRIPE_ERROR, RECEIVE_SITE_STRIPE } from './types';
import { errorToMessage } from '../../../utils/error-functions';

function requestSiteStripe() {
  return {
    type: REQUEST_SITE_STRIPE,
  };
}
function requestSiteStripeError(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: REQUEST_SITE_STRIPE_ERROR,
  };
}

function receiveSiteStripe(data) {
  return {
    data,
    receivedAt: Date.now(),
    type: RECEIVE_SITE_STRIPE,
  };
}

function fetchSiteStripe(modelName = 'site-stripe') {
  return async (dispatch, getState, { Services }) => {
    dispatch(requestSiteStripe());
    return Services.BuilderService.getContentByModelName(modelName)
      .then((response) => {
        const { data } = response;
        const { results } = data;
        const siteStripe = results[0]?.data;
        dispatch(receiveSiteStripe(siteStripe));
      })
      .catch((err) => dispatch(requestSiteStripeError(errorToMessage(err))));
  };
}

function shouldFetchSiteStripe(state) {
  const { siteStripe } = state;
  return siteStripe
    && siteStripe.status !== STATUS.FETCHED
    && siteStripe.status !== STATUS.FETCHING;
}

export default function fetchSiteStripeIfNeeded(modelName) {
  return (dispatch, getState) => {
    if (shouldFetchSiteStripe(getState())) {
      return dispatch(fetchSiteStripe(modelName));
    }
    return Promise.resolve();
  };
}
