const baseUrl = window?.MyPhoenix?.Config?.Blackboard?.Base ? window.MyPhoenix.Config.Blackboard.Base : 'https://vle.phoenix.edu';
const routerId = window?.MyPhoenix?.Config?.Blackboard?.RouterId ? window.MyPhoenix.Config.Blackboard.RouterId : 'BB300502SF160441';
const newClassroomBaseUrl = window?.MyPhoenix?.Config?.Blackboard?.NewClassroomBase
  ? window.MyPhoenix.Config.Blackboard.NewClassroomBase : 'https://api.uopx.io/api/learning/v1/courseLink?fallBack=/ultra/course';

const getBBRootUrl = () => baseUrl;

const getOldBBClassUrl = (sectionId) => (sectionId
  ? `${baseUrl}/?new_loc=/webapps/apol-integration-tools-${routerId}/router%3Flocation%3DCOURSE%26locationId%3D${sectionId.startsWith('OSIRIS') ? 'OSIRIS' : 'SDW'}%3A${sectionId.startsWith('OSIRIS') ? sectionId.substring(7) : sectionId.substring(4)}`
  : getBBRootUrl());

const getNewBBClassUrl = (sectionId) => (sectionId
  ? `${newClassroomBaseUrl}&courseId=${sectionId}`
  : getBBRootUrl());

const getBBClassUrl = (sectionId) => (
  (window?.MyPhoenix?.Config?.Features?.isLearningBBUrlEnabled
    ? window.MyPhoenix.Config.Features.isLearningBBUrlEnabled : false)
    ? getNewBBClassUrl(sectionId) : getOldBBClassUrl(sectionId)
);

const openBlackboard = (sectionId) => {
  window.open(sectionId ? getBBClassUrl(sectionId) : getBBRootUrl(), '_blank');
};

export {
  getBBRootUrl,
  getBBClassUrl,
  openBlackboard,
};
