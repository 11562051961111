const moment = require('moment-timezone');

const CURRENT_DATE_OVERRIDE_KEY = 'myphx-override-current-date';

const getCurrentDate = (timezone = 'America/Phoenix') => {
  const currentDateOverride = sessionStorage.getItem(CURRENT_DATE_OVERRIDE_KEY);
  const today = moment().tz(timezone);
  if (currentDateOverride) {
    const overriddenDate = moment(currentDateOverride);
    if (overriddenDate.isValid()) {
      return overriddenDate.tz(timezone);
    }
  }
  return today;
};
/** All parameters should be initialized js Date() objects.
    Idea is that the component can initialize it fewer times,
    and reuse it, as opposed to creating a new Date object(s) for each function call. */
const currentDateTime = getCurrentDate();
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const isSameDate = (date, date2) => date.getDate() === date2.getDate()
  && date.getMonth() === date2.getMonth()
  && date.getFullYear() === date2.getFullYear();

const getHourDue = (date) => {
  const militaryHour = date.getHours();
  const minutes = date.getMinutes();
  let normalHour = '';
  let period = 'am';
  if (militaryHour === 0) {
    normalHour = 12;
  } else if (militaryHour === 12) {
    normalHour = militaryHour;
    period = 'pm';
  } else if (militaryHour > 12 && militaryHour <= 23) {
    normalHour = militaryHour - 12;
    period = 'pm';
  } else {
    normalHour = militaryHour;
    period = 'am';
  }
  if (minutes !== 0) {
    return `${normalHour}:${minutes < 10 ? '0' : ''}${minutes}${period}`;
  }
  return `${normalHour}${period}`;
};

const getMonthAndDay = (date, separator = '/') => {
  // months are 0 index based *rolls eyes*
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  return `${month}${separator}${day}`;
};

const getYearMonthAndDay = (date) => `${date.getFullYear()}-${getMonthAndDay(date, '-')}`;

const isToday = (date) => isSameDate(date, currentDateTime);

/** adds a colon between hours and minutes in hour offset portion of dateTime string to prevent
 *  safari/ie from evaluating NaN, see: http://farai.github.io/blog/2015/02/16/javascript-nan-for-new-date-object-in-ie-and-safari/ */
// eslint-disable-next-line no-useless-escape
const addColonToHourOffset = (dateTimeString) => (dateTimeString.replace(/([+\-]\d\d)(\d\d)$/, '$1:$2'));

const toDateString = (date) => moment.tz(date, 'America/Phoenix').format('dddd, MMMM Do');

const formatDate = (format, date, timezone = 'America/Phoenix') => {
  if (!format) {
    return moment.tz(date, timezone).format('MM/DD/YY');
  } if (format === 'sortable') {
    // when sorting we want to compare the milliseconds
    return moment(date).tz(timezone).valueOf();
  }
  return moment.tz(date, timezone).format(format);
};

const isDate = (value) => {
  if (typeof value !== 'string') {
    return false;
  }
  const d = moment.tz(value, 'America/Phoenix');
  if (d == null || !d.isValid()) {
    return false;
  }

  return value.indexOf(d.format('YYYY-MM-DD')) >= 0
    || value.indexOf(d.format('MM/DD/YYYY')) >= 0
    || value.indexOf(d.format('D/M/YYYY')) >= 0
    || value.indexOf(d.format('DD/MM/YYYY')) >= 0
    || value.indexOf(d.format('D/M/YY')) >= 0
    || value.indexOf(d.format('DD/MM/YY')) >= 0;
};
// (StartA <= EndB) and (EndA >= StartB)
const dateRangesOverlap = (firstRangeStart, firstRangeEnd, secondRangeStart, secondRangeEnd) => {
  const firstRangeStartDate = moment(firstRangeStart);
  const firstRangeEndDate = moment(firstRangeEnd);
  const secondRangeStartDate = moment(secondRangeStart);
  const secondRangeEndDate = moment(secondRangeEnd);

  return (firstRangeStartDate <= secondRangeEndDate) && (firstRangeEndDate >= secondRangeStartDate);
};

const getDaysBetween = (date1, date2) => (
  moment(date2).diff(moment(date1), 'days')
);

const getDayOfWeek = (date, timezone = 'America/Phoenix') => {
  const dayOfWeek = moment.tz(date, timezone).isoWeekday();
  return Number.isNaN(dayOfWeek) ? null
    : days[dayOfWeek - 1];
};

export {
  CURRENT_DATE_OVERRIDE_KEY,
  getCurrentDate,
  currentDateTime,
  days,
  isSameDate,
  getHourDue,
  getMonthAndDay,
  isToday,
  getYearMonthAndDay,
  addColonToHourOffset,
  toDateString,
  formatDate,
  isDate,
  getDaysBetween,
  dateRangesOverlap,
  getDayOfWeek,
};
