import STATUS from '../../globalStatuses';
import {
  REQUEST_STUDENT_STATUS, RECEIVE_STUDENT_STATUS,
  REQUEST_STUDENT_STATUS_ERROR, INVALIDATE_STUDENT_STATUS,
} from './types';

export default function student(state = { student: {} }, action) {
  switch (action.type) {
    case REQUEST_STUDENT_STATUS:
      return {
        ...state,
        receivedAt: action.receivedAt,
        status: STATUS.FETCHING,
      };
    case RECEIVE_STUDENT_STATUS: {
      return {
        ...state,
        receivedAt: action.receivedAt,
        status: STATUS.FETCHED,
        statuses: action.status,
      };
    }
    case REQUEST_STUDENT_STATUS_ERROR:
      return {
        ...state,
        error: action.error,
        receivedAt: action.receivedAt,
        status: STATUS.ERROR,
      };
    case INVALIDATE_STUDENT_STATUS:
      return {
        ...state,
        status: STATUS.INVALIDATED,
      };
    default:
      return state;
  }
}
