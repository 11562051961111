export default function SkillsService(client) {
  const { MicroServices } = window.MyPhoenix.Config;
  const baseEndpointUrl = MicroServices.Base.includes('localhost:3000')
    ? `${MicroServices.Base}`
    : '';

  const getAllSkills = async () => client.get(
    `${baseEndpointUrl}/myphx-static-assets/myphoenix/json/skills.json`,
  );

  return {
    getAllSkills,
  };
}
