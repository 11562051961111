import React, { createRef, useRef } from 'react';
import PropTypes from 'prop-types';
import useStyles from './LinkList.styles';
import { useRaiseEventOnClick, createEventObject } from '../../../utils/event-functions';

/**
 * Link List React Component
 */
function LinkList({
  header,
  links,
  styleType,
  parentComponentName,
}) {
  // eslint-disable-next-line no-unused-vars
  const componentName = `${parentComponentName}_LinkList`;
  const classes = useStyles();
  const regexPatternForCssSelector = /^-\d|^\d|^--|~|!|@|\$|%|\^|&|\*|\(|\)|\+|=|,|\.|\/|'|;|:|"|\?|>|<|\[|\]|\\|{|}|\||`|#|\s/g;
  const headerWithNoSpacesOrSpecialChars = header.replace(regexPatternForCssSelector, '');
  const linkRefs = useRef([]);
  linkRefs.current = links.map((_, index) => linkRefs.current[index] ?? createRef());
  useRaiseEventOnClick([linkRefs]);

  return (
    <div
      role="navigation"
      aria-labelledby={`${headerWithNoSpacesOrSpecialChars}Navigation`}
      className={`
        ${classes.linkList}
        ${classes.root}
        ${styleType === 'footer' ? classes.footer : ''}
      `}
    >
      <h2 id={`${headerWithNoSpacesOrSpecialChars}Navigation`}>{header}</h2>
      <ul>
        {links.map((item, index) => (
          <li key={`${item.url}-${item.text}`}>
            <a
              href={item.url}
              target={item.target ? item.target : '_self'}
              ref={linkRefs.current[index]}
              data-raise-event-on-click={createEventObject(
                `${componentName}_Link`,
                [{ url: item.url }, { target: item.target }, { text: item.text }, { styleType }],
              )}
            >
              {item.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

LinkList.propTypes = {
  header: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      target: PropTypes.string,
    }).isRequired,
  ),
  styleType: PropTypes.string,
  parentComponentName: PropTypes.string,
};

LinkList.defaultProps = {
  header: '',
  links: [],
  styleType: '',
  parentComponentName: '',
};

export default LinkList;
