import {
  REQUEST_USER_DEFINED_ATTRIBUTES,
  RECEIVE_USER_DEFINED_ATTRIBUTES,
  REQUEST_USER_DEFINED_ATTRIBUTES_ERROR,
} from './types';
import { errorToMessage } from '../../../utils/error-functions';
import { shouldFetchPersistedData } from '../../../utils/action-reducer-functions';

/* ===========  Get UserDefinedAttributes ============ */

/* action creators */
function requestUserDefinedAttributes() {
  return { type: REQUEST_USER_DEFINED_ATTRIBUTES, receivedAt: Date.now() };
}

function receiveUserDefinedAttributes(userDefinedAttributes) {
  return { type: RECEIVE_USER_DEFINED_ATTRIBUTES, userDefinedAttributes, receivedAt: Date.now() };
}

function requestUserDefinedAttributesError(error) {
  return { type: REQUEST_USER_DEFINED_ATTRIBUTES_ERROR, error, receivedAt: Date.now() };
}

/**
 * Fetches the events of the current user
 * @returns {Function}
 */
function fetchUserDefinedAttributes() {
  return async (dispatch, getState, { Services }) => {
    dispatch(requestUserDefinedAttributes());
    try {
      const { auth } = getState();
      const userAttributesResponse = await
      Services.PersonsService.getUserAttributesByPersonId(auth.personId);
      return dispatch(receiveUserDefinedAttributes(userAttributesResponse.data.attributes['User Defined']));
    } catch (error) {
      return dispatch(requestUserDefinedAttributesError(errorToMessage(error)));
    }
  };
}

function shouldFetchUserDefinedAttributes(state) {
  const { userDefinedAttributes } = state;
  if (!userDefinedAttributes) {
    return true;
  }
  return shouldFetchPersistedData(userDefinedAttributes.status, userDefinedAttributes.receivedAt);
}

export default function fetchUserDefinedAttributesIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchUserDefinedAttributes(getState())) {
      // Dispatch a thunk from thunk!
      return dispatch(fetchUserDefinedAttributes());
    }
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  };
}
/* ===========  End of Get Instructor ============ */
