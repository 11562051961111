import STATUS from '../../globalStatuses';
import {
  REQUEST_COURSEINSTRUCTOR, RECEIVE_COURSEINSTRUCTOR, REQUEST_COURSEINSTRUCTOR_ERROR,
} from './types';
import { getFacultyEmail } from '../../../utils/instructor-functions';

/*= ========== data operatons ========= */

const getFacultyName = (names) => {
  const name = names.reduce((acc, value) => (value.type.toLowerCase() === 'official' ? value : acc), {});
  return name;
};

/*= ==========End of data operatons ========= */

export default function courseInstructor(state = {}, action) {
  const byMembershipId = { ...state.byMembershipId };
  switch (action.type) {
    case REQUEST_COURSEINSTRUCTOR: {
      byMembershipId[action.membershipId] = {
        receivedAt: action.receivedAt,
        status: STATUS.FETCHING,
      };
      return {
        ...state,
        byMembershipId,
      };
    }
    case RECEIVE_COURSEINSTRUCTOR: {
      const instructor = {
        receivedAt: action.receivedAt,
        status: STATUS.FETCHED,
      };
      if (action.personId) {
        const instName = getFacultyName(action.names);
        const instEmail = getFacultyEmail(action.emails);
        instructor.firstName = instName.firstName || state.firstName;
        instructor.lastName = instName.lastName || state.lastName;
        instructor.email = instEmail?.emailAddress || state?.email || '';
        instructor.personId = action.personId;
      }
      byMembershipId[action.membershipId] = instructor;
      return {
        ...state,
        byMembershipId,
      };
    }
    case REQUEST_COURSEINSTRUCTOR_ERROR: {
      byMembershipId[action.membershipId].error = action.error;
      byMembershipId[action.membershipId].receivedAt = action.receivedAt;
      byMembershipId[action.membershipId].status = STATUS.ERROR;
      return {
        ...state,
        byMembershipId,
      };
    }
    default:
      return state;
  }
}
