import STATUS from '../../globalStatuses';
import {
  REQUEST_ACTIVITIES,
  REQUEST_ACTIVITIES_ERROR,
  RECEIVE_ACTIVITIES_BY_SECTION_ID,
  RECEIVE_ASSESSMENT_RESULTS_BY_SECTION_ID,
} from './types';

export default function activities(state = { activities: {} }, action) {
  const bySectionId = { ...state.bySectionId };
  switch (action.type) {
    case REQUEST_ACTIVITIES:
      action.sectionIds.forEach((sectionId) => {
        bySectionId[sectionId] = {
          ...bySectionId[sectionId],
          activities: [],
          status: STATUS.FETCHING,
        };
      });
      return {
        ...state,
        bySectionId,
      };
    case RECEIVE_ACTIVITIES_BY_SECTION_ID:
      return {
        ...state,
        bySectionId: {
          ...bySectionId,
          [action.sectionId]: {
            ...bySectionId[action.sectionId],
            activities: [
              ...action.activities.map(
                (activity) => ({
                  id: activity.id,
                  sectionId: activity.sourceId,
                  type: activity.type,
                  title: activity.title,
                  pointsAllowed: parseFloat(activity.pointsAllowed),
                  pointsScored: null,
                  status: '',
                  endDate: activity.endDate,
                  externalSystemIds: activity.externalSystemIds ? activity.externalSystemIds : null,
                }),
              )],
            receivedAt: action.receivedAt,
          },
        },
      };
    case RECEIVE_ASSESSMENT_RESULTS_BY_SECTION_ID:
      return {
        ...state,
        bySectionId: {
          ...bySectionId,
          [action.sectionId]: {
            ...bySectionId[action.sectionId],
            activities: [
              ...bySectionId[action.sectionId].activities.map(
                (activity) => {
                  const resultForActivity = action.assessmentResults.find(
                    (result) => (result.activityId === activity.id),
                  );
                  return {
                    ...activity,
                    pointsScored: resultForActivity ? resultForActivity.pointsScored : null,
                    status: resultForActivity ? resultForActivity.status : '',
                  };
                },
              ),
            ],
            receivedAt: action.receivedAt,
            status: state.bySectionId[action.sectionId].status === STATUS.ERROR
              ? STATUS.ERROR
              : STATUS.FETCHED,
          },
        },
      };
    case REQUEST_ACTIVITIES_ERROR:
      bySectionId[action.sectionId] = {
        ...bySectionId[action.sectionId],
        receivedAt: action.receivedAt,
        status: STATUS.ERROR,
      };
      return {
        ...state,
        bySectionId,
      };
    default:
      return state;
  }
}
