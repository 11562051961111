import STATUS from '../../globalStatuses';
import {
  REQUEST_EVENTS,
  RECEIVE_EVENTS,
  REQUEST_EVENTS_ERROR,
} from './types';

export default function events(state = {}, action) {
  switch (action.type) {
    case REQUEST_EVENTS: {
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    }
    case RECEIVE_EVENTS: {
      return {
        ...state,
        ...action.data,
        status: STATUS.FETCHED,
      };
    }
    case REQUEST_EVENTS_ERROR: {
      return {
        ...state,
        status: STATUS.ERROR,
      };
    }
    default:
      return state;
  }
}
