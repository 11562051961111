import Cookies from 'js-cookie';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import omit from 'lodash.omit';
import storage from 'redux-persist/lib/storage/session';
import moment from 'moment-timezone';
import createThunkMiddleware from './enhancedThunk';
import Services from '../../services/index';
import { ScenarioIdKey } from '../components/mock-scenarios/v1/mock-scenarios';
import activities from './domains/activities/reducer';
import addresses from './domains/addresses/reducer';
import attendances from './domains/attendances/reducer';
import auth from './domains/auth/reducer';
import courseInstructor from './domains/courseInstructor/reducer';
import tasks from './domains/tasks/reducer';
import courses from './domains/courses/reducer';
import courseCurriculum from './domains/courseCurriculum/reducer';
import currentUser from './domains/currentUser/reducer';
import emails from './domains/emails/reducer';
import enrollmentApplication from './domains/enrollmentApplication/reducer';
import phones from './domains/phones/reducer';
import preloadedState from './preloadedState';
import alerts from './domains/alerts/reducer';
import userDefinedAttributes from './domains/userDefinedAttributes/reducer';
import leaveOfAbsenceData from './domains/loa/reducer';
import student from './domains/student/reducer';
import programContacts from './domains/programContacts/reducer';
import programProgress from './domains/programProgress/reducer';
import skills from './domains/skills/reducer';
import programs from './domains/programs/reducer';
import applicationState from './domains/applicationState/reducer';
import { getCurrentCoursesByRange, getFutureCoursesByRange } from '../utils/course-functions';
import social from './domains/social/reducer';
import feedback from './domains/feedbackloop/reducer';
import salesforce from './domains/salesforce/reducer';
import pla from './domains/pla/reducer';
import courseOutcomes from './domains/coursesOutcomes/reducer';
import demographics from './domains/demographics/reducer';
import militaryStatus from './domains/militaryStatus/reducer';
import CBEData from './domains/cbe/reducer';
import acmtSkills from './domains/acmt/reducer';
import skillsBasedPrograms from './domains/skillsBasedPrograms/reducer';
import academicTerms from './domains/academicTerms/reducer';
import academicYear from './domains/academicYear/reducer';
import { setPageLoadIndex } from '../utils/page-functions';
import { getCurrentDate } from '../utils/date-time-functions';
import transferCreditsData from './domains/transferCreditDetails/reducer';
import siteStripe from './domains/siteStripe/reducer';
import events from './domains/events/reducer';

const thunkWithServices = createThunkMiddleware({ withExtraArgumentsNeedingState: { Services } });

const middlewares = [thunkWithServices];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger());
}

// add blacklist values (including nested reducer values)
const blacklistPaths = ['auth.request'];
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'applicationState',
    'auth',
    'academicTerms',
    'academicYear',
    'courseInstructor',
    'courses',
    'currentUser',
    'emails',
    'leaveOfAbsenceData',
    'phones',
    'programContacts',
    'programProgress',
    'programs',
    'social',
    'student',
    'userDefinedAttributes',
    'feedback',
    'salesforce',
  ],
  blacklist: blacklistPaths,
  transforms: [ // nested blacklist-paths require a custom transform to be applied
    createTransform((inboundState, key) => {
      const blacklistPathsNestedValues = blacklistPaths
        .filter((path) => path.startsWith(`${key}.`))
        .map((path) => path.substr(key.length + 1));
      return omit(inboundState, ...blacklistPathsNestedValues);
    }, null),
  ],
  migrate: (state) => {
    let newState = { ...state || preloadedState };
    newState.auth.request = Promise.resolve();
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('login')) { // clear persisted data if ?login exists
      newState = preloadedState;
      urlParams.delete('login');
      const newParams = urlParams.toString();
      window.history.replaceState(
        {},
        document.title,
        window.location.origin + window.location.pathname + (newParams ? `?${newParams}` : ''),
      );
    }

    // set page load count used by redux to invalidate aborted fetches
    setPageLoadIndex();

    const { MyPhoenix } = window;
    const passedScenarioId = urlParams.get(ScenarioIdKey);
    if (passedScenarioId && (MyPhoenix.Environment.IsAuthor || MyPhoenix.Environment.IsTest)) {
      MyPhoenix.Persistor.purge();
      sessionStorage.setItem(ScenarioIdKey, passedScenarioId);
      Cookies.set(ScenarioIdKey, passedScenarioId);
    }

    MyPhoenix.User.email = newState.auth.email;
    MyPhoenix.User.userName = newState.auth.userName;
    MyPhoenix.User.profileId = newState.currentUser.profileId;
    MyPhoenix.User.issuerId = newState.currentUser.issuerId;
    MyPhoenix.User.firstName = newState.currentUser.firstName;
    MyPhoenix.User.lastName = newState.currentUser.lastName;
    MyPhoenix.User.type = newState.currentUser.type;

    const myPhxPath = `myphx${window.location.pathname.split('.html')[0]}`;
    MyPhoenix.Analytics.Page.name = myPhxPath.replace(/\//g, ':');
    const studentStatus = newState.student.statuses ? newState.student.statuses.find((status) => (status.statusType === 'Military_Status')) : '';
    if (studentStatus && studentStatus.statusType === 'Military_Status') {
      MyPhoenix.Analytics.User.MilitaryType = 'True';
    } else { MyPhoenix.Analytics.User.MilitaryType = 'False'; }

    const primaryProgram = newState.programs.byId[newState.programs.primaryProgramId];
    if (primaryProgram) {
      MyPhoenix.User.programCode = primaryProgram.code;
      MyPhoenix.User.programLevel = primaryProgram.qualificationLevel;
      MyPhoenix.User.programName = primaryProgram.name;
      MyPhoenix.User.programType = primaryProgram.type;
      MyPhoenix.User.programVersion = primaryProgram.version;

      MyPhoenix.Analytics.User.creditsTransferred = primaryProgram.creditsSummary.transferred;
      MyPhoenix.Analytics.User.startDate = primaryProgram.startDate;
      MyPhoenix.Analytics.User.programCompletionDate = primaryProgram.programCompletionDate;
      const enrollmentAffiliation = primaryProgram.statuses ? primaryProgram.statuses.find((status) => (status.statusType === 'EnrollmentStatus')) : '';
      if (enrollmentAffiliation.status === 'G') {
        MyPhoenix.User.type = 'Alumni';
      }
      const { qualificationLevel } = primaryProgram;
      if (qualificationLevel === 'UG') { MyPhoenix.Analytics.User.programLevel = 'UnderGraduate Level'; }
      if (qualificationLevel === 'GR') { MyPhoenix.Analytics.User.programLevel = 'Graduate Level'; }
      if (qualificationLevel === 'DO') { MyPhoenix.Analytics.User.programLevel = 'Doctorate Level'; }
      const enrollmentStatus = (statusCode) => {
        switch (statusCode) {
          case 'G': return 'Graduate';
          case 'W': return 'Withdrawn';
          case 'F': return 'FullTime Active';
          case 'L': return 'Less than FullTime Active';
          case 'A': return 'Leave of Absence';
          case 'X': return 'Not yet Started';
          default: return '';
        }
      };
      MyPhoenix.Analytics.User.studentStatus = enrollmentStatus(enrollmentAffiliation.status);
      const currentCourses = getCurrentCoursesByRange(state.courses);
      const futureCourses = getFutureCoursesByRange(state.courses);
      if (enrollmentAffiliation.status === 'C'
        && !currentCourses.length && !futureCourses.length) {
        MyPhoenix.Analytics.User.nearGrad = 'True';
      } else { MyPhoenix.Analytics.User.nearGrad = 'False'; }
      const lastActivityDate = moment.tz(primaryProgram.lastActivityDate, 'America/Phoenix');
      const currentDate = getCurrentDate();
      const noOfDays = currentDate.diff(lastActivityDate, 'days');
      if (Number.isNaN(noOfDays)) {
        MyPhoenix.Analytics.User.outOfAttendance = '';
      } else { MyPhoenix.Analytics.User.outOfAttendance = `${noOfDays} days`; }
    }
    return Promise.resolve(newState);
  },
};

export default function configureStore() {
  const rootReducer = combineReducers({
    activities,
    addresses,
    alerts,
    applicationState,
    attendances,
    auth,
    courseInstructor,
    courseOutcomes,
    courses,
    courseCurriculum,
    currentUser,
    demographics,
    emails,
    enrollmentApplication,
    leaveOfAbsenceData,
    pla,
    phones,
    programContacts,
    programProgress,
    programs,
    skills,
    social,
    student,
    tasks,
    userDefinedAttributes,
    feedback,
    salesforce,
    militaryStatus,
    CBEData,
    acmtSkills,
    skillsBasedPrograms,
    academicTerms,
    academicYear,
    transferCreditsData,
    siteStripe,
    events,
  });
  const store = createStore(
    persistReducer(persistConfig, rootReducer),
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );
  // TODO: Consider using the PersistGate component to prevent rendering before hydration finishes.
  const persistor = persistStore(store);
  return { store, persistor };
}
