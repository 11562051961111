import { createMuiTheme } from '@material-ui/core';

// reference this palette in your component within makeStyles with theme.palette
export default createMuiTheme({
  breakpoints: {
    // breakpoints aligned with .edu/uopcommon no more xs breakpoint. sm is 0-640
    values: {
      sm: 0,
      md: 640,
      lg: 992,
      xl: 1441,
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#FFF',
    },
    background: {
      paper: '#FFF',
      dark: '#212529',
      default: '#F2F3F3',
      lightRed: 'rgba(219,55,37,15%)',
    },
    primary: {
      main: '#DB3725',
      dark: '#8A1500',
      darker: '#58160F',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#0D819C',
      contrastText: '#FFF',
    },
    success: {
      main: '#007558',
      secondary: '#008563',
      tertiary: '#5D8B67',
      quaternary: '#008A68',
    },
    error: {
      main: '#DB3725',
      contrastText: '#FFF',
      background: '#FBEBEA',
      direWarning: '#F46A1F',
      failing: '#DB3727',
    },
    text: {
      primary: '#001823',
      secondary: '#111c24',
      disabled: '#5E7079',
      hint: '#405e71',
      almostBlack: '#3a4f59',
    },
    grays: {
      alertGray: '#e1eaee',
      lightGray: '#EBE8E8',
      mediumGray: '#c8c8c9',
      mediumLightGray: '#8c8c8c',
      mediumDarkGray: '#5e7079',
      darkGray: '#313c45',
      htmlGray: '#808080',
      grey: '#929292',
      borderGray: '#d2d7d9',
      borderLightGray: '#e3e6e7',
      tooltipGray: '#757575',
      ungradedGray: '#7B8A92',
      almostBlackL10: '#1E333F',
      almostBlackL50: '#97a3a9',
      almostBlackL20: '#3A4F59',
      labelGray: '#747474',
      inactiveGray: '#69767d',
    },
    custom: {
      skyBound: '#0d819c',
      pizazz: '#ff9800',
      lemonGinger: '#afb42b',
      coral: '#ca593e',
      maize: '#e5bd7d',
      seafoam: '#a9bab2',
      riverBlue: '#30718D',
      teal: '#487a92',
      navyTeal: '#30718d',
      babyMelonGreen: '#93cba4',
      deepGreen: '#567248',
      sunflower: '#f89a43',
      tan: '#b49c7a',
      brown: '#756254',
      webBlue: '#009cad',
      brandBrown: '#3c2320',
      brandRed: '#dd3826',
      closestPink: '#d84c08',
      lightShadeRed: '#ea695b',
      darkOrange: '#bc340e',
      orange: '#f67b37',
      rajah: '#f6b563',
      darkShadeRed: '#8a1500',
      paleRed: '#fc9489',
      lightOrange: '#EC712D',
      brightOrange: '#D84D0B',
      burntOrange: '#C85526',
      sunriseOrange: '#F67B39',
      green: '#388164',
      systemGreen: '#008A68',
      dangerRed: '#B21F18',
      phoenixRed: '#B21F12',
      warningRed: '#DB372533',
      warningDarkRed: '#DC3727',
      warningRajah: '#F6B664',
      lightRajah: '#F6B6641A',
      darkGray: '#5E7078',
      darkBlack: '#12262D',
      secondarySuccessTint: '#00856310',
      deepGreenTint: '#56724885',
      rajahTint: '#f6b56325',
      orangeTint: '#f67b3785',
      primaryTint: '#DB372585',
      almostBlackD20: '#000304',
      donutGreen: '#388164',
      secondaryBlue: '#30718D',
    },
  },
  typography: {
    // this along with giving html font-size 62.5% in index.css allows
    // us to have 1rem == 10px for easier calculation
    // for example, 24px will be equal to 2.4rem, see: https://material-ui.com/customization/typography/#font-size
    htmlFontSize: 10,
  },
  // Using factors of 1rem makes math easy!
  // And we can manually set gutter widths on MUI Grid components
  spacing: (factor) => `${factor}rem`,
});
