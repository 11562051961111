import { connect } from 'react-redux';
import fetchCurrentUserIfNeeded, { fetchAvatarIfNeeded } from '../domains/currentUser/actions';
import HeaderAvatar from '../../components/header-avatar/v1/HeaderAvatar';

const mapStateToProps = (state, ownProps) => ({
  avatarContentType: state.currentUser.avatarContentType,
  encodedAvatar: state.currentUser.encodedAvatar,
  firstName: state.currentUser.firstName,
  lastName: state.currentUser.lastName,
  issuerId: state.currentUser.issuerId,
  profileLink: ownProps.profileLink,
  statusAuth: state.auth.status,
});

const mapDispatchToProps = (dispatch) => ({
  loadProfile: () => {
    dispatch(fetchCurrentUserIfNeeded());
  },
  loadAvatar: () => {
    dispatch(fetchAvatarIfNeeded());
  },
});

const HeaderAvatarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderAvatar);

export default HeaderAvatarContainer;
