/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import styles from './Alert.styles';
import Icon from '../../icon/v1/Icon';
import theme from '../../../theme';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function Alert(props) {
  const {
    errorCode, dialogTitle, primaryText, secondaryText, buttonText, primaryButtonCallback, open,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const componentName = 'Alert';
  const classes = styles();

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      maxWidth="md"
      className={classes.root}
    >
      <div className={classes.root}>
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography className={classes.h1} variant="h1" data-cy="alert-dialog-title">
            <Icon icon="icon-warning-sign" color={theme.palette.custom.brandRed} className={classes.icon} />
            &nbsp;
            {dialogTitle}
          </Typography>
          <Typography className={classes.code} data-cy="alert-dialog-error-code">
            {errorCode}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.primaryText}
            data-cy="alert-dialog-contents"
          >
            {primaryText}
          </DialogContentText>
          <DialogContentText className={classes.secondaryText}>
            {secondaryText}
          </DialogContentText>
          <Button
            variant="contained"
            className={`${classes.button} ${classes.primaryButton}`}
            onClick={primaryButtonCallback}
          >
            {buttonText}
          </Button>
        </DialogContent>
      </div>
    </Dialog>
  );
}

Alert.propTypes = {
  errorCode: PropTypes.string,
  dialogTitle: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  primaryButtonCallback: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

Alert.defaultProps = {
  errorCode: '',
  secondaryText: '',
  open: false,
};

export default Alert;
