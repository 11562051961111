import STATUS from '../../globalStatuses';
import {
  RECEIVE_UPDATED_TASK, REQUEST_TASKS, RECEIVE_TASKS, REQUEST_TASKS_ERROR,
  INVALIDATE_TASKS, REQUEST_UPDATE_TASK_ERROR, REQUEST_UPDATE_TASK,
} from './types';
import { errorToMessage } from '../../../utils/error-functions';

function invalidateTasks() {
  return { type: INVALIDATE_TASKS };
}

function requestTasks() {
  return { type: REQUEST_TASKS };
}

function requestUpdateTask(taskId) {
  return { type: REQUEST_UPDATE_TASK, taskId };
}

function receiveTasks(tasks, applicationId) {
  return {
    type: RECEIVE_TASKS, tasks, applicationId, receivedAt: Date.now(),
  };
}

function receiveUpdatedTask(task) {
  return {
    type: RECEIVE_UPDATED_TASK, task, receivedAt: Date.now(),
  };
}

function requestTasksError(error) {
  return { type: REQUEST_TASKS_ERROR, error, receivedAt: Date.now() };
}

function updateTaskError(taskId, error) {
  return {
    type: REQUEST_UPDATE_TASK_ERROR, taskId, error, receivedAt: Date.now(),
  };
}

/**
 * Fetches the events of the current user
 * @returns {Function}
 */
function fetchTasks() {
  return async (dispatch, getState, { Services }) => {
    dispatch(requestTasks());
    try {
      const { auth, enrollmentApplication } = getState();
      const tasksResponse = await
      Services.SagasService.getTasksByPersonId(auth.personId);
      return dispatch(receiveTasks(tasksResponse.data.tasks, enrollmentApplication?.applicationId));
    } catch (error) {
      return dispatch(requestTasksError(errorToMessage(error)));
    }
  };
}

function shouldFetchTasks(state) {
  const { tasks } = state;
  if (!tasks) {
    return true;
  }
  return tasks.status === STATUS.UNFETCHED || tasks.status === STATUS.INVALIDATED;
}
export function fetchTasksIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchTasks(getState())) {
      // Dispatch a thunk from thunk!
      return dispatch(fetchTasks());
    }
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  };
}

/**
 * Updates an user actionable task
 *
 * payload: {
 *   status: "COMPLETED" || "ASSIGNED",
 * }
 * @returns function(*, *, {Services: *}): Promise<*>
 */
export function updateTask(taskId, payload) {
  return async (dispatch, getState, { Services }) => {
    dispatch(requestUpdateTask(taskId));
    const { auth } = getState();
    return Services.SagasService.updateTask(auth.personId, taskId, payload)
      .then((response) => dispatch(receiveUpdatedTask(response.data)))
      .catch((error) => dispatch(updateTaskError(taskId, errorToMessage(error))));
  };
}

export function resetTasks() {
  return (dispatch) => {
    dispatch(invalidateTasks());
    return Promise.resolve();
  };
}
