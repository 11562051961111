import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  ctaButton: {
    zIndex: '9',
    width: '6.8rem',
    height: '3.0rem',
    backgroundColor: theme.palette.common.white,
    backgroundSize: 'cover',
    boxShadow: '0.1rem 0.1rem 0.8rem -0.3rem rgba(0,0,0,0.4), 0.1rem 0.1rem 0.2rem rgba(0,0,0,0.05)',
    transform: 'rotate(-90deg)',
    position: 'fixed',
    bottom: '50%',
    right: '-2.4rem',
    [theme.breakpoints.up('md')]: {
      width: '9.3rem',
      height: '4.8rem',
      bottom: '50%',
    },
  },
  buttonText: {
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    textDecoration: 'none solid rgb(0, 24, 35)',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4rem',
    },
  },
  buttonContainer: {
    position: 'relative',
  },
}));
