import moment from 'moment';
import { setSelectedProgram } from '../applicationState/actions';
import {
  REQUEST_PROGRAMS, RECEIVE_PROGRAMS,
  REQUEST_PROGRAMS_ERROR, INVALIDATE_PROGRAMS,
  REQUEST_GRADUATION_DATE,
  REQUEST_GRADUATION_DATE_ERROR,
  RECEIVE_GRADUATION_DATE,
} from './types';
import { errorToMessage } from '../../../utils/error-functions';
import STATUS from '../../globalStatuses';
import { shouldFetch } from '../../../utils/status-functions';
import fetchAcademicYearDataIfNeeded from '../academicYear/action';
import { getCurrentDate } from '../../../utils/date-time-functions';

/*= ============ data operatons ========= */

const getTransformedProgramsData = (programs) => {
  let primaryId = '';
  const transformedPrograms = programs.map((program) => {
    const transformedProgram = {
      id: '',
      code: '',
      creditsSummary: {},
      version: '',
      isPrimary: false,
      lastActivityDate: '',
      programCompletionDeadlineDate: '',
      startDate: '',
      programCompletionDate: '',
      programGpa: 0,
      startingAcademicYear: '',
      statuses: [],
    };
    const programData = (({ programCodeVersion = [] }) => (
      { programCodeVersion }))(program.externalSystemIds || {});
    if (programData.programCodeVersion.length) {
      const codeVersion = programData.programCodeVersion[0].split(':');
      [transformedProgram.code, transformedProgram.version] = codeVersion;
    }
    transformedProgram.id = program.id || '';
    transformedProgram.isPrimary = program.primary === 'true';
    if (transformedProgram.isPrimary) {
      primaryId = transformedProgram.id;
    }
    transformedProgram.statuses = program.statuses || [];
    transformedProgram.lastActivityDate = program.lastActivityDate || '';
    transformedProgram.programCompletionDeadlineDate = program.programCompletionDeadlineDate || '';
    transformedProgram.startDate = program.startDate || '';
    transformedProgram.programCompletionDate = program.programCompletionDate || '';
    transformedProgram.programGpa = program.programGpa || 0;
    transformedProgram.startingAcademicYear = program.startingAcademicYear || '';
    transformedProgram.creditsSummary = {
      assessed: program.creditsAssessed || 0,
      completed: program.creditsCompleted || 0,
      earned: program.creditsEarned || 0,
      remaining: program.creditsRemaining || 0,
      transferred: program.creditsTransferred || 0,
      waived: program.creditsWaived || 0,
    };
    return transformedProgram;
  });
  transformedPrograms.primaryProgramId = primaryId;
  return transformedPrograms;
};

/*= ===========End of data operatons ========= */

/* ===========  Get Programs  ============ */

/* action creators */
function requestPrograms() {
  return { type: REQUEST_PROGRAMS, receivedAt: Date.now() };
}

function receivePrograms(programs) {
  return { type: RECEIVE_PROGRAMS, programs, receivedAt: Date.now() };
}

function requestProgramsError(error) {
  return { type: REQUEST_PROGRAMS_ERROR, error, receivedAt: Date.now() };
}

function invalidatePrograms() {
  return { type: INVALIDATE_PROGRAMS };
}

function fetchAcademicData(ayCode, Services) {
  return async (dispatch) => Services.StudentService.getOidByAyCode(ayCode)
    .then((oidByAyCodeResponse) => {
      const oid = oidByAyCodeResponse.data[0].oid
        ? oidByAyCodeResponse.data[0].oid : '';
      const startDate = oidByAyCodeResponse.data[0].startDate
        ? oidByAyCodeResponse.data[0].startDate : '';
      const today = getCurrentDate();
      const isFuture = moment.tz(startDate, 'America/Phoenix').isAfter(moment.tz(today, 'America/Phoenix'), 'day');
      if (oid && isFuture) {
        dispatch(fetchAcademicYearDataIfNeeded(oid, ayCode));
      }
    });
}

/**
 * Fetches all the programs of the current user
 * @returns {Function}
 */
function fetchPrograms() {
  return async (dispatch, getState, { Services }) => {
    dispatch(requestPrograms());
    try {
      const { auth, applicationState } = getState();
      const programsResponse = await
      Services.StudentService.getAllProgramsByPersonId(auth.personId);
      const programsData = getTransformedProgramsData(programsResponse.data);
      /* update programsData with any data from other datapoints as needed  before dispatching. */

      const templateResponses = await Promise.all(programsData
        .map((program) => (Services.ProgramsService.getTemplateByProgramIdAndVersion(
          program.code,
          program.version,
        ))));
      const shouldEnableCBEDAFlag = (cbeDaProgram) => {
        const isFlagOn = window?.MyPhoenix?.Config?.Features?.cbeDaEnabled === true;
        if (cbeDaProgram && cbeDaProgram === 'TRUE' && isFlagOn) {
          return 'TRUE';
        }
        return 'FALSE';
      };
      const newProgramsData = programsData.map((programData, index) => {
        const newProgramData = { ...programData };
        if (programData.code) {
          if (templateResponses[index].data.length) {
            const data = templateResponses[index].data[0];
            newProgramData.type = data.programType || '';
            newProgramData.qualificationLevel = data.programLevel || '';
            // no longer using displayName as name but instead using textDescription
            newProgramData.name = data?.textDescription || '';
            newProgramData.description = data.textDescription || '';
            newProgramData.cbeProgram = data.cbeProgram || '';
            newProgramData.cbeDaProgram = shouldEnableCBEDAFlag(data.cbeDaProgram);
          }
        }
        const { academicYear } = getState();
        const ayCodeData = academicYear.byCode[newProgramData.startingAcademicYear];
        if (newProgramData.startingAcademicYear && newProgramData.cbeDaProgram
            && shouldFetch(ayCodeData, true)) {
          dispatch(fetchAcademicData(newProgramData.startingAcademicYear, Services));
        }
        return newProgramData;
      });
      newProgramsData.primaryProgramId = programsData.primaryProgramId;
      // Set a default selected program in state so components dependent on it can render themselves
      if (applicationState.selectedProgramId === '' && programsData.length) {
        dispatch(
          setSelectedProgram(
            programsData.primaryProgramId ? programsData.primaryProgramId : newProgramsData[0].id,
          ),
        );
      }
      return dispatch(receivePrograms(newProgramsData));
    } catch (error) {
      return dispatch(requestProgramsError(errorToMessage(error)));
    }
  };
}

function shouldFetchPrograms(state) {
  const { programs } = state;

  return shouldFetch(programs, true);
}

export default function fetchProgramsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchPrograms(getState())) {
      // Dispatch a thunk from thunk!
      return dispatch(fetchPrograms());
    }
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  };
}
/* ===========  End of Get Programs ============ */

/* ===========  Reset Programs ============ */

export function resetPrograms() {
  return (dispatch) => {
    dispatch(invalidatePrograms());
    return Promise.resolve();
  };
}

/* ===========  End of Reset Programs ============ */

function requestGraduationDate(selectedProgramId) {
  return { type: REQUEST_GRADUATION_DATE, selectedProgramId, receivedAt: Date.now() };
}

function requestGraduationDateError(error, selectedProgramId) {
  return {
    type: REQUEST_GRADUATION_DATE_ERROR, error, selectedProgramId, receivedAt: Date.now(),
  };
}

function receiveGraduationDate(data) {
  return { type: RECEIVE_GRADUATION_DATE, data, receivedAt: Date.now() };
}

const generateRequestBody = (state) => {
  const { applicationState, programs } = state;
  const { selectedProgramId } = applicationState || '';
  const selectedProgram = programs?.byId[selectedProgramId] || '';
  const { creditsSummary, code } = selectedProgram;
  let level = 'CBE';
  let weeks = 16;

  if (selectedProgram?.cbeProgram === 'FALSE') {
    switch (selectedProgram?.qualificationLevel) {
      case 'UG':
        level = 'Undergraduate';
        weeks = 5;
        break;
      case 'G':
        level = 'Masters';
        weeks = 6;
        break;
      case 'D':
        level = 'Doctoral';
        weeks = 8;
        break;
      default:
        break;
    }
  }

  return {
    body: {
      remainingCredits: creditsSummary?.remaining,
      level,
      weeks,
      programCode: code,
      creditsPerCourse: 3,
    },
    selectedProgramId,
  };
};

function fetchGraduationDate() {
  return async (dispatch, getState, { Services }) => {
    const { body, selectedProgramId } = generateRequestBody(getState());

    dispatch(requestGraduationDate(selectedProgramId));
    return Services.StudentService.getESTGraduationDate(body)
      .then((response) => {
        const { data } = response;
        const { results } = data;
        dispatch(receiveGraduationDate({ results, selectedProgramId }));
      })
      .catch((err) => dispatch(requestGraduationDateError(errorToMessage(err), selectedProgramId)));
  };
}

function shouldFetchGraduationDate(state) {
  const { applicationState, programs } = state;
  const { selectedProgramId } = applicationState || '';
  const selectedProgram = programs?.byId[selectedProgramId] || '';
  if (!selectedProgram.programGraduationInfo) {
    return true;
  }
  return selectedProgram?.programGraduationInfo?.status === STATUS.UNFETCHED;
}

export function fetchGraduationDateIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchGraduationDate(getState())) {
      return dispatch(fetchGraduationDate());
    }
    return Promise.resolve();
  };
}
