import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'nowrap',

    [theme.breakpoints.up('md')]: {
      flexWrap: 'wrap',
    },
  },
  iconButton: {
    background: 'transparent',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    height: theme.spacing(8.2),
    minWidth: theme.spacing(5.9),
    transition: 'all 0.1s ease-in',
    '&:hover': {
      border: `0.2rem solid ${theme.palette.error.main}`,
      borderRadius: theme.spacing(0.4),
      background: 'transparent',
    },
    '&:focus, &:active': {
      border: `0.2rem solid ${theme.palette.custom.darkShadeRed}`,
      borderRadius: theme.spacing(0.4),
      background: 'transparent',
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(9.4),
      height: theme.spacing(11.4),
      borderRadius: theme.spacing(0.4),
      border: `0.1rem solid ${theme.palette.grays.mediumDarkGray}`,
      background: 'transparent',
    },
  },
  iconButtonSelected: {
    border: `0.2rem solid ${theme.palette.custom.darkShadeRed}`,
  },
  iconLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  iconButtonName: {
    fontSize: theme.spacing(1.4),
    fontWeight: 'normal',
    textTransform: 'none',
    margin: `${theme.spacing(1)} 0`,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(1.6),
    },
  },
  icon: {
    display: 'flex',
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(3.5),
      width: theme.spacing(3.5),
    },
  },
}));
