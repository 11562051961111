export default (persondId, programCode, programVersion) => {
  const postDataBase = {
    inputAPIs: [
      '<apihost>/student/v1/breaks?sourceType=SECTION&sourceId=  {sourceId1}&sourceId={sourceId2} XYZ ABC',
    ],
    inputSessionId: 'string',
    response: 'OUT/INOUT',
    variation: 'SHORT/DETAIL/anyvariationsw.r.t.rulebeingimplemented',
  };

  return {
    ...postDataBase,
    inputs: [
      {
        field: 'personId',
        type: 'string',
        value: persondId,
      },
      {
        field: 'program',
        type: 'string',
        value: programCode,
      },
      {
        field: 'version',
        type: 'string',
        value: programVersion,
      },
    ],
  };
};
