import { REQUEST_ACADEMIC_TERMS, REQUEST_ACADEMIC_TERMS_ERROR, RECEIVE_ACADEMIC_TERMS } from './types';
import { errorToMessage } from '../../../utils/error-functions';
import { shouldFetch } from '../../../utils/status-functions';

function requestAcademicTerms(termCode) {
  return {
    termCode,
    type: REQUEST_ACADEMIC_TERMS,
  };
}
function requestAcademicTermsError(termCode, error) {
  return {
    termCode,
    error,
    receivedAt: Date.now(),
    type: REQUEST_ACADEMIC_TERMS_ERROR,
  };
}

function receiveAcademicTerms(termCode, termData) {
  return {
    termCode,
    termData,
    receivedAt: Date.now(),
    type: RECEIVE_ACADEMIC_TERMS,
  };
}

function fetchAcademicTerms(termCode) {
  return async (dispatch, getState, { Services }) => {
    dispatch(requestAcademicTerms(termCode));
    return Services.StudentService.getAcademicTermsByTermCode(termCode)
      .then((academicTermResponse) => {
        dispatch(receiveAcademicTerms(termCode, academicTermResponse.data[0]));
      })
      .catch((error) => {
        dispatch(requestAcademicTermsError(termCode, errorToMessage(error)));
      });
  };
}

function shouldFetchAcademicTerms(state, termCode) {
  const { academicTerms } = state;
  const academicTerm = academicTerms.byCode[termCode];
  return shouldFetch(academicTerm, true);
}

export default function fetchAcademicTermsIfNeeded(termCode) {
  return (dispatch, getState) => {
    if (shouldFetchAcademicTerms(getState(), termCode)) {
      return dispatch(fetchAcademicTerms(termCode));
    }
    return Promise.resolve();
  };
}
