import moment from 'moment-timezone';
import { formatDate, getCurrentDate } from './date-time-functions';

export const achievementTypes = {
  presidentsList: 'presidents',
  deansList: 'deans',
  skill: 'skill',
};

export const notificationTemplateIds = {
  dev: {
    DeansList: 'NOTIFICATION-DEANS-LIST',
    PresidentsList: 'NOTIFICATION-PRESIDENTS-LIST',
    Skill: 'NOTIFICATION-SKILL',
  },
  qa: {
    DeansList: '02-NOTIFICATION-6074b7024340b05a67ad4cdf',
    PresidentsList: '02-NOTIFICATION-607a04574340b05a67ad4cf5',
    Skill: '02-NOTIFICATION-60ec8458b5823e21de57c7fc',
  },
  stage: {
    DeansList: '02-NOTIFICATION-6074b7024340b05a67ad4cdf',
    PresidentsList: '02-NOTIFICATION-607a04574340b05a67ad4cf5',
    Skill: '02-NOTIFICATION-60ec8458b5823e21de57c7fc',
  },
  prod: {
    DeansList: '02-NOTIFICATION-60ad3cc5dd7d2433ec8927d1',
    PresidentsList: '02-NOTIFICATION-60ad3ea0dd7d2433ec8927d2',
    Skill: '02-NOTIFICATION-60f4615e357dd2c6d17372c1',
  },
};

export const notificationTemplateIdsForEnv = () => {
  const { MyPhoenix } = window;
  return (!MyPhoenix || !MyPhoenix.Environment || !MyPhoenix.Environment.Lane
    || MyPhoenix.Environment.IsTest || MyPhoenix.Environment.IsAuthor)
    ? notificationTemplateIds.dev
    : notificationTemplateIds[MyPhoenix.Environment.Lane];
};

const getLatestAchievement = (achievements, achievementTypeTieBreaker) => {
  let latestAchievement = null;
  if (achievements.length) {
    latestAchievement = achievements.reduce((value, prevValue) => {
      if (achievementTypeTieBreaker === achievementTypes.presidentsList
          || achievementTypeTieBreaker === achievementTypes.deansList) {
        if (value.expiryDate > prevValue.expiryDate) {
          return value;
        }
        if (value.expiryDate === prevValue.expiryDate) {
          if (value.type === achievementTypeTieBreaker) {
            return value;
          }
        }
      } else if (value.startDate > prevValue.startDate) {
        return value;
      }
      return prevValue;
    });
  }
  return latestAchievement;
};

const getAchievementType = (achievement) => {
  const { Skill, PresidentsList, DeansList } = notificationTemplateIdsForEnv();
  if (achievement.templateId === Skill) {
    return achievementTypes.skill;
  }
  if (achievement.templateId === PresidentsList) {
    return achievementTypes.presidentsList;
  }
  if (achievement.templateId === DeansList) {
    return achievementTypes.deansList;
  }
  return null;
};

const getLatestAchievementByType = (achievementType, achievements, onlyUndismissed) => {
  const skillAchievements = [];
  const awardListAchievements = [];
  if (achievements && achievements.length) {
    achievements.forEach((achievement) => {
      const achievementData = {
        ...achievement.states,
        resources: achievement.resources,
        templateId: achievement.templateId,
        id: achievement.id,
        type: getAchievementType(achievement),
      };
      // unfortunately, 'DISMISSED' is the initial status and 'COMPLETED' is dismissed
      const isNotYetDismissed = achievementData.status.action !== 'COMPLETED';
      if ((isNotYetDismissed || !onlyUndismissed)
          && achievementData.type === achievementTypes.skill) {
        skillAchievements.push(achievementData);
      } else if ((isNotYetDismissed || !onlyUndismissed)
          && (achievementData.type === achievementTypes.presidentsList
            || achievementData.type === achievementTypes.deansList)) {
        awardListAchievements.push(achievementData);
      }
    });
  }
  if (achievementType === achievementTypes.skill) {
    return getLatestAchievement(skillAchievements);
  }
  return getLatestAchievement(awardListAchievements, achievementTypes.presidentsList);
};

export const getLatestSkillAchievement = (achievements, onlyUndismissed) => {
  const latestSkillAchievement = getLatestAchievementByType(
    achievementTypes.skill, achievements, onlyUndismissed,
  );
  if (latestSkillAchievement) {
    const { startDate } = latestSkillAchievement;
    const achievementStartDate = moment.tz(startDate, 'America/Phoenix');
    const currentDate = getCurrentDate();
    const noOfDays = currentDate.diff(achievementStartDate, 'days');
    if (noOfDays < 90) {
      return latestSkillAchievement;
    }
  }
  return null;
};

export const getLatestAwardListAchievement = (achievements, onlyUndismissed) => {
  const latestAward = getLatestAchievementByType('award list', achievements, false);
  if (latestAward
    && (!onlyUndismissed || latestAward.status.action !== 'COMPLETED')) {
    return latestAward;
  }
  return null;
};

export const getAwardListPeriod = (expiryDate) => {
  const periodEnd = moment(expiryDate).utc();
  periodEnd.date(1);
  periodEnd.subtract(6, 'month');
  const periodStart = moment(periodEnd).utc();
  periodStart.subtract(5, 'month');
  return {
    year: formatDate('YYYY', periodStart),
    startMonth: formatDate('MMMM', periodStart),
    endMonth: formatDate('MMMM', periodEnd),
  };
};
