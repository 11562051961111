import STATUS from '../../globalStatuses';
import {
  RECEIVE_DEMOGRAPHICS, REQUEST_DEMOGRAPHICS_ERROR, REQUEST_DEMOGRAPHICS,
} from './types';

export default function demographics(state = { dateOfBirth: '', status: STATUS.UNFETCHED }, action) {
  switch (action.type) {
    case REQUEST_DEMOGRAPHICS:
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case RECEIVE_DEMOGRAPHICS:
      return {
        ...state,
        dateOfBirth: action.payload.dateOfBirth,
        status: STATUS.FETCHED,
        receivedAt: action.receivedAt,
      };
    case REQUEST_DEMOGRAPHICS_ERROR:
      return {
        ...state,
        status: STATUS.ERROR,
        error: action.error,
        receivedAt: action.receivedAt,
      };
    default:
      return state;
  }
}
